export function ssnformat (str: string) {
  return [...str.split('')].map((c, idx) => {
    if (idx > 12) {
      return '_'
    } else if (idx === 8 && c.match(/[0-9]/)) {
      return '-' + c
    } else if (idx === 8) {
      return '-'
    } else if (!c.match(/[0-9]/)) {
      return '_'
    }
    return c
  }).join('').replace(/_/g, '')
}

export function titleCase (str: string): string {
  return str.toLowerCase().replace(/(^\S)|\s+(\S)/gi, (m) => m.toUpperCase())

}
