
import { computed, defineComponent } from 'vue'
import Links from '@/use/links'

export default defineComponent({
  name: 'DiagnoseHint',
  props: {
    code: { type: String }
  },
  setup (props) {
    const suggestions = computed(() => props.code ? Links.suggest(props.code) : [])

    return { suggestions }
  }
})
