/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { OpenInvestigationCreateForm } from './OpenInvestigationCreateForm';
import {
    OpenInvestigationCreateFormFromJSON,
    OpenInvestigationCreateFormFromJSONTyped,
    OpenInvestigationCreateFormToJSON,
} from './OpenInvestigationCreateForm';
/**
 * Open Investigation Create
 * @export
 * @interface OpenInvestigationCreate
 */
export interface OpenInvestigationCreate {
    /**
     *
     * @type {string}
     * @memberof OpenInvestigationCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof OpenInvestigationCreate
     */
    tel: string;
    /**
     *
     * @type {OpenInvestigationCreateForm}
     * @memberof OpenInvestigationCreate
     */
    form: OpenInvestigationCreateForm;
}
/**
 * Check if a given object implements the OpenInvestigationCreate interface.
 */
export function instanceOfOpenInvestigationCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "tel" in value;
    isInstance = isInstance && "form" in value;
    return isInstance;
}
export function OpenInvestigationCreateFromJSON(json: any): OpenInvestigationCreate {
    return OpenInvestigationCreateFromJSONTyped(json, false);
}
export function OpenInvestigationCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenInvestigationCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'email': json['email'],
        'tel': json['tel'],
        'form': OpenInvestigationCreateFormFromJSON(json['form']),
    };
}
export function OpenInvestigationCreateToJSON(value?: OpenInvestigationCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'email': value.email,
        'tel': value.tel,
        'form': OpenInvestigationCreateFormToJSON(value.form),
    };
}