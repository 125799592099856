import { useRoute } from 'vue-router/composables'
import { computed, ComputedRef } from 'vue'
import z, { ZodSchema } from 'zod'

export function computedQuery<T extends ZodSchema> (key: string, schema?: T): ComputedRef<z.infer<T>> {
  const route = useRoute()
  return computed(() => key in route.query ? (schema ?? z.string()).parse(route.query[key]) : null)
}

export function computedParam<T extends ZodSchema> (key: string, schema?: T): ComputedRef<z.infer<T>> {
  const route = useRoute()
  return computed(() => key in route.params ? (schema ?? z.string()).parse(route.params[key]) : null)
}
