/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { AnswerChildOption } from './AnswerChildOption';
import {
    AnswerChildOptionFromJSON,
    AnswerChildOptionFromJSONTyped,
    AnswerChildOptionToJSON,
} from './AnswerChildOption';
import type { AnswerValue } from './AnswerValue';
import {
    AnswerValueFromJSON,
    AnswerValueFromJSONTyped,
    AnswerValueToJSON,
} from './AnswerValue';
/**
 *
 * @export
 * @interface AnswerOption
 */
export interface AnswerOption {
    /**
     *
     * @type {string}
     * @memberof AnswerOption
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof AnswerOption
     */
    description?: string;
    /**
     *
     * @type {AnswerValue}
     * @memberof AnswerOption
     */
    value: AnswerValue;
    /**
     *
     * @type {Array<AnswerChildOption>}
     * @memberof AnswerOption
     */
    children?: Array<AnswerChildOption>;
    /**
     *
     * @type {string}
     * @memberof AnswerOption
     */
    coloring?: string;
    /**
     *
     * @type {string}
     * @memberof AnswerOption
     */
    icon?: string;
}
/**
 * Check if a given object implements the AnswerOption interface.
 */
export function instanceOfAnswerOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function AnswerOptionFromJSON(json: any): AnswerOption {
    return AnswerOptionFromJSONTyped(json, false);
}
export function AnswerOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'value': AnswerValueFromJSON(json['value']),
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(AnswerChildOptionFromJSON)),
        'coloring': !exists(json, 'coloring') ? undefined : json['coloring'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}
export function AnswerOptionToJSON(value?: AnswerOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'title': value.title,
        'description': value.description,
        'value': AnswerValueToJSON(value.value),
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(AnswerChildOptionToJSON)),
        'coloring': value.coloring,
        'icon': value.icon,
    };
}