/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * Open Investigation has been created successfully
 * @export
 * @interface OpenInvestigationCreated
 */
export interface OpenInvestigationCreated {
    /**
     *
     * @type {string}
     * @memberof OpenInvestigationCreated
     */
    id: string;
}
/**
 * Check if a given object implements the OpenInvestigationCreated interface.
 */
export function instanceOfOpenInvestigationCreated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function OpenInvestigationCreatedFromJSON(json: any): OpenInvestigationCreated {
    return OpenInvestigationCreatedFromJSONTyped(json, false);
}
export function OpenInvestigationCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenInvestigationCreated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'id': json['id'],
    };
}
export function OpenInvestigationCreatedToJSON(value?: OpenInvestigationCreated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'id': value.id,
    };
}