/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { AnswerOption } from './AnswerOption';
import {
    AnswerOptionFromJSON,
    AnswerOptionFromJSONTyped,
    AnswerOptionToJSON,
} from './AnswerOption';
/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     *
     * @type {string}
     * @memberof Answer
     */
    key: string;
    /**
     *
     * @type {string}
     * @memberof Answer
     */
    child_key?: string;
    /**
     *
     * @type {string}
     * @memberof Answer
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Answer
     */
    description?: string;
    /**
     *
     * @type {Array<AnswerOption>}
     * @memberof Answer
     */
    options: Array<AnswerOption>;
    /**
     *
     * @type {object}
     * @memberof Answer
     */
    filter?: object;
    /**
     *
     * @type {boolean}
     * @memberof Answer
     */
    primary?: boolean;
}
/**
 * Check if a given object implements the Answer interface.
 */
export function instanceOfAnswer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "options" in value;
    return isInstance;
}
export function AnswerFromJSON(json: any): Answer {
    return AnswerFromJSONTyped(json, false);
}
export function AnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Answer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'key': json['key'],
        'child_key': !exists(json, 'child_key') ? undefined : json['child_key'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'options': ((json['options'] as Array<any>).map(AnswerOptionFromJSON)),
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
    };
}
export function AnswerToJSON(value?: Answer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'key': value.key,
        'child_key': value.child_key,
        'title': value.title,
        'description': value.description,
        'options': ((value.options as Array<any>).map(AnswerOptionToJSON)),
        'filter': value.filter,
        'primary': value.primary,
    };
}