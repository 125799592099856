import { Api } from '@/lib/di/api'
import { useLoadable } from '@/use/loadable'

export function useDiagnoseCodes () {
  return useLoadable(Api.get(), async api => await api.getDiagnoseCodes())
}

export function useTreatmentCodes () {
  return useLoadable(Api.get(), async api => await api.getTreatmentCodes())
}
