

import { defineComponent, PropType } from 'vue'

import CommonCodeSelect from '@/components/actionplan/CommonCodeSelect.vue'
import DiagnoseHint from '@/components/actionplan/DiagnoseHint.vue'
import { Selectable } from '@/lib/select'

export default defineComponent({
  name: 'DiagnoseInput',
  components: { CommonCodeSelect, DiagnoseHint },
  props: {
    id: { required: true, type: String },
    index: { required: true, type: Number },
    text: { required: true, type: String },
    code: { required: true, type: String },
    options: { required: true, type: Array as PropType<Selectable[]> },
    state: { required: false, type: Object as PropType<{ text: false | null }> },
    showHint: { required: false, type: Boolean, default: true },
    showHeader: { required: false, type: Boolean, default: true }
  },
  setup (props, { emit }) {

    function getTextWithAutofill (code: string): string {
      const diagnose = props.options.find(i => i.value === code)
      if (props.text.trim().length === 0) {
        return diagnose?.text ?? code
      }
      const prevDiagnose = props.options.find(i => i.value === props.code)
      if (props.text.trim() === prevDiagnose?.text) {
        return diagnose?.text ?? code
      }
      return props.text
    }

    function changedCode (code: string) {
      emit('update', { id: props.id, code, text: getTextWithAutofill(code) })
    }
    function changedText (text: string) {
      emit('update', { id: props.id, code: props.code, text })
    }
    function removeItem () {
      emit('remove', { id: props.id })
    }

    return { changedText, changedCode, removeItem }
  }
})

