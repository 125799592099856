
import { defineComponent } from 'vue'
import { usePatient } from '@/use/patients'
import { usePatientInvestigationCreate } from '@/use/investigation'
import InvestigationForm from '@/components/form/InvestigationForm.vue'
import { Config } from '@/lib/di/config'
import { computedParam } from '@/use/route'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  components: { InvestigationForm },
  name: 'PatientCreateInvestigation',
  setup () {
    const id = computedParam('id')
    const { debug } = Config.get()
    const { data: patient } = usePatient(id)

    const router = useRouter()
    const { error, form, submit, validation, options, fakeFill } = usePatientInvestigationCreate(id, () => {
      router.push({ name: 'Patient', params: { id: id.value } })
        .catch(err => console.error('Failed to navigate', err))
    })

    return { id, patient, error, form, submit, validation, options, fakeFill, debug }
  },
  metaInfo: {
    title: 'Skapa utredning'
  }
})
