/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     *
     * @type {string}
     * @memberof UserCreate
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof UserCreate
     */
    role: string;
    /**
     *
     * @type {Array<string>}
     * @memberof UserCreate
     */
    idp_usernames: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserCreate
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof UserCreate
     */
    name: string;
}
/**
 * Check if a given object implements the UserCreate interface.
 */
export function instanceOfUserCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "idp_usernames" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function UserCreateFromJSON(json: any): UserCreate {
    return UserCreateFromJSONTyped(json, false);
}
export function UserCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'password': json['password'],
        'role': json['role'],
        'idp_usernames': json['idp_usernames'],
        'username': json['username'],
        'name': json['name'],
    };
}
export function UserCreateToJSON(value?: UserCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'password': value.password,
        'role': value.role,
        'idp_usernames': value.idp_usernames,
        'username': value.username,
        'name': value.name,
    };
}