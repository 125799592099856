
import { ActionPlanOptions } from '@/use/action-plan-form'
import { computed, defineComponent, PropType } from 'vue'
import { ActionPlanData } from '../api/index'
import { getText } from '../lib/select'

export default defineComponent({
  name: 'ActionPlanReview',
  props: {
    plan: { type: Object as PropType<ActionPlanData>, required: true },
    options: { type: Object as PropType<ActionPlanOptions>, required: true }
  },
  setup (props) {
    return {
      getText,
      empty: computed(() => {
        return ![
          props.plan.far,
          props.plan.recipe,
          props.plan.certificate.type,
          props.plan.diagnoses.length > 0,
          props.plan.treatments.length > 0,
          props.plan.specialremittance,
          props.plan.remittancetreatment.length > 0,
          props.plan.fmu,
          props.plan.comment
        ].some(b => b)
      })
    }
  }
})
