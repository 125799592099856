
import { Selectable } from '@/lib/select'
import { defineComponent, PropType } from 'vue'
import CommonCodeSelect from './CommonCodeSelect.vue'

export default defineComponent({
  name: 'TreatmentInput',
  components: { CommonCodeSelect },
  props: {
    id: { required: true, type: String },
    index: { required: true, type: Number },
    code: { required: true, type: String },
    profession: { required: true, type: String },
    comment: { required: true, type: String },
    options: { required: true, type: Object as PropType<{codes: Selectable[], professions: Selectable[]}> },
    showHeader: { required: false, type: Boolean, default: true },
    state: { required: false, type: Object as PropType<{ profession: false | null, comment: false | null }>, default: () => ({ profession: null, comment: null }) }
  },
  setup (props, { emit }) {

    function getCommentWithAutofill (code: string): string {
      const diagnose = props.options.codes.find(i => i.value === code)
      if (props.comment.trim().length === 0) {
        return diagnose?.text ?? code
      }
      const prevDiagnose = props.options.codes.find(i => i.value === props.code)
      if (props.comment.trim() === prevDiagnose?.text) {
        return diagnose?.text ?? code
      }
      return props.comment
    }

    function commentChanged (comment: string) {
      emit('item:update', { id: props.id, code: props.code, profession: props.profession, comment })
    }
    function professionChanged (profession: string) {
      emit('item:update', { id: props.id, code: props.code, profession, comment: props.comment })
    }
    function codeChanged (code: string) {
      emit('item:update', { id: props.id, code, profession: props.profession, comment: getCommentWithAutofill(code) })
    }

    return { commentChanged, codeChanged, professionChanged }
  }
})

