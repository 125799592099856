
import { defineComponent } from 'vue'
import { useEditPatient } from '@/use/patients'
import { useLoginGuard } from '@/use/login'
import { useRouter } from 'vue-router/composables'

import PatientForm from '@/components/form/PatientForm.vue'
import { z } from 'zod'
import { computedParam } from '@/use/route'

export default defineComponent({
  name: 'PatientEdit',
  components: { PatientForm },
  setup () {
    const id = computedParam('id', z.string())
    const { submit, form, feedback, error, groupstate, inputstate, fakeFill } = useEditPatient(id, onSuccess)

    useLoginGuard()

    const router = useRouter()
    function onSuccess (id: string) {
      router.push({ name: 'Patient', params: { id } })
        .catch(err => console.error('Failed to navigate', err))
    }

    return {
      submit, form, error, feedback, id, groupstate, inputstate, fakeFill
    }
  },
  metaInfo: {
    title: 'Skapa patient'
  }
})
