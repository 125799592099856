
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Collapsible',
  props: {
    text: { type: String, required: true },
    max: { type: Number, default: () => 20 }
  },
  setup () {
    const expanded = ref(false)
    return {
      expanded,
      collapsed: computed(() => !expanded.value)
    }
  }
})

