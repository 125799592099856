/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { ActionPlanDataCertificate } from './ActionPlanDataCertificate';
import {
    ActionPlanDataCertificateFromJSON,
    ActionPlanDataCertificateFromJSONTyped,
    ActionPlanDataCertificateToJSON,
} from './ActionPlanDataCertificate';
import type { ActionPlanDataTreatmentsInner } from './ActionPlanDataTreatmentsInner';
import {
    ActionPlanDataTreatmentsInnerFromJSON,
    ActionPlanDataTreatmentsInnerFromJSONTyped,
    ActionPlanDataTreatmentsInnerToJSON,
} from './ActionPlanDataTreatmentsInner';
import type { PersonalDataMedicaldiagnoseItemsInner } from './PersonalDataMedicaldiagnoseItemsInner';
import {
    PersonalDataMedicaldiagnoseItemsInnerFromJSON,
    PersonalDataMedicaldiagnoseItemsInnerFromJSONTyped,
    PersonalDataMedicaldiagnoseItemsInnerToJSON,
} from './PersonalDataMedicaldiagnoseItemsInner';
/**
 *
 * @export
 * @interface ActionPlanData
 */
export interface ActionPlanData {
    /**
     *
     * @type {string}
     * @memberof ActionPlanData
     */
    comment: string;
    /**
     *
     * @type {string}
     * @memberof ActionPlanData
     */
    fmu: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ActionPlanData
     */
    remittancetreatment: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ActionPlanData
     */
    specialremittance: string | null;
    /**
     *
     * @type {Array<PersonalDataMedicaldiagnoseItemsInner>}
     * @memberof ActionPlanData
     */
    diagnoses: Array<PersonalDataMedicaldiagnoseItemsInner>;
    /**
     *
     * @type {Array<ActionPlanDataTreatmentsInner>}
     * @memberof ActionPlanData
     */
    treatments: Array<ActionPlanDataTreatmentsInner>;
    /**
     *
     * @type {ActionPlanDataCertificate}
     * @memberof ActionPlanData
     */
    certificate: ActionPlanDataCertificate;
    /**
     *
     * @type {boolean}
     * @memberof ActionPlanData
     */
    recipe: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ActionPlanData
     */
    far: boolean;
}
/**
 * Check if a given object implements the ActionPlanData interface.
 */
export function instanceOfActionPlanData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "fmu" in value;
    isInstance = isInstance && "remittancetreatment" in value;
    isInstance = isInstance && "specialremittance" in value;
    isInstance = isInstance && "diagnoses" in value;
    isInstance = isInstance && "treatments" in value;
    isInstance = isInstance && "certificate" in value;
    isInstance = isInstance && "recipe" in value;
    isInstance = isInstance && "far" in value;
    return isInstance;
}
export function ActionPlanDataFromJSON(json: any): ActionPlanData {
    return ActionPlanDataFromJSONTyped(json, false);
}
export function ActionPlanDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionPlanData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'comment': json['comment'],
        'fmu': json['fmu'],
        'remittancetreatment': json['remittancetreatment'],
        'specialremittance': json['specialremittance'],
        'diagnoses': ((json['diagnoses'] as Array<any>).map(PersonalDataMedicaldiagnoseItemsInnerFromJSON)),
        'treatments': ((json['treatments'] as Array<any>).map(ActionPlanDataTreatmentsInnerFromJSON)),
        'certificate': ActionPlanDataCertificateFromJSON(json['certificate']),
        'recipe': json['recipe'],
        'far': json['far'],
    };
}
export function ActionPlanDataToJSON(value?: ActionPlanData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'comment': value.comment,
        'fmu': value.fmu,
        'remittancetreatment': value.remittancetreatment,
        'specialremittance': value.specialremittance,
        'diagnoses': ((value.diagnoses as Array<any>).map(PersonalDataMedicaldiagnoseItemsInnerToJSON)),
        'treatments': ((value.treatments as Array<any>).map(ActionPlanDataTreatmentsInnerToJSON)),
        'certificate': ActionPlanDataCertificateToJSON(value.certificate),
        'recipe': value.recipe,
        'far': value.far,
    };
}