

import { defineComponent, PropType } from 'vue'

import { Selectable } from '@/lib/select'
import DiagnoseInput from './DiagnoseInput.vue'

interface Diagnose { id: string, code: string, text: string }

export default defineComponent({
  name: 'MultipleDiagnoseInput',
  components: { DiagnoseInput },
  props: {
    label: String,
    description: String,

    type: { required: true, type: String },
    types: { required: true, type: Array as PropType<Selectable[]> },

    diagnoses: { required: true, type: Array as PropType<Diagnose[]> },
    codes: { required: true, type: Array as PropType<Selectable[]> }
  },
  setup (props, { emit }) {
    function itemAdd () {
      emit('item:add')
    }

    function itemRemove (item: { id: string }) {
      emit('item:remove', item)
    }

    function itemUpdate (item: { id: string, code: string, text: string }) {
      emit('item:update', item)
    }

    function typeChanged (type: string) {
      emit('type:update', type)
    }

    return { itemAdd, itemRemove, itemUpdate, typeChanged }
  }
})

