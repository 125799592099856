/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Investigation
 */
export interface Investigation {
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    patientId: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    sickday: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    meetdate: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    startdate: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    reason: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    type: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    form: string;
    /**
     *
     * @type {string}
     * @memberof Investigation
     */
    id: string;
}
/**
 * Check if a given object implements the Investigation interface.
 */
export function instanceOfInvestigation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patientId" in value;
    isInstance = isInstance && "sickday" in value;
    isInstance = isInstance && "meetdate" in value;
    isInstance = isInstance && "startdate" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "form" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function InvestigationFromJSON(json: any): Investigation {
    return InvestigationFromJSONTyped(json, false);
}
export function InvestigationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Investigation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'patientId': json['patientId'],
        'sickday': json['sickday'],
        'meetdate': json['meetdate'],
        'startdate': json['startdate'],
        'reason': json['reason'],
        'name': json['name'],
        'type': json['type'],
        'form': json['form'],
        'id': json['id'],
    };
}
export function InvestigationToJSON(value?: Investigation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'patientId': value.patientId,
        'sickday': value.sickday,
        'meetdate': value.meetdate,
        'startdate': value.startdate,
        'reason': value.reason,
        'name': value.name,
        'type': value.type,
        'form': value.form,
        'id': value.id,
    };
}