
import { Selectable } from '@/lib/select'
import { defineComponent, PropType } from 'vue'
import CommonCodeSelect from '@/components/actionplan/CommonCodeSelect.vue'
import { PersonalDataMedicaldiagnoseItemsInner } from '../../api/index'
import { WithId } from '@/lib/types'

type PersonalDataMedicaldiagnoseItem = WithId<PersonalDataMedicaldiagnoseItemsInner>

export default defineComponent({
  name: 'DiagnoseInput',
  components: { CommonCodeSelect },
  props: {

    id: { required: true, type: String },
    index: { required: true, type: Number },
    text: { required: true, type: String },
    code: { required: true, type: String },

    options: { required: true, type: Array as PropType<Selectable[]> }
  },
  setup (props, { emit }) {
    function getTextForCode (code: string) {
      return props.options.find(i => i.value === code)?.text ?? code
    }

    function changedCode (code: string) {
      emit('update', {
        id: props.id,
        code,
        text: (props.text.trim() ?? '').length === 0 ? getTextForCode(code) : props.text
      } as PersonalDataMedicaldiagnoseItem)
    }
    function changedText (text: string) {
      emit('update', { id: props.id, code: props.code, text } as PersonalDataMedicaldiagnoseItem)
    }
    function removeItem () {
      emit('remove', { id: props.id })
    }

    return { changedText, changedCode, removeItem }
  }
})
