
import Vue, { defineComponent, ref, reactive, computed } from 'vue'
import VueScroll from 'vue-scrollto'

import FormBarChartInteractive from '@/components/chart/FormBarChartInteractive.vue'
import FormRadarChart from '@/components/chart/FormRadarChart.vue'
import FormBarChart from '@/components/chart/FormBarChart.vue'
import ClusterReviewTable from '@/components/ClusterReviewTable.vue'
import ClusterReviewTableV2 from '@/components/ClusterReviewTableV2.vue'
import ActionPlanReview from '@/components/ActionPlanReview.vue'
import ActionPlanForm from '@/components/ActionPlanForm.vue'
import PersonalQuestionsReview from '@/components/PersonalQuestionsReview.vue'
import InvestigationHeader from '@/components/InvestigationHeader.vue'
import PatientHeader from '@/components/PatientHeader.vue'

import { empty } from '@/lib/common'

import { useOverview } from '@/use/overview'
import { Config } from '@/lib/di/config'
import { useLoginGuard } from '@/use/login'
import { computedParam } from '@/use/route'
import { useRouter } from 'vue-router/composables'
import { useTitle } from '@vueuse/core'
import { useModal } from '@/use/bv-modal'

export default defineComponent({
  name: 'Overview',
  components: {
    ActionPlanForm,
    ActionPlanReview,
    ClusterReviewTable,
    ClusterReviewTableV2,
    PersonalQuestionsReview,
    FormBarChart,
    FormBarChartInteractive,
    FormRadarChart,
    InvestigationHeader,
    PatientHeader
  },
  setup () {

    const id = computedParam('id')
    const { debug } = Config.get()
    const overview = reactive(useOverview(id))

    useLoginGuard()

    const tab = window.sessionStorage
      ? computed<number>({
        get: () => {
          return parseInt(sessionStorage.getItem(id.value) ?? '1')
        },
        set: (value: number) => {
          sessionStorage.setItem(id.value, `${value}`)
        }
      })
      : ref<number>(0)

    const parts = [
      'Bakgrund',
      'Bedömning',
      'Åtgärdsplan',
      'Analys'
    ]

    const report = reactive({
      parts: [...parts],
      options: { parts }
    })

    const router = useRouter()
    function editurl ({ cluster, dimension }: { cluster: string, dimension: string }) {
      return router.resolve({ name: 'Reword', params: { cluster, dimension } }).href
    }

    function printPage () {
      window.print()
    }

    const { msgBoxOk } = useModal()

    async function saveSummary () {
      await msgBoxOk(
        'För att ladda ner filen som en PDF klicka på \'Skriv Ut\' och välj skrivaren \'Spara som PDF\' i dialogrutan.'
      )
    }

    const focused = ref<{ cluster: string, dimension: string }>()

    const reviewVersion = ref<'1' | '2'>('1')

    async function showAnswer (item: { cluster: string, dimension: string}, ui: '1' | '2' = '2') {
      reviewVersion.value = ui

      await Vue.nextTick()
      focused.value = item

      await Vue.nextTick()
      setTimeout(() => {
        VueScroll.scrollTo(`#item-${item.cluster}-${item.dimension}`, { duration: 1300, easing: 'ease-in-out', offset: -100 })
      }, ui === '2' ? 400 : 100) // .35s is default animation speed, lets wait for the expand
    }

    useTitle(() => overview.patientInfo.ready && overview.investigationInfo.ready
      ? [overview.patientInfo.data?.name ?? '', overview.investigationInfo.data?.name ?? ''].filter(s => !!s).join(' - ')
      : 'Översikt')

    return {
      overview,
      reviewVersion,
      editurl,
      id, debug, empty,
      tab, report, printPage, saveSummary, showAnswer, focused
    }
  },
  metaInfo: {
    title: 'Översikt'
  }
})
