

import { defineComponent, PropType } from 'vue'

import DiagnoseInput from '@/components/actionplan/DiagnoseInput.vue'
import DiagnoseLinks from '@/components/actionplan/DiagnoseLinks.vue'
import { Selectables } from '@/lib/select'
import { ActionPlanDataInternal, ValidationState } from '@/use/action-plan-form'
import { ArrayElement, Expand } from '@/lib/entries'

type Diagnose = Expand<ArrayElement<ActionPlanDataInternal['diagnoses']>>

export default defineComponent({
  name: 'MultipleDiagnoseInput',
  components: { DiagnoseInput, DiagnoseLinks },
  props: {
    diagnoses: { required: true, type: Array as PropType<Diagnose[]> },
    options: { required: true, type: Object as PropType<{codes: Selectables}> },
    state: { required: true, type: Object as PropType<Record<string, { text: ValidationState }>> },
    showLinks: { required: false, type: Boolean, default: true }
  },
  setup (props, { emit }) {

    function itemAdd () {
      emit('item:add')
    }

    function itemRemove (item: Expand<Pick<Diagnose, 'id'>>) {
      emit('item:remove', item)
    }

    function itemUpdate (item: Diagnose) {
      emit('item:update', item)
    }

    return { itemAdd, itemRemove, itemUpdate }
  }
})

