/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  Patient,
  PatientCreate,
  PatientListing,
  PatientUpdate,
} from '../models';
import {
    PatientFromJSON,
    PatientToJSON,
    PatientCreateFromJSON,
    PatientCreateToJSON,
    PatientListingFromJSON,
    PatientListingToJSON,
    PatientUpdateFromJSON,
    PatientUpdateToJSON,
} from '../models';
export interface PatientApiDeleteRequest {
    patientId: string;
}
export interface PatientApiCreateRequest {
    patientCreate: PatientCreate;
}
export interface PatientApiGetRequest {
    patientId: string;
}
export interface PatientApiUpdateRequest {
    patientId: string;
    patientUpdate: PatientUpdate;
}
/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
    /**
     */
    async _deleteRaw(requestParameters: PatientApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling _delete.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/patient/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }
    /**
     */
    async _delete(requestParameters: PatientApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this._deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createRaw(requestParameters: PatientApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientCreate === null || requestParameters.patientCreate === undefined) {
            throw new runtime.RequiredError('patientCreate','Required parameter requestParameters.patientCreate was null or undefined when calling create.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientCreateToJSON(requestParameters.patientCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }
    /**
     */
    async create(requestParameters: PatientApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getRaw(requestParameters: PatientApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling get.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/patient/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }
    /**
     */
    async get(requestParameters: PatientApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async listRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientListing>>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/patient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientListingFromJSON));
    }
    /**
     */
    async list(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientListing>> {
        const response = await this.listRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async updateRaw(requestParameters: PatientApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling update.');
        }
        if (requestParameters.patientUpdate === null || requestParameters.patientUpdate === undefined) {
            throw new runtime.RequiredError('patientUpdate','Required parameter requestParameters.patientUpdate was null or undefined when calling update.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/patient/{patient_id}`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PatientUpdateToJSON(requestParameters.patientUpdate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }
    /**
     */
    async update(requestParameters: PatientApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }
}