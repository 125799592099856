/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * Login CSRF token
 * @export
 * @interface LoginCsrfToken
 */
export interface LoginCsrfToken {
    /**
     *
     * @type {string}
     * @memberof LoginCsrfToken
     */
    token: string;
}
/**
 * Check if a given object implements the LoginCsrfToken interface.
 */
export function instanceOfLoginCsrfToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
export function LoginCsrfTokenFromJSON(json: any): LoginCsrfToken {
    return LoginCsrfTokenFromJSONTyped(json, false);
}
export function LoginCsrfTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginCsrfToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'token': json['token'],
    };
}
export function LoginCsrfTokenToJSON(value?: LoginCsrfToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'token': value.token,
    };
}