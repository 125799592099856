/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { AnswerValue } from './AnswerValue';
import {
    AnswerValueFromJSON,
    AnswerValueFromJSONTyped,
    AnswerValueToJSON,
} from './AnswerValue';
/**
 *
 * @export
 * @interface AnswerChildOption
 */
export interface AnswerChildOption {
    /**
     *
     * @type {string}
     * @memberof AnswerChildOption
     */
    title: string;
    /**
     *
     * @type {AnswerValue}
     * @memberof AnswerChildOption
     */
    value: AnswerValue;
    /**
     *
     * @type {string}
     * @memberof AnswerChildOption
     */
    description?: string;
}
/**
 * Check if a given object implements the AnswerChildOption interface.
 */
export function instanceOfAnswerChildOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function AnswerChildOptionFromJSON(json: any): AnswerChildOption {
    return AnswerChildOptionFromJSONTyped(json, false);
}
export function AnswerChildOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerChildOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'title': json['title'],
        'value': AnswerValueFromJSON(json['value']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function AnswerChildOptionToJSON(value?: AnswerChildOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'title': value.title,
        'value': AnswerValueToJSON(value.value),
        'description': value.description,
    };
}