import { Ref } from 'vue'

export type Nullable<T> = { [P in keyof T]: T[P] | null; }
export type UUID = string
export type WithoutId<T> = Omit<T, 'id'>

export type WithId<T> = T & { id: string }

export function str (val: string | Array<string | null>): string {
  if (typeof val === 'string') return val
  throw new TypeError(`Non string values not allowed: ${JSON.stringify(val)}`)
}

export type NonNulls<T> = { [P in keyof T]: Exclude<T[P], null>; }

export type MaybeRef<T> = T | Ref<T>
