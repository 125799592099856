
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'KeyValue',
  props: {
    items: { type: Object as PropType<Record<string, unknown>>, required: true },
    even: { type: Boolean, default: false }
  }
})
