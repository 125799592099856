/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConsentRequest
 */
export interface ConsentRequest {
    /**
     *
     * @type {string}
     * @memberof ConsentRequest
     */
    terms_version: string;
    /**
     *
     * @type {string}
     * @memberof ConsentRequest
     */
    terms_payload: string;
}
/**
 * Check if a given object implements the ConsentRequest interface.
 */
export function instanceOfConsentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "terms_version" in value;
    isInstance = isInstance && "terms_payload" in value;
    return isInstance;
}
export function ConsentRequestFromJSON(json: any): ConsentRequest {
    return ConsentRequestFromJSONTyped(json, false);
}
export function ConsentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'terms_version': json['terms_version'],
        'terms_payload': json['terms_payload'],
    };
}
export function ConsentRequestToJSON(value?: ConsentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'terms_version': value.terms_version,
        'terms_payload': value.terms_payload,
    };
}