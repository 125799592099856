
import { defineComponent, onMounted, ref } from 'vue'
import { useFormValidation } from '@/use/simple-form'
import { Api } from '@/lib/di/api'
import { OpenInvestigationCreated, OpenIdentitySession, HttpError, ResponseError, ClusterData } from '../../api/index'
import CenteredLoading from '@/components/common/CenteredLoading.vue'
import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'
import { useConsent } from '@/use/consent'
import { isTel } from '@/lib/common'
import z from 'zod'
import { computedQuery } from '@/use/route'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'Review',
  components: { CenteredLoading, BackendErrorMessage },
  setup () {
    const { data: form, inputstate, groupstate, feedback, save } = useFormValidation('open-sign', {
      email: { start: '', schema: z.string().email('Måste vara en email med @') },
      tel: { start: '', schema: z.string().regex(isTel, 'Måste vara ett telenummer') }
    })

    const grandidsession = computedQuery('grandidsession', z.string().optional())

    const oia = Api.openIdentity()
    const ila = Api.identlogin()
    const osa = Api.openSubmit()

    type State = 'FILL_FORM' | 'FILL_FORM_RETRY' | 'COMPLETE' | 'LOADING' | 'ERROR'

    const state = ref<State>('FILL_FORM')

    const investigations = ref<OpenInvestigationCreated[]>([])
    const identity = ref<OpenIdentitySession>()
    const error = ref<HttpError>()

    const { termsPayload, termsVersion, termsTitle, consent, prefill } = useConsent()

    async function saveconsent (checked: boolean) {
      if (checked) {
        const result = await prefill()
        console.log('Consent created', result)
      } else {
        consent.value = null
      }
    }

    async function submit () {
      save()

      const backUrl = document.location.toString()
      const callbackUrl = document.location.toString()

      if (state.value === 'FILL_FORM_RETRY') {
        await submitForms()
      }
      if (state.value === 'FILL_FORM') {
        state.value = 'LOADING'
        const result = await oia.start({ verifyOpenIdentityForm: { backUrl, callbackUrl } })
        if (result.redirectUrl) {
          document.location = result.redirectUrl as unknown as Location
        }
      }
    }

    const router = useRouter()
    const route = useRoute()
    const forms = ref<Array<[string, ClusterData]>>([])
    onMounted(async () => {
      forms.value = Object.entries(sessionStorage).filter(([k]) => k.startsWith('open/form/')).map(([k, v]) => [k.replace('open/form/', ''), JSON.parse(v)])
      if (forms.value.length === 0) {
        router.push({ name: 'OpenWelcome', params: route.params })
          .catch(err => console.error('Failed to navigate', err))
      }
      if (grandidsession.value) {
        await completeSign(grandidsession.value)
        await submitForms()
      }
    })

    async function completeSign (grandidsession: string) {
      const { id: organisation } = route.params
      try {
        identity.value = await oia.complete({ verifyOpenIdentityResult: { grandidsession, organisation } })
        state.value = 'LOADING'
      } catch (e) {
        console.error(e)
        state.value = 'ERROR'
      }
    }

    async function submitForms () {
      state.value = 'LOADING'
      for (const [formId, formData] of forms.value) {
        try {
          const investigation = await osa.create({ openInvestigationCreate: { email: form.email, tel: form.tel, form: { id: formId, data: formData } } })
          sessionStorage.removeItem('open/form/' + formId)
          investigations.value = [...investigations.value, investigation]
        } catch (e) {
          state.value = 'ERROR'
          if (e instanceof Error && e.name === 'ResponseError') {
            error.value = await (e as ResponseError).response.json()
          } else {
            console.error(e)
          }
        }
      }
      if (state.value !== 'ERROR') {
        state.value = 'COMPLETE'
        router.push({ query: {} })
          .catch(err => console.error('Failed to navigate', err))
        ila.doLogout()
          .catch(err => console.error('Failed to logout', err))
      }
    }

    function resetSubmit () {
      state.value = 'FILL_FORM_RETRY'
      error.value = undefined
    }

    return {
      inputstate,
      groupstate,
      feedback,
      form,
      termsPayload,
      termsTitle,
      termsVersion,
      submit,
      state,
      error,
      identity,
      investigations,
      grandidsession,
      resetSubmit,
      saveconsent,
      consent
    }
  },
  metaInfo: {
    title: 'Signera'
  }
})

