/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { LoginSessionOrganisation } from './LoginSessionOrganisation';
import {
    LoginSessionOrganisationFromJSON,
    LoginSessionOrganisationFromJSONTyped,
    LoginSessionOrganisationToJSON,
} from './LoginSessionOrganisation';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
/**
 *
 * @export
 * @interface LoginSession
 */
export interface LoginSession {
    /**
     *
     * @type {string}
     * @memberof LoginSession
     */
    created: string;
    /**
     *
     * @type {User}
     * @memberof LoginSession
     */
    user: User;
    /**
     *
     * @type {LoginSessionOrganisation}
     * @memberof LoginSession
     */
    organisation: LoginSessionOrganisation | null;
    /**
     *
     * @type {string}
     * @memberof LoginSession
     */
    type: LoginSessionTypeEnum;
}
/**
 * @export
 */
export const LoginSessionTypeEnum = {
    Login: 'login'
} as const;
export type LoginSessionTypeEnum = typeof LoginSessionTypeEnum[keyof typeof LoginSessionTypeEnum];
/**
 * Check if a given object implements the LoginSession interface.
 */
export function instanceOfLoginSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function LoginSessionFromJSON(json: any): LoginSession {
    return LoginSessionFromJSONTyped(json, false);
}
export function LoginSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'created': json['created'],
        'user': UserFromJSON(json['user']),
        'organisation': LoginSessionOrganisationFromJSON(json['organisation']),
        'type': json['type'],
    };
}
export function LoginSessionToJSON(value?: LoginSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'created': value.created,
        'user': UserToJSON(value.user),
        'organisation': LoginSessionOrganisationToJSON(value.organisation),
        'type': value.type,
    };
}