/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  ActionPlanData,
  ClusterData,
  Get200Response,
  HttpError,
  Investigation,
  InvestigationCreate,
  InvestigationUpdate,
  PersonalData,
} from '../models';
import {
    ActionPlanDataFromJSON,
    ActionPlanDataToJSON,
    ClusterDataFromJSON,
    ClusterDataToJSON,
    Get200ResponseFromJSON,
    Get200ResponseToJSON,
    HttpErrorFromJSON,
    HttpErrorToJSON,
    InvestigationFromJSON,
    InvestigationToJSON,
    InvestigationCreateFromJSON,
    InvestigationCreateToJSON,
    InvestigationUpdateFromJSON,
    InvestigationUpdateToJSON,
    PersonalDataFromJSON,
    PersonalDataToJSON,
} from '../models';
export interface InvestigationApiDeleteRequest {
    investigationId: string;
}
export interface InvestigationApiCreateRequest {
    investigationCreate: InvestigationCreate;
}
export interface InvestigationApiGetRequest {
    investigationId: string;
}
export interface InvestigationApiGetPatientAnswersRequest {
    investigationId: string;
}
export interface InvestigationApiGetPatientBackgroundRequest {
    investigationId: string;
}
export interface InvestigationApiGetProfessionActionPlanRequest {
    investigationId: string;
}
export interface InvestigationApiGetProfessionAnswersRequest {
    investigationId: string;
}
export interface InvestigationApiSavePatientAnswersRequest {
    investigationId: string;
    clusterData: ClusterData;
}
export interface InvestigationApiSavePatientBackgroundRequest {
    investigationId: string;
    personalData: PersonalData;
}
export interface InvestigationApiSaveProfessionActionPlanRequest {
    investigationId: string;
    actionPlanData: ActionPlanData;
}
export interface InvestigationApiSaveProfessionAnswersRequest {
    investigationId: string;
    clusterData: ClusterData;
}
export interface InvestigationApiUpdateRequest {
    investigationId: string;
    investigationUpdate: InvestigationUpdate;
}
/**
 *
 */
export class InvestigationApi extends runtime.BaseAPI {
    /**
     */
    async _deleteRaw(requestParameters: InvestigationApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Investigation>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling _delete.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InvestigationFromJSON(jsonValue));
    }
    /**
     */
    async _delete(requestParameters: InvestigationApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Investigation> {
        const response = await this._deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createRaw(requestParameters: InvestigationApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Investigation>> {
        if (requestParameters.investigationCreate === null || requestParameters.investigationCreate === undefined) {
            throw new runtime.RequiredError('investigationCreate','Required parameter requestParameters.investigationCreate was null or undefined when calling create.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestigationCreateToJSON(requestParameters.investigationCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InvestigationFromJSON(jsonValue));
    }
    /**
     */
    async create(requestParameters: InvestigationApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Investigation> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getRaw(requestParameters: InvestigationApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Get200Response>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling get.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => Get200ResponseFromJSON(jsonValue));
    }
    /**
     */
    async get(requestParameters: InvestigationApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Get200Response> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getPatientAnswersRaw(requestParameters: InvestigationApiGetPatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getPatientAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async getPatientAnswers(requestParameters: InvestigationApiGetPatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.getPatientAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getPatientBackgroundRaw(requestParameters: InvestigationApiGetPatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getPatientBackground.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-background`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalDataFromJSON(jsonValue));
    }
    /**
     */
    async getPatientBackground(requestParameters: InvestigationApiGetPatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalData> {
        const response = await this.getPatientBackgroundRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getProfessionActionPlanRaw(requestParameters: InvestigationApiGetProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionPlanData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getProfessionActionPlan.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-action-plan`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActionPlanDataFromJSON(jsonValue));
    }
    /**
     */
    async getProfessionActionPlan(requestParameters: InvestigationApiGetProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionPlanData> {
        const response = await this.getProfessionActionPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getProfessionAnswersRaw(requestParameters: InvestigationApiGetProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getProfessionAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async getProfessionAnswers(requestParameters: InvestigationApiGetProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.getProfessionAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async savePatientAnswersRaw(requestParameters: InvestigationApiSavePatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling savePatientAnswers.');
        }
        if (requestParameters.clusterData === null || requestParameters.clusterData === undefined) {
            throw new runtime.RequiredError('clusterData','Required parameter requestParameters.clusterData was null or undefined when calling savePatientAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClusterDataToJSON(requestParameters.clusterData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async savePatientAnswers(requestParameters: InvestigationApiSavePatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.savePatientAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async savePatientBackgroundRaw(requestParameters: InvestigationApiSavePatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling savePatientBackground.');
        }
        if (requestParameters.personalData === null || requestParameters.personalData === undefined) {
            throw new runtime.RequiredError('personalData','Required parameter requestParameters.personalData was null or undefined when calling savePatientBackground.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-background`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonalDataToJSON(requestParameters.personalData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalDataFromJSON(jsonValue));
    }
    /**
     */
    async savePatientBackground(requestParameters: InvestigationApiSavePatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalData> {
        const response = await this.savePatientBackgroundRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async saveProfessionActionPlanRaw(requestParameters: InvestigationApiSaveProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionPlanData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling saveProfessionActionPlan.');
        }
        if (requestParameters.actionPlanData === null || requestParameters.actionPlanData === undefined) {
            throw new runtime.RequiredError('actionPlanData','Required parameter requestParameters.actionPlanData was null or undefined when calling saveProfessionActionPlan.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-action-plan`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActionPlanDataToJSON(requestParameters.actionPlanData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActionPlanDataFromJSON(jsonValue));
    }
    /**
     */
    async saveProfessionActionPlan(requestParameters: InvestigationApiSaveProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionPlanData> {
        const response = await this.saveProfessionActionPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async saveProfessionAnswersRaw(requestParameters: InvestigationApiSaveProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling saveProfessionAnswers.');
        }
        if (requestParameters.clusterData === null || requestParameters.clusterData === undefined) {
            throw new runtime.RequiredError('clusterData','Required parameter requestParameters.clusterData was null or undefined when calling saveProfessionAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClusterDataToJSON(requestParameters.clusterData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async saveProfessionAnswers(requestParameters: InvestigationApiSaveProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.saveProfessionAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateRaw(requestParameters: InvestigationApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Investigation>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling update.');
        }
        if (requestParameters.investigationUpdate === null || requestParameters.investigationUpdate === undefined) {
            throw new runtime.RequiredError('investigationUpdate','Required parameter requestParameters.investigationUpdate was null or undefined when calling update.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvestigationUpdateToJSON(requestParameters.investigationUpdate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InvestigationFromJSON(jsonValue));
    }
    /**
     */
    async update(requestParameters: InvestigationApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Investigation> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }
}