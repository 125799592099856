import { render, staticRenderFns } from "./DiagnoseInput.vue?vue&type=template&id=dae64b36&scoped=true&"
import script from "./DiagnoseInput.vue?vue&type=script&lang=ts&"
export * from "./DiagnoseInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae64b36",
  null
  
)

export default component.exports