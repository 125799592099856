
import { defineComponent, PropType } from 'vue'
import { Answer, AnswerData, AnswerOption, Cluster, ClusterData, Dimension, FormDefinition } from '../api/index'
import { dimensionTitle, optionValueDescription, zipDataAndDefinition } from '../lib/form-answer'
import ClusterReviewAnswerWidget from '@/components/ClusterReviewAnswerWidget.vue'

interface FocusItem { cluster: string; dimension: string }

export default defineComponent({
  name: 'ClusterReviewTable',
  components: { ClusterReviewAnswerWidget },
  props: {
    primary: { type: Object as PropType<ClusterData>, required: false },
    secondary: { type: Object as PropType<ClusterData>, required: false },
    form: { type: Object as PropType<FormDefinition>, required: true },
    focus: { type: Object as PropType<FocusItem>, required: false },
    actionable: { type: Boolean, default: false }
  },
  setup (props) {

    interface TableItem { dimension: Dimension, title: string, primary: AnswerData, secondary: AnswerData }

    function safe (data: ClusterData | undefined, cluster: string, dimension: string): AnswerData {
      return data && cluster in data && dimension in data[cluster] ? data[cluster][dimension] : {}
    }

    function tableItems (cluster: Cluster): TableItem[] {
      return cluster.dimensions.map(dimension => {
        return {
          dimension,
          title: dimensionTitle(cluster, dimension.code),
          primary: safe(props.primary, cluster.key, dimension.code),
          secondary: safe(props.secondary, cluster.key, dimension.code),
          _rowVariant: props.focus && (props.focus.cluster === cluster.key && props.focus.dimension === dimension.code)
            ? 'warning'
            : ''
        }
      }) || []
    }

    function option (data: AnswerData, answer: Answer): AnswerOption | undefined {
      const val = data[answer.key]
      return answer.options.find(o => {
        return o.value === val || o.description === val
      })
    }

    const tableFields = [
      // { key: 'dimension', label: 'Kod', tdClass: 'dimension' },
      { key: 'title', label: 'Fråga', tdClass: 'question' },
      { key: 'patient', label: 'Patient', tdClass: 'patient' },
      { key: 'profession', label: 'Läkare', tdClass: 'profession' }
    ]

    if (props.actionable) {
      tableFields.push({ key: 'action', label: '', tdClass: '' })
    }

    return {
      option,
      dimensionTitle,
      tableItems, tableFields,
      optionValueDescription,
      zipDataAndDefinition
    }
  }
})
