/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  ActionPlanData,
  ClusterData,
  HttpError,
  PersonalData,
} from '../models';
import {
    ActionPlanDataFromJSON,
    ActionPlanDataToJSON,
    ClusterDataFromJSON,
    ClusterDataToJSON,
    HttpErrorFromJSON,
    HttpErrorToJSON,
    PersonalDataFromJSON,
    PersonalDataToJSON,
} from '../models';
export interface InvestigationDataApiGetPatientAnswersRequest {
    investigationId: string;
}
export interface InvestigationDataApiGetPatientBackgroundRequest {
    investigationId: string;
}
export interface InvestigationDataApiGetProfessionActionPlanRequest {
    investigationId: string;
}
export interface InvestigationDataApiGetProfessionAnswersRequest {
    investigationId: string;
}
export interface InvestigationDataApiSavePatientAnswersRequest {
    investigationId: string;
    clusterData: ClusterData;
}
export interface InvestigationDataApiSavePatientBackgroundRequest {
    investigationId: string;
    personalData: PersonalData;
}
export interface InvestigationDataApiSaveProfessionActionPlanRequest {
    investigationId: string;
    actionPlanData: ActionPlanData;
}
export interface InvestigationDataApiSaveProfessionAnswersRequest {
    investigationId: string;
    clusterData: ClusterData;
}
/**
 *
 */
export class InvestigationDataApi extends runtime.BaseAPI {
    /**
     */
    async getPatientAnswersRaw(requestParameters: InvestigationDataApiGetPatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getPatientAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async getPatientAnswers(requestParameters: InvestigationDataApiGetPatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.getPatientAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getPatientBackgroundRaw(requestParameters: InvestigationDataApiGetPatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getPatientBackground.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-background`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalDataFromJSON(jsonValue));
    }
    /**
     */
    async getPatientBackground(requestParameters: InvestigationDataApiGetPatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalData> {
        const response = await this.getPatientBackgroundRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getProfessionActionPlanRaw(requestParameters: InvestigationDataApiGetProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionPlanData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getProfessionActionPlan.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-action-plan`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActionPlanDataFromJSON(jsonValue));
    }
    /**
     */
    async getProfessionActionPlan(requestParameters: InvestigationDataApiGetProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionPlanData> {
        const response = await this.getProfessionActionPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getProfessionAnswersRaw(requestParameters: InvestigationDataApiGetProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling getProfessionAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async getProfessionAnswers(requestParameters: InvestigationDataApiGetProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.getProfessionAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async savePatientAnswersRaw(requestParameters: InvestigationDataApiSavePatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling savePatientAnswers.');
        }
        if (requestParameters.clusterData === null || requestParameters.clusterData === undefined) {
            throw new runtime.RequiredError('clusterData','Required parameter requestParameters.clusterData was null or undefined when calling savePatientAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClusterDataToJSON(requestParameters.clusterData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async savePatientAnswers(requestParameters: InvestigationDataApiSavePatientAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.savePatientAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async savePatientBackgroundRaw(requestParameters: InvestigationDataApiSavePatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling savePatientBackground.');
        }
        if (requestParameters.personalData === null || requestParameters.personalData === undefined) {
            throw new runtime.RequiredError('personalData','Required parameter requestParameters.personalData was null or undefined when calling savePatientBackground.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/patient-background`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonalDataToJSON(requestParameters.personalData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalDataFromJSON(jsonValue));
    }
    /**
     */
    async savePatientBackground(requestParameters: InvestigationDataApiSavePatientBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalData> {
        const response = await this.savePatientBackgroundRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async saveProfessionActionPlanRaw(requestParameters: InvestigationDataApiSaveProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionPlanData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling saveProfessionActionPlan.');
        }
        if (requestParameters.actionPlanData === null || requestParameters.actionPlanData === undefined) {
            throw new runtime.RequiredError('actionPlanData','Required parameter requestParameters.actionPlanData was null or undefined when calling saveProfessionActionPlan.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-action-plan`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActionPlanDataToJSON(requestParameters.actionPlanData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActionPlanDataFromJSON(jsonValue));
    }
    /**
     */
    async saveProfessionActionPlan(requestParameters: InvestigationDataApiSaveProfessionActionPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionPlanData> {
        const response = await this.saveProfessionActionPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async saveProfessionAnswersRaw(requestParameters: InvestigationDataApiSaveProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClusterData>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling saveProfessionAnswers.');
        }
        if (requestParameters.clusterData === null || requestParameters.clusterData === undefined) {
            throw new runtime.RequiredError('clusterData','Required parameter requestParameters.clusterData was null or undefined when calling saveProfessionAnswers.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/investigation/{investigation_id}/profession-answers`.replace(`{${"investigation_id"}}`, encodeURIComponent(String(requestParameters.investigationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClusterDataToJSON(requestParameters.clusterData),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterDataFromJSON(jsonValue));
    }
    /**
     */
    async saveProfessionAnswers(requestParameters: InvestigationDataApiSaveProfessionAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClusterData> {
        const response = await this.saveProfessionAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }
}