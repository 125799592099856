
import { computed, defineComponent, PropType } from 'vue'

import BarChart from '@/components/chart/BarChart.vue'
import { FormDefinition, ClusterData, AnswerData } from '../../api/index'
import { TooltipItem, TooltipData, createTooltipDescription } from './chart'

export default defineComponent({
  name: 'FormBarChart',
  components: { BarChart },
  props: {
    title: { type: String, required: true },
    cluster: { type: Object as PropType<ClusterData>, required: true },
    form: { type: Object as PropType<FormDefinition>, required: true }
  },
  setup (props: {cluster: ClusterData, title: string, form: FormDefinition}) {

    const colors = ['#fd91a8', '#f6bb85', '#fccd56', '#4bc0c0', '#36a2eb', '#9966ff', '#9966ff']

    const column = (ans: AnswerData) => ans.grade as number

    return {
      chartdata: computed(() => {
        const clusters = props.form.clusters
          .filter(c => c.order)
          .map(c => ({ key: c.key, title: c.title, order: c.order!.radar }))
          .sort((a, b) => a.order - b.order)

        const labels = clusters.map(({ title }) => title)
        const data = clusters.map(({ key }) => {
          const list = Object.values(props.cluster ? props.cluster[key] || {} : {})
            .filter(v => typeof column(v) === 'number')
          return list.map(v => column(v))
            .reduce((p, c) => p + c, 0) / list.length

        })

        return {
          labels,
          datasets: [{
            data,
            label: props.title,
            borderColor: colors,
            backgroundColor: colors.map(s => s + '88')
          }]
        }
      }),
      options: computed(() => {
        return {
          responsive: true,
          title: {
            display: true,
            text: props.title + ' upplevda begränsning'
          },
          tooltips: {
            callbacks: {
              title: () => 'Avvikelser och bedömningar',
              label: (item: TooltipItem, { labels }: TooltipData) => {
                const cluster = labels[item.index]
                return 'Bedömning av \'' + cluster + '\''
              },
              afterLabel: (item: TooltipItem) => createTooltipDescription(item, props.form, props.cluster, column)
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                max: 4,
                min: 0,
                stepSize: 0.5
              }
            }]
          },
          legend: { display: false }
        }
      })
    }
  }
})
