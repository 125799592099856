import { MaybeRef } from '@/lib/types'
import { useActionPlanForm } from '@/use/action-plan-form'
import { useInvestigationForm } from '@/use/cluster-form'
import { Answer, AnswerData, Cluster, ClusterData, Dimension, FormDefinition } from '../api/index'
import { usePersonalData } from '@/use/personal-form'
import { clusterMerge, keysAffectedOfUpdate } from '@/lib/form-answer'
import Vue, { computed, reactive, Ref, unref } from 'vue'
import { usePatientFromInvestigation, usePatientInvestigation } from './investigation'
import { useLoadable, useSaveable } from './loadable'

import { Api } from '@/lib/di/api'

export function useMergerPatientProfession (
  form: Ref<FormDefinition | undefined>,
  patient: Ref<ClusterData | undefined>,
  patientReady: Ref<boolean>,
  profession: Ref<ClusterData | undefined>,
  professionReady: Ref<boolean>,
  onProfessionChanged: () => unknown) {

  function onProfessionAnswerChanged ({ cluster, dim, answer, value }: { cluster: Cluster, dim: Dimension, answer?: Answer, value?: string | number }, clear = false) {
    if (!profession.value) {
      profession.value = {}
    }
    const professionData = profession.value

    if (!professionData[cluster.key]) {
      Vue.set(professionData, cluster.key, reactive({}))
    }

    const professionCluster = professionData[cluster.key]

    if (clear) {
      Vue.delete(professionCluster, dim.code)
    } else {
      const patientValue = patient.value &&
        Object.keys(patient.value).length > 0 &&
        patient.value[cluster.key][dim.code]

      if (answer !== undefined && value !== undefined) {
        const prev = professionCluster[dim.code]
        const update = { ...(prev || {}), [answer.key]: value }

        const deps = keysAffectedOfUpdate(cluster.answers, answer, answer.key, value)

        for (const k of deps) {
          Vue.delete(update, k)
        }

        const needFill = keysAffectedOfUpdate(cluster.answers, answer, answer.key, prev[answer.key])

        if (patientValue) {
          for (const k of needFill) {
            Vue.set(update, k, patientValue[k])
          }
        }

        Vue.set(professionCluster, dim.code, update)
      } else if (patientValue) {
        Vue.set(professionCluster, dim.code, reactive(patientValue))
      } else {
        // Init answer
        const data = {} as AnswerData
        for (const a of cluster.answers) {
          data[a.key] = ''
          if (a.child_key) data[a.child_key] = ''
        }
        Vue.set(professionCluster, dim.code, reactive(data))
      }
    }

    onProfessionChanged()
  }

  async function initProfessionCluster () {
    const cl = {} as ClusterData
    form.value?.clusters.forEach(c => {
      cl[c.key] = {}
      c.dimensions.forEach(dim => {
        cl[c.key][dim.code] = {}
      })
    })
    profession.value = cl

    onProfessionChanged()
  }

  const ready = computed(() => patientReady.value && professionReady.value)

  return { ready, onProfessionAnswerChanged, initProfessionCluster }
}

export function useOverview (investigationId: MaybeRef<string>) {

  const { data: profession, ready: professionReady, onChanged: onProfessionChanged, saving: savingProfession } = useSaveable(Api.investigation(),
    async api => await api.getProfessionAnswers({ investigationId: unref(investigationId) }),
    async (api, clusterData) => await api.saveProfessionAnswers({ clusterData, investigationId: unref(investigationId) })
  )

  const { data: patient, ready: patientReady } = useLoadable(Api.investigation(),
    async api => await api.getPatientAnswers({ investigationId: unref(investigationId) })
  )

  const form = useInvestigationForm(investigationId)

  const { ready, onProfessionAnswerChanged, initProfessionCluster } = useMergerPatientProfession(
    form.data,
    patient, patientReady,
    profession, professionReady,
    onProfessionChanged
  )

  const investigationInfo = usePatientInvestigation(investigationId)
  const patientInfo = usePatientFromInvestigation(investigationId)
  const plan = useActionPlanForm(investigationId)
  const personal = usePersonalData(investigationId)

  return {
    investigationInfo, patientInfo,
    plan, form, personal,
    ready,
    patient, profession,
    onProfessionAnswerChanged, savingProfession, clusterMerge, initProfessionCluster
  }
}
