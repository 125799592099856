/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { FederatedLoginErrorObject } from './FederatedLoginErrorObject';
import {
    FederatedLoginErrorObjectFromJSON,
    FederatedLoginErrorObjectFromJSONTyped,
    FederatedLoginErrorObjectToJSON,
} from './FederatedLoginErrorObject';
/**
 *
 * @export
 * @interface FederatedLogin
 */
export interface FederatedLogin {
    /**
     *
     * @type {FederatedLoginErrorObject}
     * @memberof FederatedLogin
     */
    errorObject?: FederatedLoginErrorObject;
    /**
     *
     * @type {string}
     * @memberof FederatedLogin
     */
    redirectUrl: string;
    /**
     *
     * @type {string}
     * @memberof FederatedLogin
     */
    sessionId: string;
}
/**
 * Check if a given object implements the FederatedLogin interface.
 */
export function instanceOfFederatedLogin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "redirectUrl" in value;
    isInstance = isInstance && "sessionId" in value;
    return isInstance;
}
export function FederatedLoginFromJSON(json: any): FederatedLogin {
    return FederatedLoginFromJSONTyped(json, false);
}
export function FederatedLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FederatedLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'errorObject': !exists(json, 'errorObject') ? undefined : FederatedLoginErrorObjectFromJSON(json['errorObject']),
        'redirectUrl': json['redirectUrl'],
        'sessionId': json['sessionId'],
    };
}
export function FederatedLoginToJSON(value?: FederatedLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'errorObject': FederatedLoginErrorObjectToJSON(value.errorObject),
        'redirectUrl': value.redirectUrl,
        'sessionId': value.sessionId,
    };
}