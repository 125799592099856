/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Organisation } from './Organisation';
import {
    OrganisationFromJSON,
    OrganisationFromJSONTyped,
    OrganisationToJSON,
} from './Organisation';
import type { Stats } from './Stats';
import {
    StatsFromJSON,
    StatsFromJSONTyped,
    StatsToJSON,
} from './Stats';
/**
 *
 * @export
 * @interface OrganisationWithStats
 */
export interface OrganisationWithStats {
    /**
     *
     * @type {boolean}
     * @memberof OrganisationWithStats
     */
    demo: boolean;
    /**
     *
     * @type {string}
     * @memberof OrganisationWithStats
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof OrganisationWithStats
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof OrganisationWithStats
     */
    investigations: number;
    /**
     *
     * @type {number}
     * @memberof OrganisationWithStats
     */
    patients: number;
    /**
     *
     * @type {number}
     * @memberof OrganisationWithStats
     */
    users: number;
}
/**
 * Check if a given object implements the OrganisationWithStats interface.
 */
export function instanceOfOrganisationWithStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "demo" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "investigations" in value;
    isInstance = isInstance && "patients" in value;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function OrganisationWithStatsFromJSON(json: any): OrganisationWithStats {
    return OrganisationWithStatsFromJSONTyped(json, false);
}
export function OrganisationWithStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationWithStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'demo': json['demo'],
        'name': json['name'],
        'id': json['id'],
        'investigations': json['investigations'],
        'patients': json['patients'],
        'users': json['users'],
    };
}
export function OrganisationWithStatsToJSON(value?: OrganisationWithStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'demo': value.demo,
        'name': value.name,
        'id': value.id,
        'investigations': value.investigations,
        'patients': value.patients,
        'users': value.users,
    };
}