/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ErrorValueGrandid
 */
export interface ErrorValueGrandid {
    /**
     *
     * @type {any}
     * @memberof ErrorValueGrandid
     */
    meta?: any | null;
    /**
     *
     * @type {string}
     * @memberof ErrorValueGrandid
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof ErrorValueGrandid
     */
    message: string;
}
/**
 * Check if a given object implements the ErrorValueGrandid interface.
 */
export function instanceOfErrorValueGrandid(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
export function ErrorValueGrandidFromJSON(json: any): ErrorValueGrandid {
    return ErrorValueGrandidFromJSONTyped(json, false);
}
export function ErrorValueGrandidFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorValueGrandid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
        'code': json['code'],
        'message': json['message'],
    };
}
export function ErrorValueGrandidToJSON(value?: ErrorValueGrandid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'meta': value.meta,
        'code': value.code,
        'message': value.message,
    };
}