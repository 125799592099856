import { MaybeRef } from '@/lib/types'
import { ResponseError } from './../api/runtime'
import { ActionPlanDataTreatmentsInner } from './../api/models/ActionPlanDataTreatmentsInner'
import { failWith } from '@/lib/common'
import { anyVal, Selectable, Selectables } from '@/lib/select'
import { Api } from '@/lib/di/api'
import { debounce } from '@github/mini-throttle'
import Vue, { computed, onMounted, reactive, watch, unref } from 'vue'
import { ActionPlanData, ActionPlanDataCertificate, PersonalDataMedicaldiagnoseItemsInner } from '../api/index'
import { faker } from '@faker-js/faker/locale/sv'

import { useDiagnoseCodes, useTreatmentCodes } from './diagnoses'
import { v4 as uuid } from 'uuid'

import { BackendError, errorFromResponse } from './loadable'

export interface ActionPlanOptions {
  specialremittance: Selectable[],
  remittancetreatment: Selectable[],
  fmu: Selectable[]
  treatments: {
    codes: Selectables,
    professions: Selectable[]
  },
  certificates: {
    types: Selectable[]
  },
  diagnoses: {
    codes: Selectable[]
  }
}

function useActionPlanOptions () {
  const diagnoses = useDiagnoseCodes()
  const treatments = useTreatmentCodes()

  const options: ActionPlanOptions = reactive({
    treatments: {
      codes: computed<Selectable[]>(() => (treatments.data.value ?? []).map(d => ({ text: d.label, value: d.code }))),
      professions: [
        { value: 'XS910', text: 'Arbetsterapeut' },
        { value: 'XS911', text: 'Audionom' },
        { value: 'XS912', text: 'Dietist' },
        { value: 'XS913', text: 'Kurator' },
        { value: 'XS914', text: 'Logoped' },
        { value: 'XS916', text: 'Ortoptist' },
        { value: 'XS915', text: 'Läkare' },
        { value: 'XS917', text: 'Psykolog' },
        { value: 'XS918', text: 'Fysioterapeut (sjukgymnast)' },
        { value: 'XS919', text: 'Sjuksköterska' },
        { value: 'XS920', text: 'Skötare (psykiatrisk vård)' },
        { value: 'XS921', text: 'Undersköterska' },
        { value: 'XS922', text: 'Övrig hälso- och sjukvårdspersonal' },
        { value: 'XS923', text: 'Naprapat' },
        { value: 'XS924', text: 'Kiropraktor' },
        { value: 'XS925', text: 'Barnmorska' },
        { value: 'XS926', text: 'Optiker' }
      ]
    },
    certificates: {
      types: [
        { text: 'Inget', value: '' },
        { text: 'Läkarintyg', value: 'medical-certificate' },
        { text: 'LUH Läkarutlåtande hälsotillstånd', value: 'LUH' },
        { text: 'SLU Särskilt läkarutlåtande', value: 'SLU' }
      ]
    },
    diagnoses: {
      codes: computed<Selectable[]>(() => (diagnoses.data.value ?? []).map(d => ({ text: d.label, value: d.code })))
    },
    specialremittance: [
      { text: '(ingen åtgärd)', value: '' },
      { text: 'Psykiatriker', value: 'psychiatrist' },
      { text: 'Ortoped', value: 'orthopedist' },
      { text: 'Neurolog', value: 'neurologist' }
    ],
    remittancetreatment: [
      { text: 'Röntgen', value: 'x-ray' },
      { text: 'Blodprov', value: 'blood-sample' }
    ],
    fmu: [
      { text: '(ingen åtgärd)', value: '' },
      { text: 'Nivåskattning - Skattning nedsatt delaktighet, miljö', value: 'level_estimate' },
      { text: 'Funktionsskattning - Skattning nedsatt funktion, miljö', value: 'function_estimate' },
      { text: 'FMU - Särskilt läkarutlåtande', value: 'fmu_special_medical_opinion' },
      { text: 'FMU - AFU Aktivitetsförmågeutredning', value: 'fmu_afu' },
      { text: 'FMU - TAFU Teambaserad Aktivitetsförmågeutredning', value: 'fmu_tafu' },
      { text: 'FMU - SLU Särskilt läkarutlåtande', value: 'fmu_slu' }
    ]
  })

  return { options }
}

function useActionPlanFill (plan: ActionPlanDataInternal, options: ActionPlanOptions) {
  function fill () {
    const certificate: ActionPlanDataInternal['certificate'] = {
      type: anyVal(options.certificates.types.slice(1)),
      text: faker.lorem.paragraph(1),
      comment: faker.lorem.paragraph(1)
    }
    const diagnoses: ActionPlanDataInternal['diagnoses'] = faker.helpers.arrayElements().map(() => {
      return {
        id: uuid(),
        comment: faker.lorem.paragraph(),
        code: anyVal(options.diagnoses.codes),
        text: '???'
      }
    })
    const treatments: ActionPlanDataInternal['treatments'] = faker.helpers.arrayElements().map(() => {
      return {
        id: uuid(),
        profession: anyVal(options.treatments.professions),
        code: anyVal(options.treatments.codes),
        comment: faker.lorem.paragraph(1)
      }
    })
    const data: ActionPlanDataInternal = {
      far: faker.datatype.boolean(),
      recipe: faker.datatype.boolean(),
      certificate,
      diagnoses,
      treatments,
      specialremittance: anyVal(options.specialremittance.slice(1)),
      remittancetreatment: [anyVal(options.remittancetreatment)],
      fmu: anyVal(options.fmu.slice(1)),
      comment: faker.lorem.paragraph(2)
    }

    for (const [k, v] of Object.entries(data)) {
      Vue.set(plan, k, v)
    }

  }

  return { fill }
}

export type ActionPlanDataInternal = Omit<ActionPlanData, 'diagnoses' | 'treatments'> & {
  diagnoses: Array<PersonalDataMedicaldiagnoseItemsInner & { id: string }>,
  treatments: Array<ActionPlanDataTreatmentsInner & { id: string }>
}

export type ValidationState = false | null
export type ValidatedPartial<T, Ignored extends keyof T> = Omit<Validated<T>, Ignored>
export type Validated<T> = {
  [Prop in keyof T]: ValidationState;
}

export interface ActionPlanDataValidation {
  diagnoses: Record<string, ValidatedPartial<PersonalDataMedicaldiagnoseItemsInner, 'code'>>,
  treatments: Record<string, ValidatedPartial<ActionPlanDataTreatmentsInner, 'code'>>,
  certificate: ValidatedPartial<ActionPlanDataCertificate, 'type'>
}

export function useActionPlanForm (investigationId: MaybeRef<string>) {
  const { options } = useActionPlanOptions()

  const data = reactive <ActionPlanDataInternal>({
    far: false,
    recipe: false,
    certificate: {
      type: '',
      text: '',
      comment: ''
    },
    diagnoses: [],
    treatments: [],
    specialremittance: '',
    remittancetreatment: [],
    fmu: '',
    comment: ''
  })

  const validation = reactive<ActionPlanDataValidation>({
    diagnoses: {},
    treatments: {},
    certificate: {
      comment: null,
      text: null
    }
  })

  const { fill: fakeFillPlan } = useActionPlanFill(data, options)

  const state = reactive({
    changed: false,
    saving: false,
    loading: false,
    inited: false,
    error: false as false | BackendError
  })

  async function validate () {
    validation.certificate.comment = !!data.certificate.comment?.match(/\w+/) || data.certificate.type === '' ? null : false
    validation.certificate.text = !!data.certificate.text?.match(/\w+/) || data.certificate.type === '' ? null : false
    validation.treatments = reactive({})
    for (const t of data.treatments) {
      Vue.set(validation.treatments, t.id, {
        comment: t.comment?.match(/\w+/) ? null : false,
        profession: t.profession?.match(/\w+/) ? null : false
      })
    }
    validation.diagnoses = reactive({})
    for (const d of data.diagnoses) {
      Vue.set(validation.diagnoses, d.id, {
        code: d.code.match(/\w+/) ? null : false,
        text: d.text?.match(/\w+/) ? null : false
      })
    }
  }

  const saveEager = async () => {
    state.saving = true
    try {
      await save()
    } catch (err) {
      if (err instanceof ResponseError) {
        state.error = await errorFromResponse(err)
      } else {
        console.warn('Ungaught error in saveEager():', err)
      }
    } finally {
      state.saving = false
      state.changed = false
    }
  }
  const saveLazy = debounce(saveEager, 2000)

  watch(data, async () => {
    if (!state.inited) return

    state.changed = true
    validate()
      .catch(err => console.error('Failed to validate form data', err))
    saveLazy()
  })

  const api = Api.investigation()
  onMounted(async () => {
    state.loading = true
    state.inited = false
    const saved = await failWith<ActionPlanData>(undefined, async () => await api.getProfessionActionPlan({ investigationId: unref(investigationId) }))
    if (saved) {
      const { diagnoses, treatments, ...rest } = saved

      for (const [key, value] of Object.entries(rest)) {
        Vue.set(data, key, value)
      }
      Vue.set(data, 'diagnoses', diagnoses.map(({ code, text }) => ({ code, comment: text, id: uuid() })))
      Vue.set(data, 'treatments', treatments.map(({ code, profession, comment }) => ({ code, profession, comment, id: uuid() })))
      validate()
        .catch(err => console.error('Failed to validate form data', err))
    }
    state.loading = false

    // This is bad, relying on time... ;(
    setTimeout(() => {
      state.inited = true
    }, 1000)
  })

  async function save () {
    state.saving = true
    try {
      const { diagnoses, treatments, certificate, ...rest } = data
      await api.saveProfessionActionPlan({
        investigationId: unref(investigationId),
        actionPlanData: {
          ...rest,
          certificate: certificate.type ? certificate : { type: certificate.type, comment: null, text: null },
          diagnoses: diagnoses.map(({ text, code }) => ({ code, text })),
          treatments: treatments.map(({ comment, profession, code }) => ({ code, comment, profession }))
        }
      })
    } catch (err) {
      state.error = await errorFromResponse(err)
    } finally {
      state.saving = false
    }
  }

  const saveText = computed(() => {
    if (state.saving) {
      return 'Sparar...'
    } else if (state.changed) {
      return 'Sparar om 2 sek...'
    } else if (state.loading) {
      return 'Laddar...'
    } else {
      return 'Sparad'
    }
  })

  function addDiagnoseItem () {
    data.diagnoses.push({ id: uuid(), code: '', text: '' })
  }

  function removeDiagnoseItem (item: { id: string }) {
    data.diagnoses = data.diagnoses.filter(({ id }) => id !== item.id)
  }

  function updateDiagnoseItem (update: { id: string, code: string, text: string }) {
    data.diagnoses = data.diagnoses.map(itm => {
      return itm.id === update.id ? { ...update } : itm
    })
  }

  function addTreatmentItem () {
    data.treatments.push({ id: uuid(), code: '', profession: '', comment: '' })
  }

  function removeTreatmentItem (item: { id: string }) {
    data.treatments = data.treatments.filter(({ id }) => id !== item.id)
  }

  function updateTreatmentItem (update: { id: string, code: string, profession: string, comment: string }) {
    data.treatments = data.treatments.map(itm => {
      return itm.id === update.id ? { ...update } : itm
    })
  }

  return {
    options, data, fakeFillPlan, saveText, validation,

    addDiagnoseItem, removeDiagnoseItem, updateDiagnoseItem,
    addTreatmentItem, removeTreatmentItem, updateTreatmentItem
  }
}
