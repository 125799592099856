

import { HttpError } from '@/api'
import { BackendError } from '@/use/loadable'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'BackendErrorMessage',
  props: {
    value: { type: Object as PropType<BackendError | HttpError> }
  }
})
