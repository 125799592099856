/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Identity } from './Identity';
import {
    IdentityFromJSON,
    IdentityFromJSONTyped,
    IdentityToJSON,
} from './Identity';
import type { Organisation } from './Organisation';
import {
    OrganisationFromJSON,
    OrganisationFromJSONTyped,
    OrganisationToJSON,
} from './Organisation';
/**
 *
 * @export
 * @interface OpenIdentitySession
 */
export interface OpenIdentitySession {
    /**
     *
     * @type {string}
     * @memberof OpenIdentitySession
     */
    created: string;
    /**
     *
     * @type {Identity}
     * @memberof OpenIdentitySession
     */
    identity: Identity;
    /**
     *
     * @type {Organisation}
     * @memberof OpenIdentitySession
     */
    organisation: Organisation;
    /**
     *
     * @type {string}
     * @memberof OpenIdentitySession
     */
    type: OpenIdentitySessionTypeEnum;
}
/**
 * @export
 */
export const OpenIdentitySessionTypeEnum = {
    OpenIdentity: 'open-identity'
} as const;
export type OpenIdentitySessionTypeEnum = typeof OpenIdentitySessionTypeEnum[keyof typeof OpenIdentitySessionTypeEnum];
/**
 * Check if a given object implements the OpenIdentitySession interface.
 */
export function instanceOfOpenIdentitySession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "identity" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function OpenIdentitySessionFromJSON(json: any): OpenIdentitySession {
    return OpenIdentitySessionFromJSONTyped(json, false);
}
export function OpenIdentitySessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenIdentitySession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'created': json['created'],
        'identity': IdentityFromJSON(json['identity']),
        'organisation': OrganisationFromJSON(json['organisation']),
        'type': json['type'],
    };
}
export function OpenIdentitySessionToJSON(value?: OpenIdentitySession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'created': value.created,
        'identity': IdentityToJSON(value.identity),
        'organisation': OrganisationToJSON(value.organisation),
        'type': value.type,
    };
}