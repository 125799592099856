/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const AuthService = {
    Bankid: 'bankid',
    Netidaccess: 'netidaccess',
    Netidenterprise: 'netidenterprise'
} as const;
export type AuthService = typeof AuthService[keyof typeof AuthService];
export function AuthServiceFromJSON(json: any): AuthService {
    return AuthServiceFromJSONTyped(json, false);
}
export function AuthServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthService {
    return json as AuthService;
}
export function AuthServiceToJSON(value?: AuthService | null): any {
    return value as any;
}