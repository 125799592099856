/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuditItem
 */
export interface AuditItem {
    /**
     *
     * @type {number}
     * @memberof AuditItem
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof AuditItem
     */
    at: Date;
    /**
     *
     * @type {string}
     * @memberof AuditItem
     */
    action: string;
    /**
     *
     * @type {string}
     * @memberof AuditItem
     */
    subject: string;
    /**
     *
     * @type {string}
     * @memberof AuditItem
     */
    subjectPrincipal: string;
    /**
     *
     * @type {string}
     * @memberof AuditItem
     */
    resource: string;
    /**
     *
     * @type {string}
     * @memberof AuditItem
     */
    resourceOwner: string;
    /**
     *
     * @type {object}
     * @memberof AuditItem
     */
    change?: object;
}
/**
 * Check if a given object implements the AuditItem interface.
 */
export function instanceOfAuditItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "at" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "subjectPrincipal" in value;
    isInstance = isInstance && "resource" in value;
    isInstance = isInstance && "resourceOwner" in value;
    return isInstance;
}
export function AuditItemFromJSON(json: any): AuditItem {
    return AuditItemFromJSONTyped(json, false);
}
export function AuditItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'id': json['id'],
        'at': (new Date(json['at'])),
        'action': json['action'],
        'subject': json['subject'],
        'subjectPrincipal': json['subjectPrincipal'],
        'resource': json['resource'],
        'resourceOwner': json['resourceOwner'],
        'change': !exists(json, 'change') ? undefined : json['change'],
    };
}
export function AuditItemToJSON(value?: AuditItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'id': value.id,
        'at': (value.at.toISOString()),
        'action': value.action,
        'subject': value.subject,
        'subjectPrincipal': value.subjectPrincipal,
        'resource': value.resource,
        'resourceOwner': value.resourceOwner,
        'change': value.change,
    };
}