
import { defineComponent, PropType } from 'vue'
import { ClusterData, FormDefinition } from '../api/index'

import ClusterReview from '@/components/ClusterReview.vue'
import { computedParam } from '@/use/route'

export default defineComponent({
  props: {
    clusters: { type: Object as PropType<ClusterData>, required: true },
    form: { type: Object as PropType<FormDefinition>, required: true }
  },
  components: { ClusterReview },
  setup () {
    const id = computedParam('id')

    return { id }
  }
})
