/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { TsoaFieldErrorItem } from './TsoaFieldErrorItem';
import {
    TsoaFieldErrorItemFromJSON,
    TsoaFieldErrorItemFromJSONTyped,
    TsoaFieldErrorItemToJSON,
} from './TsoaFieldErrorItem';
/**
 *
 * @export
 * @interface TsoaFieldError
 */
export interface TsoaFieldError {
    [key: string]: TsoaFieldErrorItem;
}
/**
 * Check if a given object implements the TsoaFieldError interface.
 */
export function instanceOfTsoaFieldError(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function TsoaFieldErrorFromJSON(json: any): TsoaFieldError {
    return TsoaFieldErrorFromJSONTyped(json, false);
}
export function TsoaFieldErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TsoaFieldError {
    return json;
}
export function TsoaFieldErrorToJSON(value?: TsoaFieldError | null): any {
    return value;
}