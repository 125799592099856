/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { ErrorValuePrismaRelation } from './ErrorValuePrismaRelation';
import {
    ErrorValuePrismaRelationFromJSON,
    ErrorValuePrismaRelationFromJSONTyped,
    ErrorValuePrismaRelationToJSON,
} from './ErrorValuePrismaRelation';
/**
 *
 * @export
 * @interface ErrorValuePrisma
 */
export interface ErrorValuePrisma {
    /**
     *
     * @type {ErrorValuePrismaRelation}
     * @memberof ErrorValuePrisma
     */
    relation?: ErrorValuePrismaRelation;
    /**
     *
     * @type {Array<string>}
     * @memberof ErrorValuePrisma
     */
    target?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ErrorValuePrisma
     */
    type: ErrorValuePrismaTypeEnum;
}
/**
 * @export
 */
export const ErrorValuePrismaTypeEnum = {
    NotUnique: 'NOT_UNIQUE',
    NotFound: 'NOT_FOUND',
    ViolateRelation: 'VIOLATE_RELATION'
} as const;
export type ErrorValuePrismaTypeEnum = typeof ErrorValuePrismaTypeEnum[keyof typeof ErrorValuePrismaTypeEnum];
/**
 * Check if a given object implements the ErrorValuePrisma interface.
 */
export function instanceOfErrorValuePrisma(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function ErrorValuePrismaFromJSON(json: any): ErrorValuePrisma {
    return ErrorValuePrismaFromJSONTyped(json, false);
}
export function ErrorValuePrismaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorValuePrisma {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'relation': !exists(json, 'relation') ? undefined : ErrorValuePrismaRelationFromJSON(json['relation']),
        'target': !exists(json, 'target') ? undefined : json['target'],
        'type': json['type'],
    };
}
export function ErrorValuePrismaToJSON(value?: ErrorValuePrisma | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'relation': ErrorValuePrismaRelationToJSON(value.relation),
        'target': value.target,
        'type': value.type,
    };
}