/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Answer } from './Answer';
import {
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './Answer';
import type { ClusterIngress } from './ClusterIngress';
import {
    ClusterIngressFromJSON,
    ClusterIngressFromJSONTyped,
    ClusterIngressToJSON,
} from './ClusterIngress';
import type { ClusterOrder } from './ClusterOrder';
import {
    ClusterOrderFromJSON,
    ClusterOrderFromJSONTyped,
    ClusterOrderToJSON,
} from './ClusterOrder';
import type { Dimension } from './Dimension';
import {
    DimensionFromJSON,
    DimensionFromJSONTyped,
    DimensionToJSON,
} from './Dimension';
/**
 * Cluster with questions and answers
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     *
     * @type {string}
     * @memberof Cluster
     */
    key: string;
    /**
     *
     * @type {string}
     * @memberof Cluster
     */
    title: string;
    /**
     *
     * @type {ClusterOrder}
     * @memberof Cluster
     */
    order?: ClusterOrder;
    /**
     *
     * @type {ClusterIngress}
     * @memberof Cluster
     */
    ingress: ClusterIngress;
    /**
     *
     * @type {string}
     * @memberof Cluster
     */
    question: string;
    /**
     *
     * @type {Array<Dimension>}
     * @memberof Cluster
     */
    dimensions: Array<Dimension>;
    /**
     *
     * @type {Array<Answer>}
     * @memberof Cluster
     */
    answers: Array<Answer>;
}
/**
 * Check if a given object implements the Cluster interface.
 */
export function instanceOfCluster(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "ingress" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "dimensions" in value;
    isInstance = isInstance && "answers" in value;
    return isInstance;
}
export function ClusterFromJSON(json: any): Cluster {
    return ClusterFromJSONTyped(json, false);
}
export function ClusterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cluster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'key': json['key'],
        'title': json['title'],
        'order': !exists(json, 'order') ? undefined : ClusterOrderFromJSON(json['order']),
        'ingress': ClusterIngressFromJSON(json['ingress']),
        'question': json['question'],
        'dimensions': ((json['dimensions'] as Array<any>).map(DimensionFromJSON)),
        'answers': ((json['answers'] as Array<any>).map(AnswerFromJSON)),
    };
}
export function ClusterToJSON(value?: Cluster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'key': value.key,
        'title': value.title,
        'order': ClusterOrderToJSON(value.order),
        'ingress': ClusterIngressToJSON(value.ingress),
        'question': value.question,
        'dimensions': ((value.dimensions as Array<any>).map(DimensionToJSON)),
        'answers': ((value.answers as Array<any>).map(AnswerToJSON)),
    };
}