import { failWith } from '@/lib/common'
import { Api } from '@/lib/di/api'
import { MaybeRef } from '@/lib/types'
import { unref } from 'vue'
import { ActionPlanData, ClusterData, PersonalData } from '../api/index'

export interface StateProvider<T> {
  save: (body: T) => Promise<T>
  load: () => Promise<T | undefined>
}

export function usePatientAnswerState (investigationId: MaybeRef<string>): StateProvider<ClusterData> {
  const api = Api.investigation()
  return {
    save: async (clusterData: ClusterData) => await api.savePatientAnswers({ clusterData, investigationId: unref(investigationId) }),
    load: async () => await failWith(undefined, async () => await api.getPatientAnswers({ investigationId: unref(investigationId) }))
  }
}

export function useProfessionAnswerState (investigationId: MaybeRef<string>): StateProvider<ClusterData> {
  const api = Api.investigation()
  return {
    save: async (clusterData: ClusterData) => await api.saveProfessionAnswers({ clusterData, investigationId: unref(investigationId) }),
    load: async () => await failWith(undefined, async () => await api.getProfessionAnswers({ investigationId: unref(investigationId) }))
  }
}

export function useBackgroundState (investigationId: MaybeRef<string>): StateProvider<PersonalData> {
  const api = Api.investigation()
  return {
    save: async (personalData: PersonalData) => await api.savePatientBackground({ investigationId: unref(investigationId), personalData }),
    load: async () => await api.getPatientBackground({ investigationId: unref(investigationId) })
  }
}

export function useActionPlanState (investigationId: MaybeRef<string>): StateProvider<ActionPlanData> {
  const api = Api.investigation()
  return {
    save: async (actionPlanData: ActionPlanData) => await api.saveProfessionActionPlan({ investigationId: unref(investigationId), actionPlanData }),
    load: async () => await failWith(undefined, async () => await api.getProfessionActionPlan({ investigationId: unref(investigationId) }))
  }
}
