/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganisationCreate
 */
export interface OrganisationCreate {
    /**
     *
     * @type {boolean}
     * @memberof OrganisationCreate
     */
    demo: boolean;
    /**
     *
     * @type {string}
     * @memberof OrganisationCreate
     */
    name: string;
}
/**
 * Check if a given object implements the OrganisationCreate interface.
 */
export function instanceOfOrganisationCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "demo" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function OrganisationCreateFromJSON(json: any): OrganisationCreate {
    return OrganisationCreateFromJSONTyped(json, false);
}
export function OrganisationCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'demo': json['demo'],
        'name': json['name'],
    };
}
export function OrganisationCreateToJSON(value?: OrganisationCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'demo': value.demo,
        'name': value.name,
    };
}