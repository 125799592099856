
import { useUserForm } from '@/use/admin/user-form'
import { useLoadable } from '@/use/loadable'
import { defineComponent } from 'vue'

import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'
import { Api } from '@/lib/di/api'
import { useLoginGuard } from '@/use/login'
import { computedParam } from '@/use/route'

interface Org { name: string, users: Array<{ name: string, username: string, id: string; role: string }> }

export default defineComponent({
  name: 'Organisation',
  components: { BackendErrorMessage },
  setup () {
    const id = computedParam('id')
    const org = useLoadable(Api.admin(), async api => await (api.getOrganisation({ organisationId: id.value }) as unknown as Promise<Org>))
    const {
      form, valid, canSubmit, doSubmit, doClear, editing, doEdit, doRemove,
      error: createError, loading: createLoading, idpValidator
    } = useUserForm(id, onPostSubmit)

    useLoginGuard(['Superadmin'])

    async function onPostSubmit () {
      await org.load()
    }

    return {
      ...org,
      form,
      valid,
      fields: [
        { label: 'ID', key: 'id' },
        { label: 'Namn', key: 'name' },
        { label: 'Användarnamn', key: 'username' },
        { label: 'Lösenord', key: 'password' },
        { label: 'Roll', key: 'role' },
        { label: '', key: 'action' }
      ],
      roles: [
        { value: 'Admin', text: 'Admin (Vårdadmin)' },
        { value: 'Profession', text: 'Profession' }
      ],
      canSubmit,
      editing,
      createError,
      createLoading,
      idpValidator,
      doSubmit,
      doClear,
      doEdit,
      doRemove
    }
  },
  metaInfo: {
    title: 'Användare'
  }
})
