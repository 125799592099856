/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  Organisation,
  OrganisationCreate,
  OrganisationWithUsers,
  Organisations,
  User,
  UserCreate,
  UserUpdate,
} from '../models';
import {
    OrganisationFromJSON,
    OrganisationToJSON,
    OrganisationCreateFromJSON,
    OrganisationCreateToJSON,
    OrganisationWithUsersFromJSON,
    OrganisationWithUsersToJSON,
    OrganisationsFromJSON,
    OrganisationsToJSON,
    UserFromJSON,
    UserToJSON,
    UserCreateFromJSON,
    UserCreateToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models';
export interface AdminApiCreateOrganisationRequest {
    organisationCreate: OrganisationCreate;
}
export interface AdminApiCreateUserRequest {
    organisationId: string;
    userCreate: UserCreate;
}
export interface AdminApiDeleteOrganisationRequest {
    organisationId: string;
}
export interface AdminApiDeleteUserRequest {
    organisationId: string;
    userId: string;
}
export interface AdminApiGetOrganisationRequest {
    organisationId: string;
}
export interface AdminApiUpdateOrganisationRequest {
    organisationId: string;
    organisationCreate: OrganisationCreate;
}
export interface AdminApiUpdateUserRequest {
    organisationId: string;
    userId: string;
    userUpdate: UserUpdate;
}
/**
 *
 */
export class AdminApi extends runtime.BaseAPI {
    /**
     */
    async createOrganisationRaw(requestParameters: AdminApiCreateOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationCreate === null || requestParameters.organisationCreate === undefined) {
            throw new runtime.RequiredError('organisationCreate','Required parameter requestParameters.organisationCreate was null or undefined when calling createOrganisation.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/admin/orgainsations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationCreateToJSON(requestParameters.organisationCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }
    /**
     */
    async createOrganisation(requestParameters: AdminApiCreateOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.createOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createUserRaw(requestParameters: AdminApiCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling createUser.');
        }
        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
            throw new runtime.RequiredError('userCreate','Required parameter requestParameters.userCreate was null or undefined when calling createUser.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}/user`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateToJSON(requestParameters.userCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async createUser(requestParameters: AdminApiCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteOrganisationRaw(requestParameters: AdminApiDeleteOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisation.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }
    /**
     */
    async deleteOrganisation(requestParameters: AdminApiDeleteOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.deleteOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteUserRaw(requestParameters: AdminApiDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteUser.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}/user/{userId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async deleteUser(requestParameters: AdminApiDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.deleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getOrganisationRaw(requestParameters: AdminApiGetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganisationWithUsers>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisation.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationWithUsersFromJSON(jsonValue));
    }
    /**
     */
    async getOrganisation(requestParameters: AdminApiGetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganisationWithUsers> {
        const response = await this.getOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async listOrganisationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisations>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/admin/orgainsations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationsFromJSON(jsonValue));
    }
    /**
     */
    async listOrganisations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisations> {
        const response = await this.listOrganisationsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async updateOrganisationRaw(requestParameters: AdminApiUpdateOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling updateOrganisation.');
        }
        if (requestParameters.organisationCreate === null || requestParameters.organisationCreate === undefined) {
            throw new runtime.RequiredError('organisationCreate','Required parameter requestParameters.organisationCreate was null or undefined when calling updateOrganisation.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationCreateToJSON(requestParameters.organisationCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }
    /**
     */
    async updateOrganisation(requestParameters: AdminApiUpdateOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.updateOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateUserRaw(requestParameters: AdminApiUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling updateUser.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }
        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling updateUser.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/admin/orgainsations/{organisationId}/user/{userId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async updateUser(requestParameters: AdminApiUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
}