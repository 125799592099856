import { any } from './array'

export interface Selectable { text: string, value: string | number | null }
export type Selectables = Selectable[]

export function anyVal (arr: Selectables) {
  return any(arr.map(a => a.value))
}

export function getText (selectables: Selectables, value: string | undefined): string | number | undefined {
  return selectables.filter(o => o.value === value)
    .map(o => o.text)
    .find(() => true)
}
