
import { defineComponent, ref } from 'vue'
import { Config } from '@/lib/di/config'

export default defineComponent({
  name: 'Debugger',
  props: { data: Object },
  setup () {
    const { debug } = Config.get()
    const expanded = ref(false)
    return { debug, expanded }
  }
})

