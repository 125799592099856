
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CenteredLoading',
  props: {
    loadingText: { type: String, default: () => 'Laddar...' },
    vertical: { type: Boolean, default: () => false }
  }
})
