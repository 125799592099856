import { ClusterData, FormDefinition } from '../api/index'
import Vue, { Ref } from 'vue'
import { random } from 'lodash'

export function useFakeFillForm (data: ClusterData, form: Ref<FormDefinition | undefined>) {
  function fill () {
    if (!form.value) return
    for (const cluster of form.value.clusters) {
      for (const { code } of cluster.dimensions) {
        for (const a of cluster.answers) {
          const opt = a.options[random(0, a.options.length - 1, false)]
          if (opt) {
            Vue.set(data[cluster.key][code], a.key, opt.value)
          }
        }
      }
    }
  }
  return { fill }
}
