
import { defineComponent, PropType } from 'vue'
import { Answer, AnswerOption, Cluster, Dimension, DimensionData } from '../api/index'

import ClusterReviewAnswerWidget from '@/components/ClusterReviewAnswerWidget.vue'

export default defineComponent({
  name: 'ClusterReviewDimensionWidget',
  components: { ClusterReviewAnswerWidget },
  props: {
    cluster: { type: Object as PropType<Cluster>, required: true },
    data: { type: Object as PropType<DimensionData>, required: true }
  },
  setup (props) {
    function option (dimension: Dimension, answer: Answer): AnswerOption {
      return answer.options.find(o => {
        return o.value === props.data[dimension.code][answer.key] ||
          o.description === props.data[dimension.code][answer.key]
      })!
    }

    return { option }
  }
})
