/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { DimensionData } from './DimensionData';
import {
    DimensionDataFromJSON,
    DimensionDataFromJSONTyped,
    DimensionDataToJSON,
} from './DimensionData';
/**
 * cluster -> dimension -> answer -> value
 * @export
 * @interface ClusterData
 */
export interface ClusterData {
    [key: string]: DimensionData;
}
/**
 * Check if a given object implements the ClusterData interface.
 */
export function instanceOfClusterData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function ClusterDataFromJSON(json: any): ClusterData {
    return ClusterDataFromJSONTyped(json, false);
}
export function ClusterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterData {
    return json;
}
export function ClusterDataToJSON(value?: ClusterData | null): any {
    return value;
}