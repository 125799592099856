/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { LoginStatusFailed } from './LoginStatusFailed';
import {
    LoginStatusFailedFromJSON,
    LoginStatusFailedFromJSONTyped,
    LoginStatusFailedToJSON,
} from './LoginStatusFailed';
import type { LoginStatusSuccess } from './LoginStatusSuccess';
import {
    LoginStatusSuccessFromJSON,
    LoginStatusSuccessFromJSONTyped,
    LoginStatusSuccessToJSON,
} from './LoginStatusSuccess';
import type { LoginStatusSuccessOrganisation } from './LoginStatusSuccessOrganisation';
import {
    LoginStatusSuccessOrganisationFromJSON,
    LoginStatusSuccessOrganisationFromJSONTyped,
    LoginStatusSuccessOrganisationToJSON,
} from './LoginStatusSuccessOrganisation';
/**
 *
 * @export
 * @interface LoginStatus
 */
export interface LoginStatus {
    /**
     *
     * @type {LoginStatusSuccessOrganisation}
     * @memberof LoginStatus
     */
    organisation?: LoginStatusSuccessOrganisation;
    /**
     *
     * @type {string}
     * @memberof LoginStatus
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LoginStatus
     */
    username: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LoginStatus
     */
    scopes: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LoginStatus
     */
    role: string;
    /**
     *
     * @type {string}
     * @memberof LoginStatus
     */
    type: LoginStatusTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof LoginStatus
     */
    loggedIn: LoginStatusLoggedInEnum;
}
/**
 * @export
 */
export const LoginStatusTypeEnum = {
    Identity: 'identity',
    Login: 'login'
} as const;
export type LoginStatusTypeEnum = typeof LoginStatusTypeEnum[keyof typeof LoginStatusTypeEnum];
/**
 * @export
 */
export const LoginStatusLoggedInEnum = {
    False: false
} as const;
export type LoginStatusLoggedInEnum = typeof LoginStatusLoggedInEnum[keyof typeof LoginStatusLoggedInEnum];
/**
 * Check if a given object implements the LoginStatus interface.
 */
export function instanceOfLoginStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "scopes" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "loggedIn" in value;
    return isInstance;
}
export function LoginStatusFromJSON(json: any): LoginStatus {
    return LoginStatusFromJSONTyped(json, false);
}
export function LoginStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'organisation': !exists(json, 'organisation') ? undefined : LoginStatusSuccessOrganisationFromJSON(json['organisation']),
        'name': json['name'],
        'username': json['username'],
        'scopes': json['scopes'],
        'role': json['role'],
        'type': json['type'],
        'loggedIn': json['loggedIn'],
    };
}
export function LoginStatusToJSON(value?: LoginStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'organisation': LoginStatusSuccessOrganisationToJSON(value.organisation),
        'name': value.name,
        'username': value.username,
        'scopes': value.scopes,
        'role': value.role,
        'type': value.type,
        'loggedIn': value.loggedIn,
    };
}