/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UsersUsersInner
 */
export interface UsersUsersInner {
    /**
     *
     * @type {string}
     * @memberof UsersUsersInner
     */
    role: string;
    /**
     *
     * @type {Array<string>}
     * @memberof UsersUsersInner
     */
    idp_usernames: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UsersUsersInner
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof UsersUsersInner
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UsersUsersInner
     */
    id: string;
}
/**
 * Check if a given object implements the UsersUsersInner interface.
 */
export function instanceOfUsersUsersInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "idp_usernames" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function UsersUsersInnerFromJSON(json: any): UsersUsersInner {
    return UsersUsersInnerFromJSONTyped(json, false);
}
export function UsersUsersInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersUsersInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'role': json['role'],
        'idp_usernames': json['idp_usernames'],
        'username': json['username'],
        'name': json['name'],
        'id': json['id'],
    };
}
export function UsersUsersInnerToJSON(value?: UsersUsersInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'role': value.role,
        'idp_usernames': value.idp_usernames,
        'username': value.username,
        'name': value.name,
        'id': value.id,
    };
}