
import { defineComponent, PropType } from 'vue'
import { ClusterData, FormDefinition } from '../api/index'

import ClusterReviewDimensionWidget from '@/components/ClusterReviewDimensionWidget.vue'
import { zipDataAndDefinition } from '@/lib/form-answer'

export default defineComponent({
  components: { ClusterReviewDimensionWidget },
  props: {
    clusters: { type: Object as PropType<ClusterData>, required: true },
    form: { type: Object as PropType<FormDefinition>, required: true }
  },
  setup () {

    return {
      zipDataAndDefinition
    }
  }
})
