/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InvestigationUpdate
 */
export interface InvestigationUpdate {
    /**
     *
     * @type {string}
     * @memberof InvestigationUpdate
     */
    sickday: string;
    /**
     *
     * @type {string}
     * @memberof InvestigationUpdate
     */
    meetdate: string;
    /**
     *
     * @type {string}
     * @memberof InvestigationUpdate
     */
    reason: string;
    /**
     *
     * @type {string}
     * @memberof InvestigationUpdate
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof InvestigationUpdate
     */
    type: string;
}
/**
 * Check if a given object implements the InvestigationUpdate interface.
 */
export function instanceOfInvestigationUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sickday" in value;
    isInstance = isInstance && "meetdate" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function InvestigationUpdateFromJSON(json: any): InvestigationUpdate {
    return InvestigationUpdateFromJSONTyped(json, false);
}
export function InvestigationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'sickday': json['sickday'],
        'meetdate': json['meetdate'],
        'reason': json['reason'],
        'name': json['name'],
        'type': json['type'],
    };
}
export function InvestigationUpdateToJSON(value?: InvestigationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'sickday': value.sickday,
        'meetdate': value.meetdate,
        'reason': value.reason,
        'name': value.name,
        'type': value.type,
    };
}