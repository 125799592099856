
import { defineComponent, PropType } from 'vue'
import { Answer, AnswerOption, Cluster, Dimension } from '../api/index'

export default defineComponent({
  name: 'ClusterReviewAnswerWidget',
  props: {
    cluster: { type: Object as PropType<Cluster>, required: true },
    dimension: { type: Object as PropType<Dimension>, required: true },
    answer: { type: Object as PropType<Answer>, required: true },
    option: { type: Object as PropType<AnswerOption>, required: false },
    isPrimary: { type: Boolean, required: true },
    showOptionAsTitle: { type: Boolean, required: true }
  },
  setup () {
    const colormap: Record<string, string> = {
      red: 'bg-danger',
      yellow: 'bg-warning',
      green: 'bg-success'
    }

    return {
      color: (coloring?: string) => coloring ? colormap[coloring] : ''
    }

  }
})
