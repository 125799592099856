/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { PersonalDataMedicaldiagnose } from './PersonalDataMedicaldiagnose';
import {
    PersonalDataMedicaldiagnoseFromJSON,
    PersonalDataMedicaldiagnoseFromJSONTyped,
    PersonalDataMedicaldiagnoseToJSON,
} from './PersonalDataMedicaldiagnose';
/**
 *
 * @export
 * @interface PersonalData
 */
export interface PersonalData {
    /**
     *
     * @type {PersonalDataMedicaldiagnose}
     * @memberof PersonalData
     */
    medicaldiagnose: PersonalDataMedicaldiagnose;
    /**
     *
     * @type {string}
     * @memberof PersonalData
     */
    occupation: string;
    /**
     *
     * @type {string}
     * @memberof PersonalData
     */
    martialstatus: string;
    /**
     *
     * @type {string}
     * @memberof PersonalData
     */
    education: string;
    /**
     *
     * @type {string}
     * @memberof PersonalData
     */
    date: string;
    /**
     *
     * @type {string}
     * @memberof PersonalData
     */
    gender: string;
}
/**
 * Check if a given object implements the PersonalData interface.
 */
export function instanceOfPersonalData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "medicaldiagnose" in value;
    isInstance = isInstance && "occupation" in value;
    isInstance = isInstance && "martialstatus" in value;
    isInstance = isInstance && "education" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "gender" in value;
    return isInstance;
}
export function PersonalDataFromJSON(json: any): PersonalData {
    return PersonalDataFromJSONTyped(json, false);
}
export function PersonalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'medicaldiagnose': PersonalDataMedicaldiagnoseFromJSON(json['medicaldiagnose']),
        'occupation': json['occupation'],
        'martialstatus': json['martialstatus'],
        'education': json['education'],
        'date': json['date'],
        'gender': json['gender'],
    };
}
export function PersonalDataToJSON(value?: PersonalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'medicaldiagnose': PersonalDataMedicaldiagnoseToJSON(value.medicaldiagnose),
        'occupation': value.occupation,
        'martialstatus': value.martialstatus,
        'education': value.education,
        'date': value.date,
        'gender': value.gender,
    };
}