import { getCurrentInstance } from 'vue'

export function useModal () {
  const $bvModal = getCurrentInstance()?.proxy.$bvModal

  if (!$bvModal) {
    throw new Error('No $bvModal in context')
  }
  const createElement = getCurrentInstance()?.proxy.$createElement
  if (!createElement) {
    throw new Error('No $createElement in context')
  }

  return { ...$bvModal, createElement }
}
