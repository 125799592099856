
import { ssnToAge } from '@/lib/date'
import { computed, defineComponent, PropType } from 'vue'
import { Patient } from '../api/index'

interface Item {
  value: string | number,
  key: string,
  label: string,
  size?: 'auto' | number
}

export default defineComponent({
  name: 'PatientHeader',
  props: {
    patient: { type: Object as PropType<Patient>, required: true }
  },
  setup (props) {
    return {
      items: computed<Item[]>(() => {
        return [
          { value: props.patient.name, label: 'Namn', key: 'name', size: 3 },
          { value: props.patient.email, label: 'Email', key: 'email', size: 3 },
          { value: props.patient.tel, label: 'Telefonummer', key: 'tel', size: 2 },
          { value: `${ssnToAge(props.patient.ssn)}  år`, label: 'Ålder', key: 'age', size: 'auto' }
        ]
      })
    }
  }
})
