/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  AuthService,
  CompleteIDPAuthentication,
  CompleteIDPAuthentication200Response,
  FederatedLogin,
  HttpError,
  LoginCsrfToken,
  LoginForm,
  LoginStatus,
  LoginStatusFailed,
  LoginStatusSuccess,
  StartIDPAuthentication,
  VerifyIdentityForm,
  VerifyIdentityResult,
} from '../models';
import {
    AuthServiceFromJSON,
    AuthServiceToJSON,
    CompleteIDPAuthenticationFromJSON,
    CompleteIDPAuthenticationToJSON,
    CompleteIDPAuthentication200ResponseFromJSON,
    CompleteIDPAuthentication200ResponseToJSON,
    FederatedLoginFromJSON,
    FederatedLoginToJSON,
    HttpErrorFromJSON,
    HttpErrorToJSON,
    LoginCsrfTokenFromJSON,
    LoginCsrfTokenToJSON,
    LoginFormFromJSON,
    LoginFormToJSON,
    LoginStatusFromJSON,
    LoginStatusToJSON,
    LoginStatusFailedFromJSON,
    LoginStatusFailedToJSON,
    LoginStatusSuccessFromJSON,
    LoginStatusSuccessToJSON,
    StartIDPAuthenticationFromJSON,
    StartIDPAuthenticationToJSON,
    VerifyIdentityFormFromJSON,
    VerifyIdentityFormToJSON,
    VerifyIdentityResultFromJSON,
    VerifyIdentityResultToJSON,
} from '../models';
export interface IdentityLoginApiCompleteIDPAuthenticationRequest {
    authservice: AuthService;
    completeIDPAuthentication: CompleteIDPAuthentication;
}
export interface IdentityLoginApiCompleteVerifyIdentityRequest {
    verifyIdentityResult: VerifyIdentityResult;
}
export interface IdentityLoginApiDoLoginRequest {
    loginForm: LoginForm;
}
export interface IdentityLoginApiStartIDPAuthenticationRequest {
    authservice: AuthService;
    startIDPAuthentication: StartIDPAuthentication;
}
export interface IdentityLoginApiStartVerifyIdentityRequest {
    verifyIdentityForm: VerifyIdentityForm;
}
/**
 *
 */
export class IdentityLoginApi extends runtime.BaseAPI {
    /**
     */
    async completeIDPAuthenticationRaw(requestParameters: IdentityLoginApiCompleteIDPAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompleteIDPAuthentication200Response>> {
        if (requestParameters.authservice === null || requestParameters.authservice === undefined) {
            throw new runtime.RequiredError('authservice','Required parameter requestParameters.authservice was null or undefined when calling completeIDPAuthentication.');
        }
        if (requestParameters.completeIDPAuthentication === null || requestParameters.completeIDPAuthentication === undefined) {
            throw new runtime.RequiredError('completeIDPAuthentication','Required parameter requestParameters.completeIDPAuthentication was null or undefined when calling completeIDPAuthentication.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/login/idp/{authservice}/complete`.replace(`{${"authservice"}}`, encodeURIComponent(String(requestParameters.authservice))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteIDPAuthenticationToJSON(requestParameters.completeIDPAuthentication),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CompleteIDPAuthentication200ResponseFromJSON(jsonValue));
    }
    /**
     */
    async completeIDPAuthentication(requestParameters: IdentityLoginApiCompleteIDPAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompleteIDPAuthentication200Response> {
        const response = await this.completeIDPAuthenticationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async completeVerifyIdentityRaw(requestParameters: IdentityLoginApiCompleteVerifyIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verifyIdentityResult === null || requestParameters.verifyIdentityResult === undefined) {
            throw new runtime.RequiredError('verifyIdentityResult','Required parameter requestParameters.verifyIdentityResult was null or undefined when calling completeVerifyIdentity.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/identity/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyIdentityResultToJSON(requestParameters.verifyIdentityResult),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async completeVerifyIdentity(requestParameters: IdentityLoginApiCompleteVerifyIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.completeVerifyIdentityRaw(requestParameters, initOverrides);
    }
    /**
     */
    async doLoginRaw(requestParameters: IdentityLoginApiDoLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginStatusSuccess>> {
        if (requestParameters.loginForm === null || requestParameters.loginForm === undefined) {
            throw new runtime.RequiredError('loginForm','Required parameter requestParameters.loginForm was null or undefined when calling doLogin.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginFormToJSON(requestParameters.loginForm),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LoginStatusSuccessFromJSON(jsonValue));
    }
    /**
     */
    async doLogin(requestParameters: IdentityLoginApiDoLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginStatusSuccess> {
        const response = await this.doLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async doLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginStatusFailed>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/login`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LoginStatusFailedFromJSON(jsonValue));
    }
    /**
     */
    async doLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginStatusFailed> {
        const response = await this.doLogoutRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginStatus>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LoginStatusFromJSON(jsonValue));
    }
    /**
     */
    async getLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginStatus> {
        const response = await this.getLoginRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async startIDPAuthenticationRaw(requestParameters: IdentityLoginApiStartIDPAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FederatedLogin>> {
        if (requestParameters.authservice === null || requestParameters.authservice === undefined) {
            throw new runtime.RequiredError('authservice','Required parameter requestParameters.authservice was null or undefined when calling startIDPAuthentication.');
        }
        if (requestParameters.startIDPAuthentication === null || requestParameters.startIDPAuthentication === undefined) {
            throw new runtime.RequiredError('startIDPAuthentication','Required parameter requestParameters.startIDPAuthentication was null or undefined when calling startIDPAuthentication.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/login/idp/{authservice}`.replace(`{${"authservice"}}`, encodeURIComponent(String(requestParameters.authservice))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartIDPAuthenticationToJSON(requestParameters.startIDPAuthentication),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FederatedLoginFromJSON(jsonValue));
    }
    /**
     */
    async startIDPAuthentication(requestParameters: IdentityLoginApiStartIDPAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FederatedLogin> {
        const response = await this.startIDPAuthenticationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async startVerifyIdentityRaw(requestParameters: IdentityLoginApiStartVerifyIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FederatedLogin>> {
        if (requestParameters.verifyIdentityForm === null || requestParameters.verifyIdentityForm === undefined) {
            throw new runtime.RequiredError('verifyIdentityForm','Required parameter requestParameters.verifyIdentityForm was null or undefined when calling startVerifyIdentity.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/identity/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyIdentityFormToJSON(requestParameters.verifyIdentityForm),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FederatedLoginFromJSON(jsonValue));
    }
    /**
     */
    async startVerifyIdentity(requestParameters: IdentityLoginApiStartVerifyIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FederatedLogin> {
        const response = await this.startVerifyIdentityRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async tokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginCsrfToken>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/login/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LoginCsrfTokenFromJSON(jsonValue));
    }
    /**
     */
    async token(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginCsrfToken> {
        const response = await this.tokenRaw(initOverrides);
        return await response.value();
    }
}