/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnswerValue
 */
export interface AnswerValue {
}
/**
 * Check if a given object implements the AnswerValue interface.
 */
export function instanceOfAnswerValue(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function AnswerValueFromJSON(json: any): AnswerValue {
    return AnswerValueFromJSONTyped(json, false);
}
export function AnswerValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerValue {
    return json;
}
export function AnswerValueToJSON(value?: AnswerValue | null): any {
    return value;
}