import { render, staticRenderFns } from "./DiagnoseInput.vue?vue&type=template&id=84a74372&scoped=true&"
import script from "./DiagnoseInput.vue?vue&type=script&lang=ts&"
export * from "./DiagnoseInput.vue?vue&type=script&lang=ts&"
import style0 from "./DiagnoseInput.vue?vue&type=style&index=0&id=84a74372&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84a74372",
  null
  
)

export default component.exports