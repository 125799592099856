import Vue, { computed, onMounted, reactive, ref, unref, watch } from 'vue'
import { PersonalData, PersonalDataMedicaldiagnoseItemsInner as PersonalDataMedicaldiagnoseItem } from '../api/index'
import { anyVal } from '@/lib/select'

import { faker } from '@faker-js/faker/locale/sv'
import { any } from '@/lib/array'
import { debounce } from '@github/mini-throttle'
import { useBackgroundState } from './save-restore'
import { BackendError, errorFromResponse, isHttpXXX, useLoadable } from './loadable'
import { useDiagnoseCodes } from './diagnoses'
import { Api } from '@/lib/di/api'
import { v4 as uuid } from 'uuid'
import { MaybeRef, WithId } from '@/lib/types'

export function useOptions () {
  const diagnoses = useDiagnoseCodes()

  const labels = {
    date: 'Datum',
    gender: 'Vilket kön identifierar du dig med?',
    education: 'Högsta avslutade utbildning',
    martialstatus: 'Nuvarande civilstånd',
    occupation: 'Nuvarande arbetssituation',
    medicaldiagnose: 'Tidigare kända diagnoser'
  }

  const descriptions = {
    medicaldiagnose: 'Lägg till de diagnoser du känner till och ange om möjligt ICD-kod genom att skriva diagnosens namn i det högra fältet.'
  }

  const options = reactive({
    gender: [
      { value: 'female', text: 'Kvinna' },
      { value: 'male', text: 'Man' },
      { value: 'non-binary', text: 'Icke-binär / Vill inte uppge' }
    ],
    education: [
      { value: 'elementary', text: 'Grundskola' },
      { value: 'upper-secondary', text: 'Gymnasieskola' },
      { value: 'post-secondary', text: 'Eftergymnasial utbildning' }
    ],
    martialstatus: [
      { value: 'none', text: 'Aldrig gift (ej. sambo)' },
      { value: 'divorced', text: 'Frånskild' },
      { value: 'married', text: 'Gift' },
      { value: 'widow_widower', text: 'Änka/änkling' },
      { value: 'separated', text: 'Separerad' },
      { value: 'sohabiting', text: 'Sambo' }
    ],
    occupation: [
      { value: 'paid_work', text: 'Betalt arbete' },
      { value: 'self_employed', text: 'Egen företagare' },
      { value: 'unpaid_work', text: 'Obetalt arbete, såsom volontär el. i välgörenhet' },
      { value: 'student', text: 'Studerande' },
      { value: 'housework', text: 'Hushållsarbete (sköta eget hem)' },
      { value: 'retired', text: 'Pensionerad' },
      { value: 'lack_due_health', text: 'Saknar arbete av hälsoskäl' },
      { value: 'lack_due_other', text: 'Saknar arbete av andra skäl' },
      { value: 'other', text: 'Annat' }
    ],
    medicaldiagnose: {
      type: [
        { value: 'none', text: 'Inga tidigare diagnoser som jag känner till' },
        { value: 'other', text: 'En hälsobetingelse (sjukdom, störning, skada) föreligger, men dess natur eller diagnos är okänd' },
        { value: 'diagnose', text: 'Kända diagnoser' }
      ],
      codes: computed(() => {
        return diagnoses.data.value?.map(({ label: text, code: value }) => ({ text, value })) ?? [{ value: '', text: 'Laddar alternativ' }]
      })
    }
  })

  return { options, labels, descriptions }
}

export type PersonalDataInternal = Omit<PersonalData, 'medicaldiagnose'> & {
  medicaldiagnose: {
    type: string,
    items: Array<WithId<PersonalDataMedicaldiagnoseItem>>
  },
}

export function usePersonalData (investigationId: MaybeRef<string>) {
  return useLoadable(Api.investigation(), async api => await api.getPatientBackground({ investigationId: unref(investigationId) }))
}

export function usePersonalForm (investigationId: MaybeRef<string>) {
  const { options, labels, descriptions } = useOptions()

  const data = reactive<PersonalDataInternal>({
    gender: '',
    date: new Date().toISOString().substr(0, 10),
    education: '',
    martialstatus: '',
    occupation: '',
    medicaldiagnose: {
      type: '',
      items: []
    }
  })

  function fakeFill () {
    Object.assign(data, {
      gender: anyVal(options.gender),
      date: new Date().toISOString().substr(0, 10),
      education: anyVal(options.education),
      martialstatus: anyVal(options.martialstatus),
      occupation: anyVal(options.occupation),
      medicaldiagnose: any([{
        type: 'diagnose',
        items: faker.helpers.arrayElements().map(() => ({
          id: uuid(),
          text: faker.lorem.sentence(4),
          code: anyVal(unref(options.medicaldiagnose.codes)) ?? 'F00'
        }))
      }, ...options.medicaldiagnose.type.filter(t => t.value !== 'diagnose')
        .map(t => ({ type: t.value, items: [] }))
      ])
    } as PersonalDataInternal)
  }

  function onAddDiagnoseRow () {
    data.medicaldiagnose.items.push({ id: uuid(), text: '', code: '' })
  }
  function onDeleteDiagnoseRow (item: { id: string }) {
    data.medicaldiagnose.items = data.medicaldiagnose.items.filter(({ id }) => id !== item.id)
  }

  function onDiagnoseUpdate (update: { id: string, code: string, text: string }) {
    data.medicaldiagnose.items = data.medicaldiagnose.items.map(itm => {
      return itm.id === update.id ? { ...update } : itm
    })
  }

  const state = reactive({
    changed: false,
    saving: false,
    loading: false,
    inited: false
  })

  const { save, load } = useBackgroundState(investigationId)
  const error = ref<BackendError | undefined>(undefined)

  onMounted(async () => {
    state.loading = true
    try {
      const result = await load()
      if (result) {
        const { medicaldiagnose: { type, items }, ...rest } = result
        Object.assign(data, {
          ...rest,
          medicaldiagnose: {
            type,
            items: items.map(i => ({ ...i, id: uuid() }))
          }
        })
      }
      Vue.nextTick(() => {
        state.inited = true
      })
    } catch (resp) {
      if (isHttpXXX(resp, 404)) {
        console.log(`No previous saved state found for: ${unref(investigationId)}`)
        state.inited = true
      } else {
        error.value = await errorFromResponse(resp)
      }
    } finally {
      state.loading = false
    }
  })

  const saveEager = async () => {
    state.saving = true
    try {
      const { medicaldiagnose: { type, items }, ...rest } = data
      const hasDiagnoses = data.medicaldiagnose.type === 'diagnose'
      await save({
        ...rest,
        medicaldiagnose: {
          type,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          items: hasDiagnoses ? items.map(({ id, ...i }) => ({ ...i })) : []
        }
      })
    } catch (err) {
      console.error(err)
    } finally {
      state.saving = false
      state.changed = false
    }
  }
  const saveLazy = debounce(saveEager, 1000)

  watch(data, () => {
    if (!state.inited) return
    state.changed = true
    saveLazy()
  })

  return { data, error, options, labels, descriptions, state, onAddDiagnoseRow, onDeleteDiagnoseRow, onDiagnoseUpdate, fakeFill, saveEager }

}
