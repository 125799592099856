
import { computed, defineComponent, PropType, ref } from 'vue'
import { Selectable } from '@/lib/select'

export default defineComponent({
  name: 'CommonCodeSelect',
  components: { },
  props: {
    name: { required: false, type: String, default: 'input-diagnose-code' },
    value: { required: true, type: String },
    options: { required: true, type: Array as PropType<Selectable[]> },
    showValueText: { required: false, type: Boolean, default: false },
    placeholder: { required: false, type: String }
  },
  setup (props, { emit }) {
    const input = ref<HTMLElement>()
    const query = ref(props.value)
    const focused = ref(false)
    const focus = ref<null | number>(null)

    const suggestions = computed<Selectable[]>(() => {
      const q = query.value?.toLowerCase()?.trim()
      if (!q) return []

      return props.options.filter(({ value: _code, text: _text }) => {
        const text = _text.toLowerCase()
        const code = (`${_code ?? ''}`).toLowerCase()

        return text.includes(q) || code.startsWith(q)
      }).slice(0, 5)
    })

    function selected (sel: Selectable) {
      updateQuery(`${sel.value ?? ''}`)
    }

    function updateQuery (q: string) {
      query.value = q
      emit('update', q)
    }

    function render (sel: Selectable) {
      return `${sel.value ?? ''} - ${sel.text}`
    }

    function keyUp () {
      if (focus.value === null) {
        focus.value = suggestions.value.length - 1
      } else if (focus.value > 0) {
        focus.value = focus.value - 1
      } else {
        focus.value = null
      }
    }

    function keyDown () {
      if (focus.value === null) {
        focus.value = 0
      } else if (focus.value < suggestions.value.length - 1) {
        focus.value = focus.value + 1
      } else {
        focus.value = null
      }
    }

    function keyEnter (_idx?: number) {
      const idx = _idx ?? focus.value
      const sugg = suggestions.value

      if (idx === null) return

      const { value, text } = sugg[idx]

      query.value = `${value ?? ''}`
      focus.value = null
      focused.value = false

      // When enter is pressed (_idx == undf) unfocus the input
      if (_idx === undefined) {
        input.value?.blur()
      }

      selected({ value, text })
    }

    function blur () {
      focused.value = false
      focus.value = null
    }

    const highlight = (needle: string, haystack: string) =>
      haystack.replace(new RegExp(needle, 'gi'), (str: string) => `<b>${str}</b>`)

    const state = computed(() => {
      const q = query.value
      return (focused.value || q === '' || props.options.length <= 1)
        ? null
        : props.options.find(opt => opt.value === q) === undefined
          ? false
          : null
    })

    const hint = computed(() => {
      if (props.showValueText) {
        const option = props.options.find(c => c.value === props.value.trim())
        if (option) {
          const text = option.text
          return text
        }
      }
      return false
    })

    return { input, selected, suggestions, query, updateQuery, focused, focus, keyDown, keyUp, blur, highlight, keyEnter, render, state, hint }
  }
})
