/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { ErrorValue } from './ErrorValue';
import {
    ErrorValueFromJSON,
    ErrorValueFromJSONTyped,
    ErrorValueToJSON,
} from './ErrorValue';
import type { ErrorValueGrandid } from './ErrorValueGrandid';
import {
    ErrorValueGrandidFromJSON,
    ErrorValueGrandidFromJSONTyped,
    ErrorValueGrandidToJSON,
} from './ErrorValueGrandid';
import type { ErrorValuePrisma } from './ErrorValuePrisma';
import {
    ErrorValuePrismaFromJSON,
    ErrorValuePrismaFromJSONTyped,
    ErrorValuePrismaToJSON,
} from './ErrorValuePrisma';
import type { JoiValidationErrorItem } from './JoiValidationErrorItem';
import {
    JoiValidationErrorItemFromJSON,
    JoiValidationErrorItemFromJSONTyped,
    JoiValidationErrorItemToJSON,
} from './JoiValidationErrorItem';
import type { TsoaFieldError } from './TsoaFieldError';
import {
    TsoaFieldErrorFromJSON,
    TsoaFieldErrorFromJSONTyped,
    TsoaFieldErrorToJSON,
} from './TsoaFieldError';
/**
 *
 * @export
 * @interface HttpErrorExtra
 */
export interface HttpErrorExtra {
    /**
     *
     * @type {any}
     * @memberof HttpErrorExtra
     */
    upstream?: any | null;
    /**
     *
     * @type {ErrorValueGrandid}
     * @memberof HttpErrorExtra
     */
    grandid?: ErrorValueGrandid;
    /**
     *
     * @type {ErrorValuePrisma}
     * @memberof HttpErrorExtra
     */
    prisma?: ErrorValuePrisma;
    /**
     *
     * @type {Array<JoiValidationErrorItem>}
     * @memberof HttpErrorExtra
     */
    joi?: Array<JoiValidationErrorItem>;
    /**
     *
     * @type {TsoaFieldError}
     * @memberof HttpErrorExtra
     */
    tsoa?: TsoaFieldError;
}
/**
 * Check if a given object implements the HttpErrorExtra interface.
 */
export function instanceOfHttpErrorExtra(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function HttpErrorExtraFromJSON(json: any): HttpErrorExtra {
    return HttpErrorExtraFromJSONTyped(json, false);
}
export function HttpErrorExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): HttpErrorExtra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'upstream': !exists(json, 'upstream') ? undefined : json['upstream'],
        'grandid': !exists(json, 'grandid') ? undefined : ErrorValueGrandidFromJSON(json['grandid']),
        'prisma': !exists(json, 'prisma') ? undefined : ErrorValuePrismaFromJSON(json['prisma']),
        'joi': !exists(json, 'joi') ? undefined : ((json['joi'] as Array<any>).map(JoiValidationErrorItemFromJSON)),
        'tsoa': !exists(json, 'tsoa') ? undefined : TsoaFieldErrorFromJSON(json['tsoa']),
    };
}
export function HttpErrorExtraToJSON(value?: HttpErrorExtra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'upstream': value.upstream,
        'grandid': ErrorValueGrandidToJSON(value.grandid),
        'prisma': ErrorValuePrismaToJSON(value.prisma),
        'joi': value.joi === undefined ? undefined : ((value.joi as Array<any>).map(JoiValidationErrorItemToJSON)),
        'tsoa': TsoaFieldErrorToJSON(value.tsoa),
    };
}