/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Consent
 */
export interface Consent {
    /**
     *
     * @type {string}
     * @memberof Consent
     */
    termsVersion: string;
    /**
     *
     * @type {string}
     * @memberof Consent
     */
    termsDigest: string;
    /**
     *
     * @type {Date}
     * @memberof Consent
     */
    completedAt: Date | null;
    /**
     *
     * @type {Date}
     * @memberof Consent
     */
    createdAt: Date;
    /**
     *
     * @type {string}
     * @memberof Consent
     */
    id: string;
}
/**
 * Check if a given object implements the Consent interface.
 */
export function instanceOfConsent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "termsVersion" in value;
    isInstance = isInstance && "termsDigest" in value;
    isInstance = isInstance && "completedAt" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function ConsentFromJSON(json: any): Consent {
    return ConsentFromJSONTyped(json, false);
}
export function ConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'termsVersion': json['termsVersion'],
        'termsDigest': json['termsDigest'],
        'completedAt': (json['completedAt'] === null ? null : new Date(json['completedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
    };
}
export function ConsentToJSON(value?: Consent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'termsVersion': value.termsVersion,
        'termsDigest': value.termsDigest,
        'completedAt': (value.completedAt === null ? null : value.completedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
    };
}