
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClusterIngressProgressBar',
  props: {
    max: { type: Number, required: true },
    progress: { type: Number, required: true }
  }
})
