/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ClusterIngress
 */
export interface ClusterIngress {
    /**
     *
     * @type {string}
     * @memberof ClusterIngress
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof ClusterIngress
     */
    title: string;
}
/**
 * Check if a given object implements the ClusterIngress interface.
 */
export function instanceOfClusterIngress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function ClusterIngressFromJSON(json: any): ClusterIngress {
    return ClusterIngressFromJSONTyped(json, false);
}
export function ClusterIngressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterIngress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'description': json['description'],
        'title': json['title'],
    };
}
export function ClusterIngressToJSON(value?: ClusterIngress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'description': value.description,
        'title': value.title,
    };
}