/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Investigation } from './Investigation';
import {
    InvestigationFromJSON,
    InvestigationFromJSONTyped,
    InvestigationToJSON,
} from './Investigation';
/**
 *
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     *
     * @type {Array<Investigation>}
     * @memberof Patient
     */
    investigations: Array<Investigation>;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    registered: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    organisationId: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    tel: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    ssn: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    name: string;
}
/**
 * Check if a given object implements the Patient interface.
 */
export function instanceOfPatient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "investigations" in value;
    isInstance = isInstance && "registered" in value;
    isInstance = isInstance && "organisationId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tel" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "ssn" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function PatientFromJSON(json: any): Patient {
    return PatientFromJSONTyped(json, false);
}
export function PatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Patient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'investigations': ((json['investigations'] as Array<any>).map(InvestigationFromJSON)),
        'registered': json['registered'],
        'organisationId': json['organisationId'],
        'id': json['id'],
        'tel': json['tel'],
        'email': json['email'],
        'ssn': json['ssn'],
        'name': json['name'],
    };
}
export function PatientToJSON(value?: Patient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'investigations': ((value.investigations as Array<any>).map(InvestigationToJSON)),
        'registered': value.registered,
        'organisationId': value.organisationId,
        'id': value.id,
        'tel': value.tel,
        'email': value.email,
        'ssn': value.ssn,
        'name': value.name,
    };
}