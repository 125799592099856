import { ArrayElement } from './entries'
export function any<T> (arr: T[]): ArrayElement<T> {
  const copy = [...arr]
  copy.sort(() => Math.random() * 2 - 1)
  return copy.find(() => true) as ArrayElement<T>
}

export function flatMap<T, U> (array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn))
}
