
import { Selectable } from '@/lib/select'
import { defineComponent, PropType } from 'vue'

import TreatmentInput from '@/components/actionplan/TreatmentInput.vue'

interface Treatment { id: string, code: string, profession: string, comment: string }

export default defineComponent({
  name: 'MultipleTreatmentInput',
  components: { TreatmentInput },
  props: {
    treatments: { required: true, type: Array as PropType<Treatment[]> },
    options: { required: true, type: Object as PropType<{codes: Selectable[], professions: Selectable[]}> },
    state: { required: true, type: Object as PropType<Record<string, { profession: false | null, comment: false | null }>> }
  }
})

