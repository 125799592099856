/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { UsersUsersInner } from './UsersUsersInner';
import {
    UsersUsersInnerFromJSON,
    UsersUsersInnerFromJSONTyped,
    UsersUsersInnerToJSON,
} from './UsersUsersInner';
/**
 *
 * @export
 * @interface Users
 */
export interface Users {
    /**
     *
     * @type {Array<UsersUsersInner>}
     * @memberof Users
     */
    users: Array<UsersUsersInner>;
}
/**
 * Check if a given object implements the Users interface.
 */
export function instanceOfUsers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function UsersFromJSON(json: any): Users {
    return UsersFromJSONTyped(json, false);
}
export function UsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): Users {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'users': ((json['users'] as Array<any>).map(UsersUsersInnerFromJSON)),
    };
}
export function UsersToJSON(value?: Users | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'users': ((value.users as Array<any>).map(UsersUsersInnerToJSON)),
    };
}