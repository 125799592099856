/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Stats
 */
export interface Stats {
    /**
     *
     * @type {number}
     * @memberof Stats
     */
    investigations: number;
    /**
     *
     * @type {number}
     * @memberof Stats
     */
    patients: number;
    /**
     *
     * @type {number}
     * @memberof Stats
     */
    users: number;
}
/**
 * Check if a given object implements the Stats interface.
 */
export function instanceOfStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "investigations" in value;
    isInstance = isInstance && "patients" in value;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function StatsFromJSON(json: any): Stats {
    return StatsFromJSONTyped(json, false);
}
export function StatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'investigations': json['investigations'],
        'patients': json['patients'],
        'users': json['users'],
    };
}
export function StatsToJSON(value?: Stats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'investigations': value.investigations,
        'patients': value.patients,
        'users': value.users,
    };
}