import { WithoutId } from './types'

export function withoutId<T extends {id: string}> (array: T[]): Array<WithoutId<T>> {
  return array.map(d => {
    const copy = { ...d }
    if ('id' in copy) delete (copy as Partial<T>).id
    return copy as WithoutId<T>
  })
}

export async function failWith<T> (nul: T | undefined, fn: () => Promise<T>): Promise<T | typeof nul> {
  try {
    return await fn()
  } catch {
    return typeof nul === 'function' ? nul() : nul
  }
}

export function empty<T extends Record<string, unknown>> (t: T): boolean {
  return typeof t === 'object' ? Object.keys(t).length === 0 : true
}

// eslint-disable-next-line no-control-regex
export const isRFC5322Email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const isSSN = /^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/

export const isTel = /[-+ 0-9]+/

export function notEqual (text: string | null | undefined) {
  return new RegExp(text ? `^(?!.*${text}).*$` : '.*')
}

export function keys<T extends Record<string, unknown>> (obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>
}
