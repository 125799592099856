import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import AuditLog from '@/views/admin/AuditLog.vue'
import Patient from '@/views/admin/Patient.vue'
import PatientList from '@/views/admin/PatientList.vue'
import PatientCreate from '@/views/admin/PatientCreate.vue'
import PatientEdit from '@/views/admin/PatientEdit.vue'
import PatientCreateInvestigation from '@/views/admin/PatientCreateInvestigation.vue'
import PatientEditInvestigation from '@/views/admin/PatientEditInvestigation.vue'

import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'

import Welcome from '@/views/patient/Welcome.vue'
import Verify from '@/views/patient/Verify.vue'
import FormView from '@/views/patient/FormView.vue'
import PersonalQuestions from '@/views/patient/PersonalQuestions.vue'
import Thanks from '@/views/patient/Thanks.vue'

import Overview from '@/views/profession/Overview.vue'
import Reword from '@/views/profession/Reword.vue'

import OpenWelcome from '@/views/open/Welcome.vue'
import OpenForm from '@/views/open/FormView.vue'
import OpenSign from '@/views/open/Sign.vue'

import OrganisationList from '@/views/superadmin/OrganisationList.vue'
import Organisation from '@/views/superadmin/Organisation.vue'

Vue.use(VueRouter)

const patient = [
  {
    path: '/welcome/:id',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/verify/:id',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/form/:id',
    name: 'Form',
    component: FormView
  },
  {
    path: '/personal-questions/:id',
    name: 'PersonalQuestions',
    component: PersonalQuestions
  },
  {
    path: '/thanks/:id',
    name: 'Thanks',
    component: Thanks
  }
]

const profession = [
  {
    path: '/overview/:id',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/overview/:id/profession-answer/:cluster/:dimension',
    name: 'Reword',
    component: Reword
  }
]

const admin = [
  {
    path: '/patients',
    name: 'PatientList',
    component: PatientList
  },
  {
    path: '/patient/create',
    name: 'PatientCreate',
    component: PatientCreate
  },
  {
    path: '/patient/:id/edit',
    name: 'PatientEdit',
    component: PatientEdit
  },
  {
    path: '/patient/:id/create',
    name: 'PatientCreateInvestigation',
    component: PatientCreateInvestigation
  },
  {
    path: '/patient/:patient_id/:investigation_id/edit',
    name: 'PatientEditInvestigation',
    component: PatientEditInvestigation
  },
  {
    path: '/patient/:id',
    name: 'Patient',
    component: Patient
  },
  {
    path: '/auditlog',
    name: 'AuditLog',
    component: AuditLog
  }
]

const superadmin = [
  {
    path: '/organisations',
    name: 'Organisations',
    component: OrganisationList
  },
  {
    path: '/organisation/:id',
    name: 'Organisation',
    component: Organisation
  }
]

const open = [
  {
    path: '/open/:id/welcome',
    name: 'OpenWelcome',
    component: OpenWelcome
  },
  {
    path: '/open/:id/form',
    name: 'OpenForm',
    component: OpenForm
  },
  {
    path: '/open/:id/sign',
    name: 'OpenSign',
    component: OpenSign
  }
]

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/patients'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  ...profession,
  ...patient,
  ...admin,
  ...superadmin,
  ...open
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
