/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Organisation } from './Organisation';
import {
    OrganisationFromJSON,
    OrganisationFromJSONTyped,
    OrganisationToJSON,
} from './Organisation';
import type { Users } from './Users';
import {
    UsersFromJSON,
    UsersFromJSONTyped,
    UsersToJSON,
} from './Users';
import type { UsersUsersInner } from './UsersUsersInner';
import {
    UsersUsersInnerFromJSON,
    UsersUsersInnerFromJSONTyped,
    UsersUsersInnerToJSON,
} from './UsersUsersInner';
/**
 *
 * @export
 * @interface OrganisationWithUsers
 */
export interface OrganisationWithUsers {
    /**
     *
     * @type {boolean}
     * @memberof OrganisationWithUsers
     */
    demo: boolean;
    /**
     *
     * @type {string}
     * @memberof OrganisationWithUsers
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof OrganisationWithUsers
     */
    id: string;
    /**
     *
     * @type {Array<UsersUsersInner>}
     * @memberof OrganisationWithUsers
     */
    users: Array<UsersUsersInner>;
}
/**
 * Check if a given object implements the OrganisationWithUsers interface.
 */
export function instanceOfOrganisationWithUsers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "demo" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "users" in value;
    return isInstance;
}
export function OrganisationWithUsersFromJSON(json: any): OrganisationWithUsers {
    return OrganisationWithUsersFromJSONTyped(json, false);
}
export function OrganisationWithUsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationWithUsers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'demo': json['demo'],
        'name': json['name'],
        'id': json['id'],
        'users': ((json['users'] as Array<any>).map(UsersUsersInnerFromJSON)),
    };
}
export function OrganisationWithUsersToJSON(value?: OrganisationWithUsers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'demo': value.demo,
        'name': value.name,
        'id': value.id,
        'users': ((value.users as Array<any>).map(UsersUsersInnerToJSON)),
    };
}