/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * A LoginStatus when not logged in
 * @export
 * @interface LoginStatusFailed
 */
export interface LoginStatusFailed {
    /**
     *
     * @type {boolean}
     * @memberof LoginStatusFailed
     */
    loggedIn: LoginStatusFailedLoggedInEnum;
}
/**
 * @export
 */
export const LoginStatusFailedLoggedInEnum = {
    False: false
} as const;
export type LoginStatusFailedLoggedInEnum = typeof LoginStatusFailedLoggedInEnum[keyof typeof LoginStatusFailedLoggedInEnum];
/**
 * Check if a given object implements the LoginStatusFailed interface.
 */
export function instanceOfLoginStatusFailed(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "loggedIn" in value;
    return isInstance;
}
export function LoginStatusFailedFromJSON(json: any): LoginStatusFailed {
    return LoginStatusFailedFromJSONTyped(json, false);
}
export function LoginStatusFailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginStatusFailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'loggedIn': json['loggedIn'],
    };
}
export function LoginStatusFailedToJSON(value?: LoginStatusFailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'loggedIn': value.loggedIn,
    };
}