/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * Login form with credentials to complete login
 * @export
 * @interface LoginForm
 */
export interface LoginForm {
    /**
     *
     * @type {string}
     * @memberof LoginForm
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof LoginForm
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof LoginForm
     */
    token: string;
}
/**
 * Check if a given object implements the LoginForm interface.
 */
export function instanceOfLoginForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
export function LoginFormFromJSON(json: any): LoginForm {
    return LoginFormFromJSONTyped(json, false);
}
export function LoginFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'username': json['username'],
        'password': json['password'],
        'token': json['token'],
    };
}
export function LoginFormToJSON(value?: LoginForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'username': value.username,
        'password': value.password,
        'token': value.token,
    };
}