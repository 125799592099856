import { MaybeRef } from '@/lib/types'
import Vue, { computed, reactive, Ref, watch, unref } from 'vue'
import { AnswerData, ClusterData, DefaultApi, DimensionData, FormDefinition, InvestigationApi } from '../api/index'
import { Loadable, useLoadable } from './loadable'
import { isComplete } from '@/lib/form-answer'
import { Api } from '@/lib/di/api'

export interface FormState {
  progress: {
    cluster: { current: number, max: number },
    dimension: { current: number, max: number }
  },
  done: boolean,
  ingress: boolean,
  edit: boolean
}

export function useInvestigationForm (investigationId: MaybeRef<string>): Loadable<FormDefinition> {
  return useLoadable([Api.investigation(), Api.get()], async ([investigations, api]: [InvestigationApi, DefaultApi]) => {
    const { form: form_id } = await investigations.get({ investigationId: unref(investigationId) })
    return await api.getForm({ key: form_id ?? '' })
  })
}

export function usePlainForm (): Loadable<FormDefinition> {
  return useLoadable(Api.get(), async (api: DefaultApi) => {
    // @TODO add orgId here to get correct form
    const forms = await api.list()
    const id = forms.find(() => true)?.id
    return await api.getForm({ key: id ?? '' })
  })
}

export function useFormProgressFromLocalState (
  form: Ref<FormDefinition | undefined>,
  forceReview?: Ref<boolean>) {

  const key = () => `open/form/${form.value?.id ?? 'current'}`
  return useFormProgress(form, async () => {
    return key() in sessionStorage
      ? JSON.parse(sessionStorage.getItem(key()) ?? '{}')
      : null
  }, async (data) => {
    sessionStorage.setItem(key(), JSON.stringify(data))
    return data
  }, forceReview)
}

export function useFormProgress (
  form: Ref<FormDefinition | undefined>,
  savedStateProvider: () => Promise<ClusterData | undefined> = async () => undefined,
  saveState: (data: ClusterData) => Promise<ClusterData>,
  forceReview?: Ref<boolean>) {

  const { state, initState, initStateFromSaved } = useFormState()
  const { data, initData, initDataFromSaved } = useFormData()

  watch(form, (newForm, prevForm) => {
    // If already initialized, ignore!
    if (!(newForm && !prevForm) && !newForm) return

    onFormLoaded(newForm)
      .catch(err => console.error('Failed call onFormLoaded()', err))
  })

  async function onFormLoaded (f: FormDefinition) {
    const savedState = await savedStateProvider()

    initState(f)
    if (savedState) {
      initStateFromSaved(f, savedState)
    }

    initData(f)
    if (savedState) {
      initDataFromSaved(f, savedState)
    }

    if (forceReview?.value) {
      state.done = true
    }
  }

  const cluster = computed(() => {
    return form.value?.clusters[state.progress.cluster.current]
  })

  const clusterDimension = computed(() => {
    return cluster.value?.dimensions[state.progress.dimension.current]
  })

  const keys = computed(() => {
    return {
      cluster: cluster.value?.key ?? '',
      dimension: clusterDimension.value?.code ?? ''
    }
  })

  const clusterAnswers = computed(() => {
    return cluster.value?.answers ?? []
  })

  function advance () {
    const result = saveState(data)
    if (result instanceof Promise) {
      result.catch(err => console.error('Failed to saveState', err))
    }

    state.progress.dimension.current++

    if (state.progress.dimension.current === state.progress.dimension.max) { // advance to next cluster

      state.progress.cluster.current++

      // Have we reached end of clusters?
      if (state.progress.cluster.current === state.progress.cluster.max) {
        state.done = true
      } else {
        state.ingress = true

        state.progress.dimension.current = 0
        state.progress.dimension.max = cluster.value?.dimensions.length ?? 0
      }
    }
  }

  const canAdvance = computed(() => {
    const index = state.progress.dimension.current
    const limit = state.progress.dimension.max

    if (index === -1 || index >= limit) { // if at the end
      return false
    } else { // else check if we are done at current
      return canAdvanceTo(index)
    }
  })

  function canAdvanceTo (dimenIdx: number): boolean {
    const dimen = cluster.value?.dimensions[dimenIdx]

    if (dimen) {
      const ans: AnswerData = data[keys.value.cluster][dimen.code]
      return isComplete(clusterAnswers.value, ans)
    }
    return false
  }

  const canGoBack = computed(() => {
    return state.progress.cluster.current > 0 || state.progress.dimension.current > 0
  })

  function goBack () {
    state.ingress = false

    if (state.progress.dimension.current > 0) {
      state.progress.dimension.current--
    } else if (state.progress.cluster.current > 0) {
      state.progress.cluster.current--
      state.progress.dimension.max = cluster.value?.dimensions.length ?? 0
    } else {
      debugger
    }
  }

  function goTo (clusterKey: string, dimensionKey: string) {
    initState(form.value!)

    state.ingress = false
    const clusters = form.value?.clusters ?? []
    for (const c of clusters) {
      for (const dimension of c.dimensions) {
        if (dimension.code === dimensionKey && c.key === clusterKey) {
          state.done = false
          state.edit = true
          return
        }
        state.progress.dimension.current++
      }
      state.progress.dimension.current = 0
      state.progress.cluster.current++
      state.progress.dimension.max = cluster.value?.dimensions.length ?? 0
    }
  }

  function goToDimension (index: number) {
    if (canAdvanceTo(index)) {
      state.progress.dimension.current = index
    }
  }

  function isCurrentDimension (index: number) {
    return state.progress.dimension.current === index
  }

  function goDone (save = false) {
    if (save) {
      const result = saveState(data)
      if (result instanceof Promise) {
        result
          .catch(err => console.error('Failed to saveState', err))
      }
    }
    state.done = true
  }

  function isDone () {
    return state.done
  }
  function isInEdit () {
    return state.edit
  }

  return {
    state,
    data,
    keys,
    cluster, clusterAnswers, clusterDimension,
    advance, canAdvance, canAdvanceTo,
    canGoBack, goBack, goTo, goDone, isDone, isInEdit,
    isCurrentDimension, goToDimension
  }
}

export function useFormData () {
  const data = reactive<ClusterData>({})

  function initData ({ clusters }: FormDefinition) {
    for (const cluster of clusters) {
      Vue.set(data, cluster.key, reactive<DimensionData>({}))

      for (const { code } of cluster.dimensions) {
        Vue.set(data[cluster.key], code, reactive<AnswerData>({}))

        for (const a of cluster.answers) {
          Vue.set(data[cluster.key][code], a.key, '')

          if (a.child_key) {
            Vue.set(data[cluster.key][code], a.child_key, '')
          }
        }
      }
    }
  }

  function initDataFromSaved ({ clusters }: FormDefinition, saved: ClusterData) {
    for (const cluster of clusters) {
      if (!(cluster.key in saved)) break

      for (const { code } of cluster.dimensions) {
        if (!(code in saved[cluster.key])) break

        const answer = saved[cluster.key][code]
        for (const [k, v] of Object.entries(answer).filter(([, v]) => v !== '')) {
          Vue.set(data[cluster.key][code], k, v)
        }
      }
    }
  }

  return { data, initData, initDataFromSaved }
}

function useFormState () {

  const state = reactive<FormState>({
    progress: {
      cluster: { current: 0, max: 0 },
      dimension: { current: 0, max: 0 }
    },
    done: false,
    ingress: true,
    edit: false
  })

  function initState ({ clusters }: FormDefinition) {
    state.progress.cluster.current = 0
    state.progress.cluster.max = clusters.length
    state.progress.dimension.current = 0
    state.progress.dimension.max = clusters[0].dimensions.length
  }

  function initStateFromSaved (form: FormDefinition, saved: ClusterData) {
    initState(form)

    for (const cluster of form.clusters.filter(c => c.key in saved)) {
      let completed = 0
      for (const dimension of cluster.dimensions) {
        if (isComplete(cluster.answers, saved[cluster.key][dimension.code])) {
          completed++
        }
      }

      state.progress.dimension.current = completed
      if (completed === cluster.dimensions.length) {
        state.progress.cluster.current++
        state.progress.dimension.current = 0
        state.progress.dimension.max = form.clusters[state.progress.cluster.current]?.dimensions.length
      } else {
        break // Break loop and let us stay in this progress state
      }
    }

    state.ingress = state.progress.dimension.current === 0
    state.done = state.progress.cluster.current === state.progress.cluster.max
  }

  return { state, initState, initStateFromSaved }
}
