import { ResponseError } from './../api/runtime'
import { onMounted, reactive, Ref, ref, toRefs } from 'vue'
import { ErrorValue } from '../api/index'
import { useDebounceFn } from '@vueuse/core'

export interface Loadable<T> {
  data: Ref<T | undefined>
  loading: Ref<boolean>
  error: Ref<BackendError | undefined>
  ready: Ref<boolean>
  load: () => Promise<void>
}

export function useLoadable<T, A> (api: A, doLoad: (api: A) => Promise<T>): Loadable<T> {
  const data = ref<T>()

  const loading = ref(true)
  const ready = ref(false)

  const error = ref(undefined as BackendError | undefined)

  async function load () {
    try {
      loading.value = true
      ready.value = false
      error.value = undefined
      data.value = await doLoad(api)
    } catch (resp) {
      error.value = await errorFromResponse(resp)
    } finally {
      ready.value = true
      loading.value = false
    }
  }

  onMounted(async () => await load())

  return { data, loading, error, ready, load }
}

export function useSaveable<T, A> (api: A, load: (api: A) => Promise<T>, save: (api: A, t: T) => Promise<unknown>) {
  const state = reactive({
    changed: false,
    saving: false
  })

  const { data, loading, error, ready } = useLoadable(api, load)

  async function saveEager (t: T): Promise<void> {
    state.saving = true
    error.value = undefined
    try {
      await save(api, t)
    } catch (resp) {
      error.value = await errorFromResponse(resp)
    } finally {
      state.saving = false
      state.changed = false
    }
  }

  const saveLazy = useDebounceFn((t: T) => {
    saveEager(t)
      .catch(err => console.error('Failed to saveEager()', err))
  }, 2000, { maxWait: 10000 })

  function onChanged () {
    if (!ready) return
    state.changed = true
    const d = data.value
    if (d) saveLazy(d)
  }

  return { ...toRefs(state), data, loading, error, ready, onChanged }
}

export interface BackendError {
  url: string,
  status: number,
  message: string,
  error?: string,
  extra?: ErrorValue
}
export async function errorFromResponse (error: unknown): Promise<BackendError> {
  if (error instanceof Error && error.name === 'ResponseError' && 'response' in error) {
    const resp = (error as ResponseError).response
    const message = await (resp.json() as Promise<{ status: number, message: string, error: string, extra: ErrorValue }>)
      .catch(async () => await resp.text().catch(() => 'Kunde inte ansluta till servern: ' + resp.url))
    return {
      url: resp.url,
      status: resp.status,
      ...(typeof message === 'string' ? { message } : { ...message })
    }
  }

  if (error instanceof Error) {
    console.error('Unknown backend error', error)
    throw error
  }

  throw error
}

export function isHttpXXX (err: unknown, code: number) {
  return err && err instanceof Error && err.name === 'ResponseError' && (err as ResponseError).response.status === code
}
