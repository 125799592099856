
import { keys, notEqual } from '@/lib/common'
import { Api } from '@/lib/di/api'
import { BackendError, errorFromResponse, useLoadable } from '@/use/loadable'
import Vue, { computed, defineComponent, reactive, ref } from 'vue'
import { Organisation, OrganisationWithStats } from '../../api/index'

import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'
import { useLoginGuard } from '@/use/login'
import { useModal } from '@/use/bv-modal'

export default defineComponent({
  name: 'OrganisationList',
  components: { BackendErrorMessage },
  setup (props, context) {
    const { msgBoxConfirm } = useModal()
    const api = Api.admin()
    const orgs = useLoadable(api, async api => await api.listOrganisations())

    useLoginGuard(['Superadmin'])

    type Form = Omit<Organisation, 'id'>
    const defaults = {
      name: '',
      demo: false
    }
    const form = reactive<Form>({ ...defaults })

    const editing = ref<string>()
    const submitError = ref<BackendError>()

    async function doSubmit () {
      orgs.loading.value = true
      orgs.ready.value = false

      try {
        if (editing.value) {
          await api.updateOrganisation({ organisationId: editing.value, organisationCreate: form })
        } else {
          await api.createOrganisation({ organisationCreate: form })
        }
        doClear()
      } catch (err) {
        submitError.value = await errorFromResponse(err)
      } finally {
        orgs.loading.value = false
        await orgs.load()
      }
    }

    function doClear () {
      submitError.value = undefined
      editing.value = undefined
      Object.assign(form, defaults)
    }

    function doEdit ({ id, ...rest }: OrganisationWithStats) {
      editing.value = id
      for (const k of keys(form)) {
        if (k in rest) {
          Vue.set(form, k, rest[k])
        }
      }
    }

    async function doDelete (org: OrganisationWithStats) {
      const opts = {
        okVariant: 'danger',
        okTitle: 'Ta bort!',
        cancelTitle: 'Avbryt'
      }
      const result = await msgBoxConfirm('Är du säker på att du vill ta bort organisationen: ' + org.name, opts)
      if (result === true) {
        orgs.loading.value = true

        try {
          await api.deleteOrganisation({ organisationId: org.id })
        } catch (err) {
          submitError.value = await errorFromResponse(err)
        } finally {
          orgs.loading.value = false
          await orgs.load()
        }
      }
    }

    return {
      ...orgs,

      fields: [
        { label: 'ID', key: 'id', tdClass: 'id' },
        { label: 'Namn', key: 'name' },
        { label: 'Användare', key: 'users' },
        { label: 'Patienter', key: 'patients' },
        { label: 'Utredningar', key: 'investigations' },
        { label: '', key: 'demo' },
        { label: '', key: 'action' }
      ],
      form,
      doSubmit,
      doClear,
      doEdit,
      doDelete,
      editing,
      submitError,
      filter: computed(() => notEqual(editing.value)),
      location: document.location.host
    }
  },
  metaInfo: {
    title: 'Organisationer'
  }
})
