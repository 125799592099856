
import { defineComponent } from 'vue'
import { usePatient } from '@/use/patients'
import { usePatientInvestigationEdit } from '@/use/investigation'
import InvestigationForm from '@/components/form/InvestigationForm.vue'
import { useLoginGuard } from '@/use/login'
import { useRouter } from 'vue-router/composables'
import { computedParam } from '@/use/route'

export default defineComponent({
  components: { InvestigationForm },
  name: 'PatientEditInvestigation',
  setup () {
    const patientId = computedParam('patient_id')
    const investigationId = computedParam('investigation_id')

    useLoginGuard()

    const router = useRouter()
    const { data: patient } = usePatient(patientId)
    const { error, form, submit, validation, options } = usePatientInvestigationEdit(investigationId, () => {
      router.push({ name: 'Patient', params: { id: patientId.value } })
        .catch(err => console.error('Failed to navigate', err))
    })

    return { patientId, investigationId, patient, error, form, submit, validation, options }
  },
  metaInfo: {
    title: 'Skapa utredning'
  }
})
