
import { computed, defineComponent, PropType } from 'vue'

import { Cluster, ClusterData, Dimension, Answer, AnswerOption } from '../api/index'

import { isFiltered } from '../lib/form-answer'

import AnswerSlider from '@/components/clusterreview/AnswerSlider.vue'
import AnswerBoxes from '@/components/clusterreview/AnswerBoxes.vue'

export default defineComponent({
  name: 'ClusterReviewDimensionCard',
  components: { AnswerSlider, AnswerBoxes },
  props: {
    dimension: { type: Object as PropType<Dimension>, required: true },
    cluster: { type: Object as PropType<Cluster>, required: true },
    primary: { type: Object as PropType<ClusterData>, required: false },
    secondary: { type: Object as PropType<ClusterData>, required: false },
    highlight: { type: Boolean, default: false },
    showSaving: { type: Boolean, default: false }
  },
  setup (props, { emit }) {
    function isSelected (data: ClusterData, cluster: Cluster, dim: Dimension, answer: Answer, opt: AnswerOption) {
      return String(data[cluster.key][dim.code][answer.key]) === String(opt.value)
    }

    function createCorrection (cluster: Cluster, dim: Dimension) {
      emit('update:secondary', { cluster, dim })
    }

    function hasCorrection (cluster: Cluster, dim: Dimension): boolean {
      return !!props.secondary &&
        props.secondary[cluster.key] &&
        props.secondary[cluster.key][dim.code] &&
        Object.keys(props.secondary[cluster.key][dim.code]).length > 0
    }

    function setCorrection (cluster: Cluster, dim: Dimension, answer: Answer, value: number | string) {
      emit('update:secondary', { cluster, dim, answer, value })
    }

    function removeCorrection (cluster: Cluster, dim: Dimension) {
      emit('remove:secondary', { cluster, dim })
    }

    const primaryAnswers = computed(() => {
      return props.primary ? props.primary[props.cluster.key][props.dimension.code] : {}
    })
    const secondaryAnswers = computed(() => {
      return props.secondary ? props.secondary[props.cluster.key][props.dimension.code] : {}
    })

    return {
      isSelected,
      isFiltered,
      createCorrection,
      hasCorrection,
      setCorrection,
      removeCorrection,
      primaryAnswers,
      secondaryAnswers
    }
  }
})
