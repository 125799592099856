import { Consent, ConsentResponse } from '@/api'
import { Api } from '@/lib/di/api'
import { computed, ref } from 'vue'

export function useConsent () {
  const consents = Api.consent()

  const termsTitle = 'GDPR - Villkor för godkännande av behandling utav lämnade uppgifter'
  const termsVersion = '1.0.0'
  const termsPayload = `
<p>Den 25:e maj 2018 trädde den nya dataskyddsförordningen (GDPR) i kraft i hela EU och ersätter personuppgiftslagen (PUL). Syftet med PUL har varit att skydda människor mot att deras personliga integritet kränks när deras personuppgifter behandlas. Med GDPR förstärks rättigheterna för den enskilde ytterligare genom strängare krav på hur företag behandlar personuppgifter.</p>
<p>Med anledning av den nya förordningen behöver vi ditt godkännande för av lagring av dina uppgifter till din behandlande läkare. Vissa av frågorna i våra enkäter kan vara av känslig natur och dina svar på dessa frågor kan komma att innehålla uppgifter om t.ex. din fysiska och psykiska hälsa och andra områden som kan uppfattas som integritetskänsliga. Uppgiftena som du lämnar ligger till grund för att du ska kunna få en korrekt bedömning av förmån som vård, rehabilitering, hjälpinsats och ersättning som vidare beslutas av gällande vårdaktör som försäkringsbolag, kommun annan myndighet eller verk.</p>
<p>Syftet med behandlingen av uppgifterna (inklusive känsliga uppgifter) är att du ska kunna få en så exakt bedömning som är möjlig. Bedömningen är baserad på det de svar du givit och det underlag som svaren på dina frågor resulterar i. Sammanfattningsvis behandlar vi uppgifterna för att kunna genomföra, sammanställa och analysera resultatet av dina lämnade uppgifter, undersökningar och åtgärder.</p>
  `.trim()

  const consent = ref<ConsentResponse | Consent | null>()
  const consentCompleted = computed(() => consent.value && 'completedAt' in consent.value && consent.value.completedAt)

  async function findConsent (investigationId: string) {
    const consentResp = await consents.searchRaw({ investigationId, version: termsVersion })
    if (consentResp.raw.ok) {
      consent.value = await consentResp.value()
      if (consent.value) {
        return true
      }
    }
    return false
  }

  async function prefill () {
    const result = await consents.prefill({
      consentRequest: { terms_version: termsVersion, terms_payload: termsPayload }
    })
    consent.value = result
  }

  return { termsPayload, termsTitle, termsVersion, consent, consentCompleted, findConsent, prefill }
}
