/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { LoginStatusSuccessOrganisation } from './LoginStatusSuccessOrganisation';
import {
    LoginStatusSuccessOrganisationFromJSON,
    LoginStatusSuccessOrganisationFromJSONTyped,
    LoginStatusSuccessOrganisationToJSON,
} from './LoginStatusSuccessOrganisation';
/**
 * A LoginStatus when logged in
 * @export
 * @interface LoginStatusSuccess
 */
export interface LoginStatusSuccess {
    /**
     *
     * @type {LoginStatusSuccessOrganisation}
     * @memberof LoginStatusSuccess
     */
    organisation?: LoginStatusSuccessOrganisation;
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccess
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccess
     */
    username: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LoginStatusSuccess
     */
    scopes: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccess
     */
    role: string;
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccess
     */
    type: LoginStatusSuccessTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof LoginStatusSuccess
     */
    loggedIn: LoginStatusSuccessLoggedInEnum;
}
/**
 * @export
 */
export const LoginStatusSuccessTypeEnum = {
    Identity: 'identity',
    Login: 'login'
} as const;
export type LoginStatusSuccessTypeEnum = typeof LoginStatusSuccessTypeEnum[keyof typeof LoginStatusSuccessTypeEnum];
/**
 * @export
 */
export const LoginStatusSuccessLoggedInEnum = {
    True: true
} as const;
export type LoginStatusSuccessLoggedInEnum = typeof LoginStatusSuccessLoggedInEnum[keyof typeof LoginStatusSuccessLoggedInEnum];
/**
 * Check if a given object implements the LoginStatusSuccess interface.
 */
export function instanceOfLoginStatusSuccess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "scopes" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "loggedIn" in value;
    return isInstance;
}
export function LoginStatusSuccessFromJSON(json: any): LoginStatusSuccess {
    return LoginStatusSuccessFromJSONTyped(json, false);
}
export function LoginStatusSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginStatusSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'organisation': !exists(json, 'organisation') ? undefined : LoginStatusSuccessOrganisationFromJSON(json['organisation']),
        'name': json['name'],
        'username': json['username'],
        'scopes': json['scopes'],
        'role': json['role'],
        'type': json['type'],
        'loggedIn': json['loggedIn'],
    };
}
export function LoginStatusSuccessToJSON(value?: LoginStatusSuccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'organisation': LoginStatusSuccessOrganisationToJSON(value.organisation),
        'name': value.name,
        'username': value.username,
        'scopes': value.scopes,
        'role': value.role,
        'type': value.type,
        'loggedIn': value.loggedIn,
    };
}