/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  FederatedLogin,
  HttpError,
  OpenIdentitySession,
  VerifyOpenIdentityForm,
  VerifyOpenIdentityResult,
} from '../models';
import {
    FederatedLoginFromJSON,
    FederatedLoginToJSON,
    HttpErrorFromJSON,
    HttpErrorToJSON,
    OpenIdentitySessionFromJSON,
    OpenIdentitySessionToJSON,
    VerifyOpenIdentityFormFromJSON,
    VerifyOpenIdentityFormToJSON,
    VerifyOpenIdentityResultFromJSON,
    VerifyOpenIdentityResultToJSON,
} from '../models';
export interface OpenIdentityApiCompleteRequest {
    verifyOpenIdentityResult: VerifyOpenIdentityResult;
}
export interface OpenIdentityApiStartRequest {
    verifyOpenIdentityForm: VerifyOpenIdentityForm;
}
/**
 *
 */
export class OpenIdentityApi extends runtime.BaseAPI {
    /**
     */
    async completeRaw(requestParameters: OpenIdentityApiCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenIdentitySession>> {
        if (requestParameters.verifyOpenIdentityResult === null || requestParameters.verifyOpenIdentityResult === undefined) {
            throw new runtime.RequiredError('verifyOpenIdentityResult','Required parameter requestParameters.verifyOpenIdentityResult was null or undefined when calling complete.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/identity-open/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyOpenIdentityResultToJSON(requestParameters.verifyOpenIdentityResult),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OpenIdentitySessionFromJSON(jsonValue));
    }
    /**
     */
    async complete(requestParameters: OpenIdentityApiCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenIdentitySession> {
        const response = await this.completeRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async startRaw(requestParameters: OpenIdentityApiStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FederatedLogin>> {
        if (requestParameters.verifyOpenIdentityForm === null || requestParameters.verifyOpenIdentityForm === undefined) {
            throw new runtime.RequiredError('verifyOpenIdentityForm','Required parameter requestParameters.verifyOpenIdentityForm was null or undefined when calling start.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/identity-open/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyOpenIdentityFormToJSON(requestParameters.verifyOpenIdentityForm),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FederatedLoginFromJSON(jsonValue));
    }
    /**
     */
    async start(requestParameters: OpenIdentityApiStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FederatedLogin> {
        const response = await this.startRaw(requestParameters, initOverrides);
        return await response.value();
    }
}