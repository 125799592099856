
import { computed, defineComponent, PropType } from 'vue'
import { Answer, AnswerOption, AnswerChildOption } from '../api/index'

import Collapsible from '@/components/common/Collapsible.vue'

export default defineComponent({
  name: 'AnswerFormOption',
  components: { Collapsible },
  props: {
    answer: { type: Object as PropType<Answer>, required: true },
    option: { type: Object as PropType<AnswerOption | AnswerChildOption>, required: true }
  },
  setup (props) {
    return {
      displayValue: computed(() => !!props.answer.primary && !props.option.title === props.option.value),
      displayDescription: computed(() => props.option.description && !!props.answer.primary)
    }
  }
})
