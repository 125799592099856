import { Api } from '@/lib/di/api'
import { } from '@/lib/formatter'
import { BackendError, errorFromResponse } from '@/use/loadable'
import { computed, ComputedRef, reactive, ref, unref } from 'vue'
import { UserCreate } from '../../api/index'
import { MaybeRef } from '@/lib/types'
import { useModal } from '../bv-modal'

type UserForm = UserCreate & { name: string; username: string }
interface OrgUser { name: string, username: string, id: string; role: string, idp_usernames: string }

const REGEX_SITHS = /^\D{2}\d{8,16}-[A-Z0-9]{4}$/
const REGEX_SSN = /^(19|20)\d{6}\d{4}$/

export function useUserForm (organisationId: MaybeRef<string>, onPostSubmit: () => PromiseLike<void>) {
  const form = reactive<UserForm>({
    password: '',
    role: '',
    name: '',
    username: '',
    idp_usernames: []
  })
  const valid = reactive<Record<keyof UserForm, boolean | null | ComputedRef<boolean | null>>>({
    name: computed(() => form.name === '' ? null : form.name.length > 3),
    username: computed(() => form.username === '' ? null : !!form.username.match(/^\S+@\S+\.\S+$/gi)),
    password: computed(() => form.password === '' ? null : form.password.length >= 8),
    role: computed(() => form.role === '' ? null : true),
    idp_usernames: computed(() => {
      if (editing.value) return (form.password.length >= 8 || form.password === '') ? true : form.idp_usernames.length > 0
      else return form.password.length >= 8 ? true : form.idp_usernames.length > 0
    })
  })
  const canSubmit = computed(() => Object.entries(valid)
    .map(([k, v]) => ([k, (k === 'password' && editing.value && v === null) ? true : v]))
    .every(([, v]) => v === true)
  )

  function doClear () {
    error.value = undefined
    editing.value = undefined
    for (const _k of Object.keys(form)) {
      const k = _k as keyof UserForm
      if (k === 'idp_usernames') {
        form[k] = []
      } else {
        form[k] = ''
      }
    }
  }

  const error = ref<BackendError | undefined>()
  const editing = ref<string>()
  const loading = ref<boolean>(false)

  const api = Api.admin()
  async function doSubmit (evt: Event) {
    loading.value = true

    try {
      const { idp_usernames, ...rest } = form
      const data = idp_usernames.length > 0 ? { idp_usernames, ...rest, password: '' } : form
      if (editing.value) {
        await api.updateUser({
          organisationId: unref(organisationId),
          userId: editing.value,
          userUpdate: data
        })
      } else {
        await api.createUser({
          organisationId: unref(organisationId),
          userCreate: data
        })
      }
      doClear()
    } catch (err) {
      error.value = await errorFromResponse(err)
      evt.preventDefault()
    } finally {
      loading.value = false
      await onPostSubmit()
    }
  }

  function doEdit (user: OrgUser) {
    editing.value = user.id
    if (user) {
      for (const k of Object.keys(form).filter(k => k !== 'password')) {
        Object.assign(form, { [k]: user[k as Exclude<keyof UserForm, 'password'>] })
      }
      form.password = ''
    }
  }

  const { msgBoxConfirm, createElement } = useModal()

  async function doRemove (user: OrgUser) {
    const opts = {
      okVariant: 'danger',
      okTitle: 'Ta bort!',
      cancelTitle: 'Avbryt'
    }
    const result = await msgBoxConfirm([
      createElement('div', 'Är du säker på att du vill ta bort användaren?'),
      createElement('div', `Namn: ${user.name}`),
      createElement('div', `Epost: ${user.username}`)
    ], opts)
    if (result === true) {
      loading.value = true

      try {
        await api.deleteUser({ organisationId: unref(organisationId), userId: user.id })
      } catch (err) {
        error.value = await errorFromResponse(err)
      } finally {
        loading.value = false
        await onPostSubmit()
      }
    }
  }

  function idpValidator (idp: string) {
    // BankID: , HSA: SE123456789-9876,
    if (idp.match(REGEX_SSN)) {
      return true
    }
    if (idp.match(REGEX_SITHS)) {
      return true
    }
    return false
  }

  return {
    doClear,
    doEdit,
    doRemove,
    doSubmit,
    canSubmit,
    idpValidator,
    form,
    valid,
    loading,
    error,
    editing
  }
}
