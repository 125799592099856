
import { defineComponent, watch } from 'vue'
import { useInvestigationOptions } from '@/use/investigation'
import { usePatient } from '@/use/patients'

import { ssnToAge, prettyDate } from '@/lib/date'
import { Api } from '@/lib/di/api'

import { useLoginGuard } from '@/use/login'
import KeyValue from '@/components/common/KeyValue.vue'
import { errorFromResponse } from '@/use/loadable'
import BackendErrorMessage from '@/components/common/BackendErrorMessage.vue'
import { useClipboard, useTitle } from '@vueuse/core'
import { computedParam } from '@/use/route'
import { z } from 'zod'
import { useRouter } from 'vue-router/composables'
import { useModal } from '@/use/bv-modal'

export default defineComponent({
  components: { KeyValue, BackendErrorMessage },
  setup () {
    const investigations = Api.investigation()
    const patients = Api.patient()
    const router = useRouter()

    const id = computedParam('id', z.string())
    const { error, loading, data: patient, load: refreshPatient } = usePatient(id)
    const options = useInvestigationOptions()

    const { reload } = useLoginGuard()

    const { isSupported: hasClipboardSupport, copy: copyToClipboard } = useClipboard()

    function edit () {
      router.push({ name: 'PatientEdit', params: { id: id.value } })
        .catch(err => console.error('Failed to navigate', err))
    }

    watch(error, (err) => {
      if (err && err.status === 401) {
        reload()
          .catch(err => console.error('Failed to reload()', err))
      }
    })

    useTitle(() => patient.value ? `E-Admin ${patient.value.name}` : 'E-Admin Patient')

    const { msgBoxConfirm, msgBoxOk } = useModal()
    async function confirmDelete (firstText: string, secondText: string, fn: () => Promise<void>) {
      const first = await msgBoxConfirm(firstText, {
        okTitle: 'JA',
        okVariant: 'danger',
        cancelTitle: 'NEJ',
        cancelVariant: 'primary'
      })
      if (first) {
        const second = await msgBoxConfirm(secondText, {
          okTitle: 'JA, jag är HELT säker',
          okVariant: 'danger',
          cancelTitle: 'NEJ',
          cancelVariant: 'primary'
        })
        if (second) {
          try {
            await fn()
          } catch (e) {
            error.value = await errorFromResponse(e)
          }
        }
      }
    }

    async function confirmDeletePatient () {
      if ((patient.value?.investigations.length ?? 0) > 0) {
        return await msgBoxOk('Det går inte att ta bort patienter med kopplade utredningar. Ta bort alla utredningar först.')
      }
      await confirmDelete(
        'Är du säker på att du vill ta bort patienten?',
        'Är du HELT säker på att du vill ta bort patienten samt alla utredningar och tillhörande data om patientens svar och åtgärdsplaner?',
        async () => {
          await patients._delete({ patientId: id.value ?? '' })
          await router.push({ name: 'PatientList' })
        }
      )
    }

    async function confirmDeleteInvestigation (investigationId: string) {
      await confirmDelete(
        'Är du säker på att du vill ta bort utredningen?',
        'Är du HELT säker på att du vill ta bort utredningen och tillhörande data om patientens svar och åtgärdsplaner?',
        async () => {
          await investigations._delete({ investigationId })
          await refreshPatient()
        }
      )
    }

    function link (id?: string): string {
      return id ? document.location.origin + router.resolve({ name: 'Welcome', params: { id } }).href : '#'
    }

    return {
      link, confirmDeletePatient, confirmDeleteInvestigation,
      id, error, loading, hasClipboardSupport,
      patient, prettyDate, copyToClipboard,
      edit, ssnToAge, ...options
    }
  },
  metaInfo: {
    title: 'Patient'
  }
})
