
import { Api } from '@/lib/di/api'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { AuditItem } from '../../api/index'
import { useIntervalFn, useLocalStorage } from '@vueuse/core'

import { format, parseISO } from 'date-fns'
import { useLoginGuard } from '@/use/login'

export default defineComponent({
  name: 'AuditLog',
  setup () {
    const api = Api.get()
    const log = ref<AuditItem[]>([])
    const highlight = ref<Set<number>>(new Set())
    const expanded = ref<number>()

    useLoginGuard()
    async function refresh () {
      const oldids = new Set(log.value.map(({ id }) => id))
      const result = await api.listAudits({ })
      if (log.value.length > 0) {
        highlight.value = new Set(result.filter(({ id }) => !oldids.has(id)).map(({ id }) => id))
      }
      if (result.length > 0) {
        log.value = result.map(i => ({ ...i, _showDetails: i.id === expanded.value }))
      }

      setTimeout(() => {
        highlight.value = new Set()
      }, 5000)
    }

    function rowClass (item: AuditItem) {
      return highlight.value.has(item.id) ? 'table-warning' : ''
    }

    onMounted(refresh)
    useIntervalFn(() => {
      refresh()
        .catch(err => console.log('Failed to call refresh', err))
    }, 2000)

    const fields: Array<{ key: keyof AuditItem, label: string, tdClass?: string }> = [
      { key: 'id', label: 'id', tdClass: 'id' },
      { key: 'at', label: 'När', tdClass: 'at' },
      { key: 'action', label: 'Typ', tdClass: 'type' },
      { key: 'subject', label: 'Vem (namn)', tdClass: 'subject' },
      { key: 'resource', label: 'Vad (id)', tdClass: 'resource' },
      { key: 'change', label: 'Ändring', tdClass: 'change' }
    ]

    type Action = 'edit' | 'create' | 'view' | 'delete'
    const actions: Record<Action, { text: string, style: string }> = {
      edit: { text: 'Edit', style: 'warning' },
      create: { text: 'Create', style: 'success' },
      view: { text: 'View', style: '' },
      delete: { text: 'Delete', style: 'danger' }
    }
    const filter = useLocalStorage<Action[]>('auditview-filters', ['edit', 'delete', 'create'])

    return {
      log: computed(() => log.value.filter(l => filter.value.includes(l.action as unknown as Action))),
      refresh, rowClass, fields, expanded, actions, filter,
      setExpanded: (id: number, fn: () => void) => {
        expanded.value = expanded.value === id ? undefined : id
        fn()
      }, format, parseISO
    }
  },
  metaInfo: {
    title: 'Händelselogg'
  }
})
