
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { Consent } from '../../api/index'
import { format } from 'date-fns'
import { useGlobalLogin } from '@/use/login'
import { useConsent } from '@/use/consent'
import { computedParam } from '@/use/route'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'Welcome',
  setup () {

    const id = computedParam('id')
    const { termsPayload, termsVersion, termsTitle, consent, consentCompleted, findConsent, prefill } = useConsent()

    const l = useGlobalLogin()

    const state = reactive({
      consent: computed(() => consent.value !== null),
      consentChecked: false,
      identity: computed(() => l.isIdentity.value && !l.isLogin.value)
    })

    onMounted(async () => {
      try {
        state.consentChecked = await findConsent(id.value)
      } catch (e) {
        console.warn('Error in Welcome onMounted', e)
        consent.value = null
      }
    })

    const router = useRouter()
    async function next () {
      await prefill()
      await router.push({ name: 'Verify', params: { id: id.value } })
    }

    return {
      id, state, termsPayload, termsVersion, termsTitle, consent, consentCompleted, next,
      format: (s: Consent['completedAt']) => {
        return s ? format(s, 'yyyy-MM-dd HH:mm') : ''
      }
    }
  },
  metaInfo: {
    title: 'Välkomen'
  }
})
