/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { HttpErrorExtra } from './HttpErrorExtra';
import {
    HttpErrorExtraFromJSON,
    HttpErrorExtraFromJSONTyped,
    HttpErrorExtraToJSON,
} from './HttpErrorExtra';
import type { StatusCode } from './StatusCode';
import {
    StatusCodeFromJSON,
    StatusCodeFromJSONTyped,
    StatusCodeToJSON,
} from './StatusCode';
/**
 *
 * @export
 * @interface HttpError
 */
export interface HttpError {
    /**
     *
     * @type {string}
     * @memberof HttpError
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof HttpError
     */
    message: string;
    /**
     *
     * @type {string}
     * @memberof HttpError
     */
    stack?: string;
    /**
     *
     * @type {StatusCode}
     * @memberof HttpError
     */
    status: StatusCode;
    /**
     *
     * @type {HttpErrorExtra}
     * @memberof HttpError
     */
    extra: HttpErrorExtra | null;
}
/**
 * Check if a given object implements the HttpError interface.
 */
export function instanceOfHttpError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "extra" in value;
    return isInstance;
}
export function HttpErrorFromJSON(json: any): HttpError {
    return HttpErrorFromJSONTyped(json, false);
}
export function HttpErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): HttpError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'name': json['name'],
        'message': json['message'],
        'stack': !exists(json, 'stack') ? undefined : json['stack'],
        'status': StatusCodeFromJSON(json['status']),
        'extra': HttpErrorExtraFromJSON(json['extra']),
    };
}
export function HttpErrorToJSON(value?: HttpError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'name': value.name,
        'message': value.message,
        'stack': value.stack,
        'status': StatusCodeToJSON(value.status),
        'extra': HttpErrorExtraToJSON(value.extra),
    };
}