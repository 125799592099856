

import { defineComponent } from 'vue'
import { computedParam } from '@/use/route'

export default defineComponent({
  name: 'Welcome',
  setup () {
    const id = computedParam('id')

    return { id }
  },
  metaInfo: {
    title: 'Välkomen'
  }
})
