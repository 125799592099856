
import Vue, { defineComponent, computed, watch, reactive } from 'vue'

import { useInvestigationForm } from '@/use/cluster-form'
import { isComplete as _isComplete } from '@/lib/form-answer'
import { flatMap } from '@/lib/array'
import { AnswerData, ClusterData } from '../../api/index'
import { Api } from '@/lib/di/api'
import { empty, failWith } from '@/lib/common'

import AnswerForm from '@/components/AnswerForm.vue'
import { useLoginGuard } from '@/use/login'
import { computedParam, computedQuery } from '@/use/route'
import { z } from 'zod'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'Reword',
  components: { AnswerForm },
  setup () {
    const router = useRouter()

    useLoginGuard()

    const returnTo = computedQuery('returnTo', z.string().optional())
    const id = computedParam('id')
    const cluster_key = computedParam('cluster')
    const dimension_key = computedParam('dimension')

    const api = Api.investigation()
    const { data: form, ...formstate } = useInvestigationForm(id)
    const data = reactive<{ answer: AnswerData }>({
      answer: {}
    })

    const cluster = computed(() => {
      return form.value?.clusters.find(c => c.key === cluster_key.value)
    })

    const keys = computed(() => cluster.value
      ? flatMap(cluster.value.answers, a => a.child_key ? [a.key, a.child_key] : [a.key])
      : [])

    const dimension = computed(() => {
      return cluster.value?.dimensions.find(d => d.code === dimension_key.value)
    })

    const isComplete = computed(() => {
      return _isComplete(cluster.value?.answers ?? [], data.answer)
    })

    function safe (data: ClusterData | undefined, cluster: string, dimension: string): AnswerData {
      return data && cluster in data && dimension in data[cluster] ? data[cluster][dimension] : {}
    }

    watch(formstate.loading, () => {
      if (keys.value.length > 0 && !formstate.error.value) {
        for (const k of keys.value) {
          Vue.set(data.answer, k, null)
        }
        restore()
          .catch(err => console.error('Failed to restore', err))
      }
    })

    async function restore () {
      const [professionAnswers, patientAnswers] = await Promise.all([
        failWith<ClusterData>({}, async () => await api.getProfessionAnswers({ investigationId: id.value })),
        failWith<ClusterData>({}, async () => await api.getPatientAnswers({ investigationId: id.value }))
      ])
      const professionAnswer = safe(professionAnswers, cluster_key.value, dimension_key.value)
      const patientAnswer = safe(patientAnswers, cluster_key.value, dimension_key.value)

      const prefill = empty(professionAnswer) ? patientAnswer : professionAnswer
      for (const [k, v] of Object.entries(prefill)) {
        Vue.set(data.answer, k, v)
      }
    }

    async function saveAndNavigate () {
      await api.saveProfessionAnswers({
        investigationId: id.value,
        clusterData: { [cluster_key.value]: { [dimension_key.value]: data.answer } }
      })
      router.push(returnTo.value ?? router.resolve({ name: 'Overview', params: { id: id.value } }).href)
        .catch(err => console.error('Failed to navigate', err))
    }

    return { id, form, ...formstate, cluster, dimension, data, returnTo, isComplete, saveAndNavigate }
  },
  metaInfo: {
    title: 'Komplettera'
  }
})
