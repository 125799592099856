/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { AnswerData } from './AnswerData';
import {
    AnswerDataFromJSON,
    AnswerDataFromJSONTyped,
    AnswerDataToJSON,
} from './AnswerData';
/**
 * dimension -> answer -> value
 * @export
 * @interface DimensionData
 */
export interface DimensionData {
    [key: string]: AnswerData;
}
/**
 * Check if a given object implements the DimensionData interface.
 */
export function instanceOfDimensionData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function DimensionDataFromJSON(json: any): DimensionData {
    return DimensionDataFromJSONTyped(json, false);
}
export function DimensionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DimensionData {
    return json;
}
export function DimensionDataToJSON(value?: DimensionData | null): any {
    return value;
}