
import { Nullable } from '@/lib/types'
import { InvestigationFormModel, InvestigationFormOptions } from '@/use/investigation'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'InvestigationForm',
  components: {},
  props: {
    validation: { type: Object as PropType<Nullable<InvestigationFormModel>>, required: true },
    options: { type: Object as PropType<InvestigationFormOptions>, required: true },
    form: { type: Object as PropType<InvestigationFormModel>, required: true },
    error: { type: [String, Object], required: false },
    actionLabel: { type: String, required: true },
    showFakeFill: { type: Boolean, required: false }
  }
})
