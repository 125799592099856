/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ClusterOrder
 */
export interface ClusterOrder {
    /**
     *
     * @type {number}
     * @memberof ClusterOrder
     */
    radar: number;
    /**
     *
     * @type {number}
     * @memberof ClusterOrder
     */
    flow: number;
}
/**
 * Check if a given object implements the ClusterOrder interface.
 */
export function instanceOfClusterOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "radar" in value;
    isInstance = isInstance && "flow" in value;
    return isInstance;
}
export function ClusterOrderFromJSON(json: any): ClusterOrder {
    return ClusterOrderFromJSONTyped(json, false);
}
export function ClusterOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'radar': json['radar'],
        'flow': json['flow'],
    };
}
export function ClusterOrderToJSON(value?: ClusterOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'radar': value.radar,
        'flow': value.flow,
    };
}