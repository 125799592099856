/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * Verify Identity Form
 * @export
 * @interface VerifyOpenIdentityForm
 */
export interface VerifyOpenIdentityForm {
    /**
     *
     * @type {string}
     * @memberof VerifyOpenIdentityForm
     */
    callbackUrl: string;
    /**
     *
     * @type {string}
     * @memberof VerifyOpenIdentityForm
     */
    backUrl: string;
}
/**
 * Check if a given object implements the VerifyOpenIdentityForm interface.
 */
export function instanceOfVerifyOpenIdentityForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "callbackUrl" in value;
    isInstance = isInstance && "backUrl" in value;
    return isInstance;
}
export function VerifyOpenIdentityFormFromJSON(json: any): VerifyOpenIdentityForm {
    return VerifyOpenIdentityFormFromJSONTyped(json, false);
}
export function VerifyOpenIdentityFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyOpenIdentityForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'callbackUrl': json['callbackUrl'],
        'backUrl': json['backUrl'],
    };
}
export function VerifyOpenIdentityFormToJSON(value?: VerifyOpenIdentityForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'callbackUrl': value.callbackUrl,
        'backUrl': value.backUrl,
    };
}