import { AnswerData, ClusterData, FormDefinition } from '../../api/index'

import { dimensionTitle } from '@/lib/form-answer'

export interface TooltipItem {
  // Label for the tooltip
  label: string,

  // Value for the tooltip
  value: string,

  // X Value of the tooltip
  // (deprecated) use `value` or `label` instead
  xLabel: number | string,

  // Y value of the tooltip
  // (deprecated) use `value` or `label` instead
  yLabel: number | string,

  // Index of the dataset the item comes from
  datasetIndex: number,

  // Index of this data item in the dataset
  index: number,

  // X position of matching point
  x: number,

  // Y position of matching point
  y: number
}

export interface TooltipData {
  datasets: Array<{
    label: string[]
    data: number[]
  }>,
  labels: string[]
}

export function createTooltipDescription (item: TooltipItem, form: FormDefinition, clusterdatasets: ClusterData | Array<{cluster: ClusterData, title: string}>, column: (a: AnswerData) => number) {

  const data = form.clusters.filter(c => c.order).find(c => c.order!.radar === item.index)
  if (!data) {
    return 'Ingen data'
  }
  const clusterKey = data.key
  const cluster: ClusterData = Array.isArray(clusterdatasets) ? clusterdatasets[item.datasetIndex].cluster : clusterdatasets
  const clusterData = cluster[clusterKey]
  const clusterDef = form.clusters.find(c => c.key === clusterKey)

  if (!clusterDef) {
    return 'Okänd dimension'
  }

  const codes = Object.entries(clusterData)
    .filter(([, v]) => {
      return typeof column(v) === 'number'
    })
    .map(([k, v]) => ({ value: column(v), data: v, question: dimensionTitle(clusterDef, k) }))
  codes.sort(({ value: a }, { value: b }) => b - a)

  const result = codes
    .map(({ value, question }) => `${value}: ${question}`)

  const sum = codes.reduce((value, { value: valueX }) => value + valueX, 0)

  if (result.length > 0) {
    return [...result, `Summa: ${sum} (Medel ${(sum / codes.length).toFixed(1)})`]
  } else {
    return ['Ingen avvikelse']
  }

}
