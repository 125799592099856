
import router from '@/router'
import { useGlobalLogin } from '@/use/login'
import { computedQuery } from '@/use/route'
import { defineComponent, ref } from 'vue'
import { useTimeoutFn } from '@vueuse/core'
import { z } from 'zod'

export default defineComponent({
  setup () {
    const done = ref(false)
    const noop = computedQuery('noop', z.string().optional())

    const { logout } = useGlobalLogin()

    const count = ref(3)

    useTimeoutFn(() => count.value--, 1000)
    useTimeoutFn(() => count.value--, 2000)
    useTimeoutFn(() => count.value--, 3000)

    async function doLogout () {
      await logout()
      done.value = true
      useTimeoutFn(() => {
        if (!noop.value) {
          router.push({ name: 'Login' })
            .catch(err => console.error('Failed to navigate', err))
        }
      }, 500)
    }

    useTimeoutFn(() => {
      doLogout()
        .catch(err => console.error('Failed to perform logout', err))
    }, 3500)

    return { done, count }
  },
  metaInfo: {
    title: 'Logga ut'
  }
})
