import { render, staticRenderFns } from "./ClusterReviewDimensionCard.vue?vue&type=template&id=821be942&scoped=true&"
import script from "./ClusterReviewDimensionCard.vue?vue&type=script&lang=ts&"
export * from "./ClusterReviewDimensionCard.vue?vue&type=script&lang=ts&"
import style0 from "./ClusterReviewDimensionCard.vue?vue&type=style&index=0&id=821be942&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "821be942",
  null
  
)

export default component.exports