
import { computed, defineComponent, PropType } from 'vue'

import RadarChart from '@/components/chart/RadarChart.vue'
import { FormDefinition, ClusterData, AnswerData } from '../../api/index'
import { TooltipItem, TooltipData, createTooltipDescription } from './chart'

export default defineComponent({
  name: 'FormRadarChart',
  components: { RadarChart },
  props: {
    clusterdatasets: { type: Array as PropType<Array<{cluster: ClusterData, title: string}>>, required: true },
    form: { type: Object as PropType<FormDefinition>, required: true }
  },
  setup (props: {clusterdatasets: Array<{cluster: ClusterData, title: string}>, form: FormDefinition}) {
    const colors = [
      {
        borderColor: '#36a2eb',
        backgroundColor: '#36a2eba2'
      },
      {
        borderColor: '#e25f5f',
        backgroundColor: '#e25f5fa2'
      }
    ]

    const column = (ans: AnswerData) => ans.grade as number

    return {

      chartdata: computed(() => {
        const clusters = props.form.clusters
          .filter(c => c.order)
          .map(c => ({ key: c.key, title: c.title, order: c.order!.radar }))
          .sort((a, b) => a.order - b.order)

        const datasets = props.clusterdatasets.map(({ title, cluster }, index) => {

          const values = clusters.map(({ key }) => {
            const list = Object.values(cluster ? cluster[key] || {} : {})
              .filter(v => typeof column(v) === 'number')
            return list.map(v => column(v))
              .reduce((p, c) => p + c, 0) / list.length
          })

          return {
            data: values,
            label: title,
            ...colors[index % colors.length]
          }

        })
        const labels = clusters.map(c => c.title)

        return {
          labels,
          datasets
        }
      }),
      options: {
        responsive: true,
        tooltips: {
          titleFontSize: 20,
          callbacks: {
            title: () => 'Avvikelser och bedömningar',
            label: (item: TooltipItem, { datasets, labels }: TooltipData) => {
              const cluster = labels[item.index]
              const dataset = datasets[item.datasetIndex].label as unknown as string

              return `${dataset} bedöming av '${cluster}'`

            },
            afterLabel: (item: TooltipItem) => createTooltipDescription(item, props.form, props.clusterdatasets, column)
          }
        },
        scale: {
          ticks: {
            min: 0,
            max: 4,
            display: true
          },
          angleLines: { display: true }
        }
      }
    }
  }
})
