
import { computed, defineComponent, PropType, ref } from 'vue'

import { FormDefinition, ClusterData } from '../../api/index'
import FormBarChart from '@/components/chart/FormBarChart.vue'

export default defineComponent({
  name: 'FormBarChartInteractive',
  components: { FormBarChart },
  props: {
    clusterdatasets: { type: Array as PropType<Array<{cluster: ClusterData, title: string}>>, required: true },
    form: { type: Object as PropType<FormDefinition>, required: true }
  },
  setup (props: {clusterdatasets: Array<{cluster: ClusterData, title: string}>, form: FormDefinition}) {

    const datasetIdx = ref<number>(0)

    const datasetTitle = computed(() => props.clusterdatasets[datasetIdx.value].title)
    const datasetCluster = computed(() => props.clusterdatasets[datasetIdx.value].cluster)

    const datasets = computed(() => props.clusterdatasets.map((ds, idx) => ({ text: ds.title, value: idx })))

    const hasData = computed(() => {
      return datasetIdx.value in props.clusterdatasets &&
        datasetTitle.value && datasetCluster.value &&
        Object.keys(datasetCluster.value).length > 0
    })

    return {
      datasets,
      datasetIdx,
      datasetTitle,
      datasetCluster,
      hasData
    }
  }
})
