var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"patient container"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'PatientList' },"exact":""}},[_vm._v("Patienter")]),(_vm.patient)?_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.patient.name))]):_vm._e()],1),(_vm.loading)?_c('CenteredLoading'):(_vm.error)?_c('div',[(_vm.error.error === 'DatabaseError')?_c('div',[_c('h1',[_vm._v("Ett fel uppstod")]),(_vm.error.extra && _vm.error.extra.prisma && _vm.error.extra.prisma.type === 'VIOLATE_RELATION')?_c('p',[_vm._v(" Kunde inte ta bort patienten pga. av att underliggande data fanns kvar. ")]):_c('p',[_vm._v(_vm._s(_vm.error.message))])]):_vm._e(),(_vm.error.error === 'IntegrityError' && _vm.error.status === 404)?_c('div',[_c('h1',[_vm._v("Patienten är inte registrerad")]),_c('b-button',{attrs:{"variant":"link","to":{ name: 'PatientList' }}},[_c('b-icon-chevron-left'),_vm._v(" Återgå till patienter ")],1)],1):_c('backend-error-message',{attrs:{"value":_vm.error}})],1):(_vm.patient)?_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.patient.name)+" "),_c('div',{staticClass:"mb-1 float-right"},[_c('b-button',{attrs:{"variant":"link","to":{ name: 'PatientEdit', params: { id: _vm.id } }}},[_c('b-icon-pencil')],1),_c('b-button',{class:[, _vm.patient.investigations.length > 0 ? 'text-muted' : 'text-danger'],attrs:{"variant":"link"},on:{"click":function($event){return _vm.confirmDeletePatient()}}},[_c('b-icon-trash')],1)],1)]),_c('KeyValue',{attrs:{"items":{
      'Personnummer': `${_vm.patient.ssn} (${_vm.ssnToAge(_vm.patient.ssn)} år)`,
      'E-mail': _vm.patient.email,
      'Tel': _vm.patient.tel
    }}}),_c('div',{staticClass:"clearfix mb-2"},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'PatientCreateInvestigation', params: { id: _vm.id } }}},[_c('b-icon',{attrs:{"icon":"plus","scale":"1.5","shift-v":"1"}}),_vm._v(" Skapa ny utredning ")],1)],1),_c('div',{})]),(_vm.patient.investigations.length > 0)?_c('h2',[_vm._v("Utredningar ("+_vm._s(_vm.patient.investigations.length)+" st)")]):_c('h2',[_vm._v("Inga påbörjade utredningar")]),_vm._l((_vm.patient.investigations),function(investigation){return _c('b-card',{key:investigation.id + '',staticClass:"mt-4"},[_c('div',{staticClass:"clearfix"},[_c('h5',[_vm._v(" "+_vm._s(investigation.name)+" "),_c('div',{staticClass:"mb-1 float-right"},[_c('b-button',{attrs:{"variant":"link","to":{ name: 'PatientEditInvestigation', params: { patient_id: _vm.id, investigation_id: investigation.id } }}},[_c('b-icon-pencil')],1),_c('b-button',{staticClass:"text-danger",attrs:{"variant":"link"},on:{"click":function($event){return _vm.confirmDeleteInvestigation(investigation.id)}}},[_c('b-icon-trash')],1)],1)]),_c('p',[_vm._v(_vm._s(investigation.reason))])]),_c('KeyValue',{attrs:{"even":"","items":{
        'Skapades': _vm.prettyDate(investigation.startdate, true),
        'Mötesdag': _vm.prettyDate(investigation.meetdate, true),
        'Sjukdag': _vm.prettyDate(investigation.sickday, true, false),
        'Utredning': _vm.getTypeText(investigation.type)
      }}}),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{staticClass:"mt-2",attrs:{"size":"lg","variant":"primary","to":{name: 'Overview', params: { id: investigation.id } }}},[_vm._v(" Öppna översikt ")])],1),_c('b-col',{attrs:{"cols":"8"}},[_c('dl',{staticClass:"patientlink"},[_c('dt',[_vm._v("Patientens länk")]),_c('dd',[(_vm.hasClipboardSupport)?_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){_vm.copyToClipboard(_vm.link(investigation.id))}}},[_c('b-icon-link45deg'),_vm._v(" Kopiera länk ")],1):_vm._e(),_c('small',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.link(investigation.id))+" ")])],1)])])],1)],1)})],2):_c('div',[_c('h1',[_vm._v("Ingen patient funnen")]),_c('p',[_vm._v("Det fanns ingen patient i systemet med id: "+_vm._s(_vm.id))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }