/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatientUpdate
 */
export interface PatientUpdate {
    /**
     *
     * @type {string}
     * @memberof PatientUpdate
     */
    tel: string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof PatientUpdate
     */
    name: string;
}
/**
 * Check if a given object implements the PatientUpdate interface.
 */
export function instanceOfPatientUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tel" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function PatientUpdateFromJSON(json: any): PatientUpdate {
    return PatientUpdateFromJSONTyped(json, false);
}
export function PatientUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'tel': json['tel'],
        'email': json['email'],
        'name': json['name'],
    };
}
export function PatientUpdateToJSON(value?: PatientUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'tel': value.tel,
        'email': value.email,
        'name': value.name,
    };
}