
import { defineComponent, PropType } from 'vue'
import { countBy } from 'lodash-es'

import { Cluster, ClusterData } from '../api/index'

export default defineComponent({
  name: 'ClusterReviewBadges',
  props: {
    data: { type: Object as PropType<ClusterData>, required: true },
    cluster: { type: Object as PropType<Cluster>, required: true }
  },
  setup () {
    function summary (data: ClusterData, cluster: Cluster, answerKey: string): Array<{ title: string, coloring?: string, count: number}> {
      const answer = cluster.answers.find(a => a.key === answerKey)

      const clusterData = Object.entries(data[cluster.key])
        .map(([key, value]) => ({ key, value: value[answerKey] }))
        .filter(({ value }) => value > 0)

      const out = countBy(clusterData, d => d.value)

      return Object.entries(out).map(([value, count]) => {
        const c = answer?.options?.find(o => String(o.value) === String(value))
        return { title: c?.title ?? value, coloring: c?.coloring, count }
      })
    }

    return { summary }
  }
})
