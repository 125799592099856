
import { prettyDate } from '@/lib/date'
import { useInvestigationOptions } from '@/use/investigation'
import { computed, defineComponent, PropType } from 'vue'
import { Investigation } from '../api/index'

interface Item {
  value: string | number,
  key: string,
  label: string,
  size?: 'auto' | number
}

export default defineComponent({
  name: 'InvestigationHeader',
  props: {
    investigation: { type: Object as PropType<Investigation>, required: true }
  },
  setup (props) {
    const { getTypeText } = useInvestigationOptions()
    return {
      items: computed<Item[]>(() => {
        return [
          { value: props.investigation.name, label: 'Utredning', key: 'name', size: 3 },
          { value: getTypeText(props.investigation.type) ?? props.investigation.type, label: 'Typ', key: 'type', size: 3 },
          { value: prettyDate(props.investigation.sickday, true, false), label: 'Sjukdag', key: 'sickday', size: 2 },
          { value: props.investigation.reason, label: 'Anledning', key: 'reason', size: 'auto' }
        ]
      })
    }
  }
})
