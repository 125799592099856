
import { defineComponent } from 'vue'
import Links from '@/use/links'

export default defineComponent({
  name: 'DiagnoseLinks',
  props: {},
  setup () {
    return { links: Links.general }
  }
})
