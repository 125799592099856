
import { optionValueTitle } from '@/lib/form-answer'
import { computed, defineComponent, PropType } from 'vue'
import { Answer, AnswerDataValue, Dimension } from '../../api/index'

export default defineComponent({
  name: 'AnswerBoxes',
  props: {
    dimension: { type: Object as PropType<Dimension>, required: false },
    value: { type: Object as PropType<AnswerDataValue>, required: false },
    answer: { type: Object as PropType<Answer>, required: true },
    editable: { type: Boolean, default: () => false }
  },
  setup (props) {
    return {
      valueText: computed(() => optionValueTitle(props.answer, props.value)),
      isValue: computed(() => typeof props.value === 'string' || typeof props.value === 'number')
    }
  }
})
