
import { defineComponent, PropType } from 'vue'

import ClusterIngressProgressBar from '@/components/ClusterIngressProgressBar.vue'
import { ClusterIngress } from '../api/index'

export default defineComponent({
  name: 'ClusterIngress',
  components: { ClusterIngressProgressBar },
  props: {
    ingress: { type: Object as PropType<ClusterIngress>, required: true },
    progress: { type: Object as PropType<{current: number, max: number}>, required: true },
    showBack: Boolean
  }
})
