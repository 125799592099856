/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    grandidSessionId: string;
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    birthdate: string;
    /**
     *
     * @type {string}
     * @memberof Identity
     */
    ssn: string;
}
/**
 * Check if a given object implements the Identity interface.
 */
export function instanceOfIdentity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grandidSessionId" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "surname" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "birthdate" in value;
    isInstance = isInstance && "ssn" in value;
    return isInstance;
}
export function IdentityFromJSON(json: any): Identity {
    return IdentityFromJSONTyped(json, false);
}
export function IdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Identity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'grandidSessionId': json['grandidSessionId'],
        'givenName': json['givenName'],
        'surname': json['surname'],
        'name': json['name'],
        'birthdate': json['birthdate'],
        'ssn': json['ssn'],
    };
}
export function IdentityToJSON(value?: Identity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'grandidSessionId': value.grandidSessionId,
        'givenName': value.givenName,
        'surname': value.surname,
        'name': value.name,
        'birthdate': value.birthdate,
        'ssn': value.ssn,
    };
}