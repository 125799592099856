/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  Consent,
  ConsentRequest,
  ConsentResponse,
  HttpError,
} from '../models';
import {
    ConsentFromJSON,
    ConsentToJSON,
    ConsentRequestFromJSON,
    ConsentRequestToJSON,
    ConsentResponseFromJSON,
    ConsentResponseToJSON,
    HttpErrorFromJSON,
    HttpErrorToJSON,
} from '../models';
export interface ConsentApiPrefillRequest {
    consentRequest: ConsentRequest;
}
export interface ConsentApiSearchRequest {
    investigationId: string;
    version?: string;
}
/**
 *
 */
export class ConsentApi extends runtime.BaseAPI {
    /**
     */
    async prefillRaw(requestParameters: ConsentApiPrefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentResponse>> {
        if (requestParameters.consentRequest === null || requestParameters.consentRequest === undefined) {
            throw new runtime.RequiredError('consentRequest','Required parameter requestParameters.consentRequest was null or undefined when calling prefill.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/consent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsentRequestToJSON(requestParameters.consentRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentResponseFromJSON(jsonValue));
    }
    /**
     */
    async prefill(requestParameters: ConsentApiPrefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentResponse> {
        const response = await this.prefillRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async searchRaw(requestParameters: ConsentApiSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consent>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling search.');
        }
        const queryParameters: any = {};
        if (requestParameters.investigationId !== undefined) {
            queryParameters['investigationId'] = requestParameters.investigationId;
        }
        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/consent/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentFromJSON(jsonValue));
    }
    /**
     */
    async search(requestParameters: ConsentApiSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consent> {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }
}