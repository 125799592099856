/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoginStatusSuccessOrganisation
 */
export interface LoginStatusSuccessOrganisation {
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccessOrganisation
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof LoginStatusSuccessOrganisation
     */
    name: string;
}
/**
 * Check if a given object implements the LoginStatusSuccessOrganisation interface.
 */
export function instanceOfLoginStatusSuccessOrganisation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function LoginStatusSuccessOrganisationFromJSON(json: any): LoginStatusSuccessOrganisation {
    return LoginStatusSuccessOrganisationFromJSONTyped(json, false);
}
export function LoginStatusSuccessOrganisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginStatusSuccessOrganisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'id': json['id'],
        'name': json['name'],
    };
}
export function LoginStatusSuccessOrganisationToJSON(value?: LoginStatusSuccessOrganisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'id': value.id,
        'name': value.name,
    };
}