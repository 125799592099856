

import { getText, Selectable } from '@/lib/select'
import { useOptions } from '@/use/personal-form'
import { computed, defineComponent, PropType } from 'vue'
import { PersonalData, PersonalDataMedicaldiagnoseItemsInner as DiagnoseItems } from '../api/index'

export default defineComponent({
  name: 'PersonalQuestionsReview',
  props: {
    data: Object as PropType<PersonalData>
  },
  setup (props) {
    const { options, labels } = useOptions()
    interface ListItem {
      key: string,
      text: string | number | undefined,
      value: string
    }
    const list = computed<ListItem[]>(() => {
      const simple = Object.entries(props.data ?? {}).map(([k, v]) => {
        if (k === 'medicaldiagnose') {
          return {
            key: k,
            text: labels.medicaldiagnose,
            value: v.items.map(({ code, text }: DiagnoseItems) => `• ${code} － ${text}`)
          }
        } else if (k in (options ?? {})) {
          const selectes: Selectable[] = options[k as Exclude<keyof typeof options, 'medicaldiagnose'>]
          return { key: k, text: labels[k as keyof typeof labels], value: getText(selectes, v) }
        } else if (k in labels) {
          return { key: k, text: labels[k as keyof typeof labels], value: v }
        } else {
          return { key: k, text: k, value: v }
        }
      })

      return simple
    })

    return { options, list }
  }
})
