/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  HttpError,
  OpenInvestigationCreate,
  OpenInvestigationCreated,
} from '../models';
import {
    HttpErrorFromJSON,
    HttpErrorToJSON,
    OpenInvestigationCreateFromJSON,
    OpenInvestigationCreateToJSON,
    OpenInvestigationCreatedFromJSON,
    OpenInvestigationCreatedToJSON,
} from '../models';
export interface OpenSubmitApiCreateRequest {
    openInvestigationCreate: OpenInvestigationCreate;
}
/**
 *
 */
export class OpenSubmitApi extends runtime.BaseAPI {
    /**
     */
    async createRaw(requestParameters: OpenSubmitApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenInvestigationCreated>> {
        if (requestParameters.openInvestigationCreate === null || requestParameters.openInvestigationCreate === undefined) {
            throw new runtime.RequiredError('openInvestigationCreate','Required parameter requestParameters.openInvestigationCreate was null or undefined when calling create.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/v1/open-submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpenInvestigationCreateToJSON(requestParameters.openInvestigationCreate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OpenInvestigationCreatedFromJSON(jsonValue));
    }
    /**
     */
    async create(requestParameters: OpenSubmitApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenInvestigationCreated> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }
}