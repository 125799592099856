
import { ChartData, ChartOptions } from 'chart.js'
import { Radar, mixins } from 'vue-chartjs'
import { BaseChart } from 'vue-chartjs/types/components'

const { reactiveProp } = mixins

export default {
  extends: Radar,
  name: 'RadarChart',
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mixins: [reactiveProp],
  mounted () {
    const self = this as unknown as (BaseChart & { chartData: ChartData, options: ChartOptions })
    self.renderChart(self.chartData, self.options)
  }
}
