
import { defineComponent, reactive } from 'vue'
import { Api } from '@/lib/di/api'
import { BackendError, errorFromResponse } from '@/use/loadable'

export default defineComponent({
  name: 'LoginForm',
  setup (props, context) {
    const api = Api.identlogin()

    const form = reactive({
      username: '',
      password: '',
      valid: null as boolean | null,
      error: {
        state: null as boolean | null,
        cause: null as Record<string, any> | BackendError | null
      }
    })

    return {
      form,
      submit: async () => {
        form.error.state = false
        form.error.cause = null
        const { username, password } = form
        const { token } = await api.token()
        try {
          const value = await api.doLogin({ loginForm: { username, password, token } })
          form.valid = true
          context.emit('success', value)
        } catch (e) {
          const error = await errorFromResponse(e)
          if (error.status === 401) {
            form.valid = false
            form.error.state = false
            form.error.cause = null
          } else {
            console.error('Errorous response: ', error)
            form.error.state = true
            form.error.cause = error
          }
        }
      }
    }
  }
})
