const general = [
  { href: 'https://roi.socialstyrelsen.se/fmb', text: 'Försäkringsmedicinskt beslutsstöd' },
  { href: 'https://lakemedelsboken.se/', text: 'Läkemedelsboken' },
  { href: 'http://www.fyss.se/fyss-kapitel/fyss-kapitel-del-2-diagnosspecifik-del/', text: 'FYSS' },
  { href: 'https://psykiatristod.se/regionala-vardprogram', text: 'Psykiatristöd' }
]
const links = [
  { link: 'https://roi.socialstyrelsen.se/fmb/akut-bronkit/1', name: 'Akut bronkit', codes: ['J20'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/allergisk-rinit/6', name: 'Allergisk rinit', codes: ['J30'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/astma/8', name: 'Astma', codes: ['J45', 'J46'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/influensa/10', name: 'Influensa', codes: ['J09', 'J10', 'J11'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/kronisk-bronkit/15', name: 'Kronisk bronkit', codes: ['J40', 'J41', 'J42'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/kroniskt-obstruktiv-lungsjukdom-kol/13', name: 'Kroniskt obstruktiv lungsjukdom (KOL)', codes: ['J44'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/pneumoni/17', name: 'Pneumoni', codes: ['J12', 'J13', 'J14', 'J15', 'J16', 'J17', 'J18'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/sinuit-kronisk-sinuit/20', name: 'Sinuit, kronisk sinuit', codes: ['J01', 'J32'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/tonsillit-faryngit-peritonsillit/23', name: 'Tonsillit, faryngit, peritonsillit', codes: ['J02', 'J03', 'J36'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ovre-luftvagsinfektion-oli/28', name: 'Övre luftvägsinfektion (ÖLI)', codes: ['J00', 'J06'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/diabetes-mellitus-typ-1/32', name: 'Diabetes mellitus typ 1', codes: ['E10', 'O240'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/diabetes-mellitus-typ-2/37', name: 'Diabetes mellitus typ 2', codes: ['E11', 'E14', 'O241'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hypotyreos-lag-amnesomsattning/42', name: 'Hypotyreos (låg ämnesomsättning)', codes: ['E03'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/obesitaskirurgi-vid-fetma/45', name: 'Obesitaskirurgi vid fetma', codes: ['E66'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/tyreotoxikos-giftstruma/50', name: 'Tyreotoxikos (giftstruma)', codes: ['E05'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/forvarkar-och-hotande-fortidsbord/406', name: 'Förvärkar och hotande förtidsbörd', codes: ['O47'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/graviditetsillamaende-graviditetskrakningar/397', name: 'Graviditetsillamående, graviditetskräkningar', codes: ['O210', 'O212', 'O218', 'O219', 'O211'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/graviditetsrelaterad-rygg--och-backensmarta-inklusive-symfyseolys/401', name: 'Graviditetsrelaterad rygg- och bäckensmärta (inklusive symfyseolys)', codes: ['O267'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/cerebrovaskulara-sjukdomar/370', name: 'Cerebrovaskulära sjukdomar', codes: ['I60', 'I61', 'I62', 'I63', 'I64', 'I65', 'I66', 'I67', 'I69'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hjartrytmrubbningar-formaksflimmer/374', name: 'Hjärtrytmrubbningar, förmaksflimmer', codes: ['I47', 'I48', 'I49'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hjartsvikt/378', name: 'Hjärtsvikt', codes: ['I50'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hypertoni/384', name: 'Hypertoni', codes: ['I10', 'I11', 'I12', 'I13', 'I15'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ischemisk-kranskarlsjukdom/386', name: 'Ischemisk kranskärlsjukdom', codes: ['I20', 'I21', 'I22', 'I23', 'I24', 'I25'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/perifera-karlsjukdomar/326', name: 'Perifera kärlsjukdomar', codes: ['I74', 'I80'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/aderbrack/391', name: 'Åderbråck', codes: ['I83'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/eksemsjukdomar/686', name: 'Eksemsjukdomar', codes: ['L23', 'L24', 'L25', 'L26', 'L27', 'L28', 'L29', 'L30'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/psoriasis-och-pustulosis-palmoplantaris-ppp/692', name: 'Psoriasis och pustulosis palmoplantaris (ppp)', codes: ['L40'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/urtikaria/698', name: 'Urtikaria', codes: ['L50'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/borreliainfektion/66', name: 'Borreliainfektion', codes: ['A692'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/covid-19-efter-intensivvard-med-respiratorbehandling/641', name: 'Covid-19 efter intensivvård med respiratorbehandling', codes: ['U071'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hiv-aids/69', name: 'HIV/AIDS', codes: ['B24'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/herpesinfektioner/72', name: 'Herpesinfektioner', codes: ['A60', 'B00', 'B02'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/kortelfeber/79', name: 'Körtelfeber', codes: ['B27'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/salmonellos-och-andra-bakteriella-tarminfektioner/76', name: 'Salmonellos och andra bakteriella tarminfektioner', codes: ['A02', 'A04'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/virushepatiter/82', name: 'Virushepatiter', codes: ['B15', 'B16', 'B17', 'B18', 'B19'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/akut-blindtarmsinflammation-opererad-med-buksnitt-eller-laparoskopiskt/85', name: 'Akut blindtarmsinflammation (opererad med buksnitt eller laparoskopiskt)', codes: ['K35'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/gallstenssjukdom-opererad-med-buksnitt-eller-laparoskopiskt/94', name: 'Gallstenssjukdom (opererad med buksnitt eller laparoskopiskt)', codes: ['K80'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/gastroesofageal-refluxsjukdom-gerd/99', name: 'Gastroesofageal refluxsjukdom (GERD)', codes: ['K21'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/irritabel-tarm-ibs/103', name: 'Irritabel tarm (IBS)', codes: ['K58'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/leversjukdomar-gallvags--och-pankreassjukdomar/105', name: 'Leversjukdomar, gallvägs- och pankreassjukdomar', codes: ['K70', 'K71', 'K72', 'K73', 'K74', 'K75', 'K81', 'K82', 'K83', 'K85', 'K86'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ljumskbrack-femoralbrack-och-navelbrack-opererats-med-oppen-kirurgi-eller-laparoskopiskt/110', name: 'Ljumskbråck, femoralbråck och navelbråck (opererats med öppen kirurgi eller laparoskopiskt)', codes: ['K40', 'K41', 'K42'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ulceros-kolit-crohns-sjukdom-och-icke-infektios-tarminflammation/115', name: 'Ulcerös kolit, Crohns sjukdom och icke-infektiös tarminflammation', codes: ['K50', 'K51', 'K52'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ulcus-gastrit-och-dyspepsi/90', name: 'Ulcus, gastrit och dyspepsi', codes: ['K25', 'K26', 'K27', 'K28', 'K29', 'K30'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/epilepsi/625', name: 'Epilepsi', codes: ['G40'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/huvudvark-av-spanningstyp/123', name: 'Huvudvärk av spänningstyp', codes: ['G442'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hypersomni-av-centralnervos-genes/137', name: 'Hypersomni av centralnervös genes', codes: ['G471', 'G472', 'G474', 'G478'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/insomni/139', name: 'Insomni', codes: ['G470'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/migran/126', name: 'Migrän', codes: ['G43'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/multipel-skleros/128', name: 'Multipel skleros', codes: ['G35'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/obstruktiv-somnapne-osa/145', name: 'Obstruktiv sömnapné (OSA)', codes: ['G473'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/parkinsons-sjukdom/133', name: 'Parkinsons sjukdom', codes: ['G20'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/adhd-aktivitets--och-uppmarksamhetsstorning/493', name: 'ADHD (Aktivitets- och uppmärksamhetsstörning)', codes: ['F900'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/akut-stressreaktion/498', name: 'Akut stressreaktion', codes: ['F430'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/anorexia-nervosa/549', name: 'Anorexia nervosa', codes: ['F500', 'F501'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/anpassningsstorning-livskris-sorgreaktion/501', name: 'Anpassningsstörning (livskris, sorgreaktion)', codes: ['F432'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/bipolar-sjukdom/504', name: 'Bipolär sjukdom', codes: ['F31'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/bulimia-nervosa/554', name: 'Bulimia nervosa', codes: ['F502', 'F503'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/depressiv-episod/508', name: 'Depressiv episod', codes: ['F32'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/generaliserat-angestsyndrom/512', name: 'Generaliserat ångestsyndrom', codes: ['F411'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/paniksyndrom/516', name: 'Paniksyndrom', codes: ['F410'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/posttraumatiskt-stressyndrom/520', name: 'Posttraumatiskt stressyndrom', codes: ['F431'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/psykotiska-syndrom/525', name: 'Psykotiska syndrom', codes: ['F20', 'F21', 'F22', 'F23', 'F24', 'F25', 'F28', 'F29', 'F200', 'F201', 'F202', 'F203', 'F204', 'F205', 'F206', 'F208', 'F209', 'F219', 'F220', 'F228', 'F229', 'F230', 'F231', 'F232', 'F233', 'F238', 'F239', 'F249', 'F250', 'F251', 'F252', 'F258', 'F259', 'F289', 'F299'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/recidiverande-depression/528', name: 'Recidiverande depression', codes: ['F33'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/skadligt-bruk-av-alkohol-och-alkoholberoende/532', name: 'Skadligt bruk av alkohol och alkoholberoende', codes: ['F10'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/skadligt-bruk-av-droger-drogberoende/558', name: 'Skadligt bruk av droger, drogberoende', codes: ['F11', 'F12', 'F13', 'F14', 'F15', 'F16', 'F18', 'F19'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/social-fobi/538', name: 'Social fobi', codes: ['F401'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/tvangssyndrom/541', name: 'Tvångssyndrom', codes: ['F42'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/utmattningssyndrom/546', name: 'Utmattningssyndrom', codes: ['F438'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/akillestendinopati/254', name: 'Akillestendinopati', codes: ['M766'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/akut-lumbago/259', name: 'Akut lumbago', codes: ['M545'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/ankyloserande-spondylit-bechterews-sjukdom-axial-spondylartrit-inflammatorisk-ryggsjukdom/644', name: 'Ankyloserande spondylit (Bechterews sjukdom) – (axial spondylartrit (inflammatorisk ryggsjukdom))', codes: ['M45', 'M459'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/cervikal-rizopati-cervikalt-diskbrack/263', name: 'Cervikal rizopati, cervikalt diskbråck', codes: ['M50'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/epikondylalgi-epikondylit/268', name: 'Epikondylalgi, epikondylit', codes: ['M77'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/gikt/274', name: 'Gikt', codes: ['M10'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hoftartros/649', name: 'Höftartros', codes: ['M16'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/karpaltunnelsyndrom/309', name: 'Karpaltunnelsyndrom', codes: ['G560'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/knaartros/661', name: 'Knäartros', codes: ['M17'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/lumbago-ischias-diskbrack/317', name: 'Lumbago-ischias, diskbråck', codes: ['M544', 'M51'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/langvarig-smarta-i-rorelseorganen-inkl-fibromyalgi/271', name: 'Långvarig smärta i rörelseorganen inkl. fibromyalgi', codes: ['M79', 'R52'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/psoriasisartrit/673', name: 'Psoriasisartrit', codes: ['M07'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/reaktiv-artrit/336', name: 'Reaktiv artrit', codes: ['M02', 'M03'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/reumatoid-artrit-ra/679', name: 'Reumatoid artrit (RA)', codes: ['M05', 'M06'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/skulder--och-axelledsbesvar/339', name: 'Skulder- och axelledsbesvär', codes: ['M754', 'M753', 'M750', 'M751', 'M659B'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/systemisk-lupus-erythematosus-sle/366', name: 'Systemisk lupus erythematosus (SLE)', codes: ['M32'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/vissa-fotbesvar/244', name: 'Vissa fotbesvär', codes: ['M201', 'M202', 'M204', 'M722', 'G576'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/vissa-hand--och-handledsbesvar/276', name: 'Vissa hand- och handledsbesvär', codes: ['M18', 'G562', 'M653', 'M654', 'M700', 'M720'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/endometrios/589', name: 'Endometrios', codes: ['N80'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hysterektomi-vid-adenomatos-hyperplasi-av-endometriet/610', name: 'Hysterektomi vid adenomatös hyperplasi av endometriet', codes: ['N851'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hysterektomi-vid-dysplasi-i-cervix-uteri/615', name: 'Hysterektomi vid dysplasi i cervix uteri', codes: ['N87'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hysterektomi-vid-glandularcystisk-hyperplasi-av-endometriet/605', name: 'Hysterektomi vid glandulärcystisk hyperplasi av endometriet', codes: ['N850'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hysterektomi-vid-riklig-frekvent-och-oregelbunden-menstruation/620', name: 'Hysterektomi vid riklig, frekvent och oregelbunden menstruation', codes: ['N92'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/pyelonefrit-njursten/156', name: 'Pyelonefrit, njursten', codes: ['N10', 'N11', 'N12', 'N20', 'N21'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/akillesruptur-gastrocnemius-skada/220', name: 'Akillesruptur, gastrocnemius-skada', codes: ['S860', 'S861'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/distal-radiusfraktur/184', name: 'Distal radiusfraktur', codes: ['S525'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/distorsion-i-halskotpelaren-inklusive-whiplash-skada/204', name: 'Distorsion i halskotpelaren (inklusive Whiplash-skada)', codes: ['S134'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/frakturer-och-luxationer-i-ryggraden/188', name: 'Frakturer och luxationer i ryggraden', codes: ['S12', 'S130', 'S131', 'S132', 'S133', 'S220', 'S221', 'S230', 'S231', 'S232', 'S330', 'S331', 'S332'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/skada-pa-ovre-extremiteten-sarskada-luxation-distorsion-och-fraktur/198', name: 'Skada på övre extremiteten (sårskada, luxation, distorsion och fraktur)', codes: ['S40', 'S50', 'S60', 'S53', 'S63', 'S434', 'S42', 'S52', 'S62', 'S430'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/skador-pa-nedre-extremiteten-frakturer-luxationer-distorsioner-sarskador/223', name: 'Skador på nedre extremiteten (frakturer, luxationer, distorsioner, sårskador)', codes: ['S30', 'S70', 'S80', 'S90', 'S73', 'S83', 'S93', 'S32', 'S72', 'S82', 'S92'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/sarskada-pa-hand-handled-och-underarm/207', name: 'Sårskada på hand, handled och underarm', codes: ['S51', 'S61'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/traumatisk-hjarnskada-inklusive-hjarnskakning/216', name: 'Traumatisk hjärnskada (inklusive hjärnskakning)', codes: ['S06'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/vissa-knaledsskador/233', name: 'Vissa knäledsskador', codes: ['S832', 'S835', 'S836', 'M232'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/blascancer/443', name: 'Blåscancer', codes: ['C67'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/brostcancer/632', name: 'Bröstcancer', codes: ['C50', 'D05', 'Z853'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/endometriecancer/432', name: 'Endometriecancer', codes: ['C54'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hudmelanom-icke-spridd-eller-med-regional-lymfkortelspridning/438', name: 'Hudmelanom, icke spridd eller med regional lymfkörtelspridning', codes: ['C43'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/hysterektomi-vid-uterusmyom/600', name: 'Hysterektomi vid uterusmyom', codes: ['D25'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/koloncancer/419', name: 'Koloncancer', codes: ['C18', 'C19'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/lungcancer/449', name: 'Lungcancer', codes: ['C34'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/metastaserad-cancer/491', name: 'Metastaserad cancer', codes: ['C77', 'C78', 'C79'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/prostatacancer/424', name: 'Prostatacancer', codes: ['C61'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/rektalcancer/428', name: 'Rektalcancer', codes: ['C20', 'C21'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/inflammation-i-regnbagshinna-aderhinna-och-nathinna/160', name: 'Inflammation i regnbågshinna, åderhinna och näthinna', codes: ['H20', 'H30'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/katarakt/163', name: 'Katarakt', codes: ['H25', 'H26', 'H28'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/nathinneavlossning/168', name: 'Näthinneavlossning', codes: ['H33'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/horselnedsattning/171', name: 'Hörselnedsättning', codes: ['H90', 'H91'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/menieres-sjukdom/174', name: 'Ménières sjukdom', codes: ['H810'] },
  { link: 'https://roi.socialstyrelsen.se/fmb/tinnitus-hyperakusi/178', name: 'Tinnitus, hyperakusi', codes: ['H931', 'H932'] }
]

function suggest (_code: string) {
  if (!_code || !_code.match(/[A-Z]\d{2,}.*/)) return []

  const code = _code.replace(/\..*$/, '') // Remove suffix

  const suggestion = links.find(f => f.codes.some(c => c.includes(code) || code.includes(c)))
  if (suggestion && code.match(/[A-Z]\d+.*/)) {
    return [{ href: suggestion.link, text: '' + suggestion.codes.join(', ') + ': ' + suggestion.name }]
  }
  return []
}

export default {
  general,
  suggest
}
