import { inject, InjectionKey, provide, reactive, toRefs, UnwrapRef } from 'vue'

export const ConfigComponent: InjectionKey<UnwrapRef<ConfigType>> = Symbol('config')

export interface ConfigType {
  env: string;
  debug: boolean;
}

export class Config {
  static init () {
    const { VUE_APP_ENV: env } = process.env

    const config = reactive<ConfigType>({
      debug: env === 'development',
      env: env ?? 'production'
    })

    provide(ConfigComponent, config)

    return { config }
  }

  static get () {
    const config = inject(ConfigComponent, reactive<ConfigType>({} as ConfigType))
    return { ...toRefs(config) }
  }
}
