import Vue from 'vue'
import VueMeta from 'vue-meta'

import './app.scss'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import App from './App.vue'
import Router from './router'

import Debugger from '@/components/common/Debugger.vue'
import CenteredLoading from '@/components/common/CenteredLoading.vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMeta)

Vue.component('Debugger', Debugger)
Vue.component('CenteredLoading', CenteredLoading)

Vue.config.productionTip = false

new Vue({
  router: Router,
  render: h => h(App)
}).$mount('#app')
