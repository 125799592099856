/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * Verify Open Identity Result
 * @export
 * @interface VerifyOpenIdentityResult
 */
export interface VerifyOpenIdentityResult {
    /**
     *
     * @type {string}
     * @memberof VerifyOpenIdentityResult
     */
    grandidsession: string;
    /**
     *
     * @type {string}
     * @memberof VerifyOpenIdentityResult
     */
    organisation: string;
}
/**
 * Check if a given object implements the VerifyOpenIdentityResult interface.
 */
export function instanceOfVerifyOpenIdentityResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grandidsession" in value;
    isInstance = isInstance && "organisation" in value;
    return isInstance;
}
export function VerifyOpenIdentityResultFromJSON(json: any): VerifyOpenIdentityResult {
    return VerifyOpenIdentityResultFromJSONTyped(json, false);
}
export function VerifyOpenIdentityResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyOpenIdentityResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'grandidsession': json['grandidsession'],
        'organisation': json['organisation'],
    };
}
export function VerifyOpenIdentityResultToJSON(value?: VerifyOpenIdentityResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'grandidsession': value.grandidsession,
        'organisation': value.organisation,
    };
}