/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { VerifyIdentityFormSubject } from './VerifyIdentityFormSubject';
import {
    VerifyIdentityFormSubjectFromJSON,
    VerifyIdentityFormSubjectFromJSONTyped,
    VerifyIdentityFormSubjectToJSON,
} from './VerifyIdentityFormSubject';
/**
 * Verify Identity Form
 * @export
 * @interface VerifyIdentityForm
 */
export interface VerifyIdentityForm {
    /**
     *
     * @type {string}
     * @memberof VerifyIdentityForm
     */
    callbackUrl: string;
    /**
     *
     * @type {string}
     * @memberof VerifyIdentityForm
     */
    backUrl: string;
    /**
     *
     * @type {VerifyIdentityFormSubject}
     * @memberof VerifyIdentityForm
     */
    subject: VerifyIdentityFormSubject;
}
/**
 * Check if a given object implements the VerifyIdentityForm interface.
 */
export function instanceOfVerifyIdentityForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "callbackUrl" in value;
    isInstance = isInstance && "backUrl" in value;
    isInstance = isInstance && "subject" in value;
    return isInstance;
}
export function VerifyIdentityFormFromJSON(json: any): VerifyIdentityForm {
    return VerifyIdentityFormFromJSONTyped(json, false);
}
export function VerifyIdentityFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyIdentityForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'callbackUrl': json['callbackUrl'],
        'backUrl': json['backUrl'],
        'subject': VerifyIdentityFormSubjectFromJSON(json['subject']),
    };
}
export function VerifyIdentityFormToJSON(value?: VerifyIdentityForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'callbackUrl': value.callbackUrl,
        'backUrl': value.backUrl,
        'subject': VerifyIdentityFormSubjectToJSON(value.subject),
    };
}