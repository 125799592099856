/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ActionPlanDataTreatmentsInner
 */
export interface ActionPlanDataTreatmentsInner {
    /**
     *
     * @type {string}
     * @memberof ActionPlanDataTreatmentsInner
     */
    comment: string | null;
    /**
     *
     * @type {string}
     * @memberof ActionPlanDataTreatmentsInner
     */
    code: string | null;
    /**
     *
     * @type {string}
     * @memberof ActionPlanDataTreatmentsInner
     */
    profession: string | null;
}
/**
 * Check if a given object implements the ActionPlanDataTreatmentsInner interface.
 */
export function instanceOfActionPlanDataTreatmentsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "profession" in value;
    return isInstance;
}
export function ActionPlanDataTreatmentsInnerFromJSON(json: any): ActionPlanDataTreatmentsInner {
    return ActionPlanDataTreatmentsInnerFromJSONTyped(json, false);
}
export function ActionPlanDataTreatmentsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionPlanDataTreatmentsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'comment': json['comment'],
        'code': json['code'],
        'profession': json['profession'],
    };
}
export function ActionPlanDataTreatmentsInnerToJSON(value?: ActionPlanDataTreatmentsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'comment': value.comment,
        'code': value.code,
        'profession': value.profession,
    };
}