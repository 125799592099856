/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { JoiValidationErrorItemContext } from './JoiValidationErrorItemContext';
import {
    JoiValidationErrorItemContextFromJSON,
    JoiValidationErrorItemContextFromJSONTyped,
    JoiValidationErrorItemContextToJSON,
} from './JoiValidationErrorItemContext';
import type { JoiValidationErrorItemPath } from './JoiValidationErrorItemPath';
import {
    JoiValidationErrorItemPathFromJSON,
    JoiValidationErrorItemPathFromJSONTyped,
    JoiValidationErrorItemPathToJSON,
} from './JoiValidationErrorItemPath';
/**
 *
 * @export
 * @interface JoiValidationErrorItem
 */
export interface JoiValidationErrorItem {
    /**
     *
     * @type {string}
     * @memberof JoiValidationErrorItem
     */
    message: string;
    /**
     *
     * @type {Array<JoiValidationErrorItemPath>}
     * @memberof JoiValidationErrorItem
     */
    path: Array<JoiValidationErrorItemPath>;
    /**
     *
     * @type {string}
     * @memberof JoiValidationErrorItem
     */
    type: string;
    /**
     *
     * @type {JoiValidationErrorItemContext}
     * @memberof JoiValidationErrorItem
     */
    context?: JoiValidationErrorItemContext;
}
/**
 * Check if a given object implements the JoiValidationErrorItem interface.
 */
export function instanceOfJoiValidationErrorItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function JoiValidationErrorItemFromJSON(json: any): JoiValidationErrorItem {
    return JoiValidationErrorItemFromJSONTyped(json, false);
}
export function JoiValidationErrorItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoiValidationErrorItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'message': json['message'],
        'path': ((json['path'] as Array<any>).map(JoiValidationErrorItemPathFromJSON)),
        'type': json['type'],
        'context': !exists(json, 'context') ? undefined : JoiValidationErrorItemContextFromJSON(json['context']),
    };
}
export function JoiValidationErrorItemToJSON(value?: JoiValidationErrorItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'message': value.message,
        'path': ((value.path as Array<any>).map(JoiValidationErrorItemPathToJSON)),
        'type': value.type,
        'context': JoiValidationErrorItemContextToJSON(value.context),
    };
}