
import { useGlobalLogin } from '@/use/login'
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
  name: 'Thanks',
  setup () {
    const { logout } = useGlobalLogin()

    onMounted(async () => await logout())

    return { }
  },
  metaInfo: {
    title: 'Tack'
  }
})
