/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 * ICF dimensions (codes)
 * @export
 * @interface Dimension
 */
export interface Dimension {
    /**
     *
     * @type {string}
     * @memberof Dimension
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof Dimension
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof Dimension
     */
    description?: string;
}
/**
 * Check if a given object implements the Dimension interface.
 */
export function instanceOfDimension(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function DimensionFromJSON(json: any): Dimension {
    return DimensionFromJSONTyped(json, false);
}
export function DimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dimension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'code': json['code'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function DimensionToJSON(value?: Dimension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'code': value.code,
        'title': value.title,
        'description': value.description,
    };
}