
import { defineComponent, computed, onMounted, reactive } from 'vue'
import { Api } from '@/lib/di/api'
import { errorFromResponse } from '@/use/loadable'
import { useGlobalLogin } from '@/use/login'
import { computedParam, computedQuery } from '@/use/route'
import { z } from 'zod'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'Verify',
  setup () {

    const id = computedParam('id')
    const state = reactive({
      complete: false,
      error: null as 'invalid_ssn' | 'incorrect_ssn' | 'timeout' | 'unknown' | null
    })
    const { reload, identity, prettyName } = useGlobalLogin()

    const loadingText = computed(() => {
      if (state.complete) {
        return prettyName.value ? 'Välkommen ' + prettyName.value : 'Slutför verifiering'
      } else {
        return 'Startar BankID verifiering'
      }
    })

    const grandidsession = computedQuery('grandidsession', z.string().optional())

    const api = Api.identlogin()

    onMounted(async () => {
      if (grandidsession.value) {
        await handleComplete()
      } else {
        await handleStart()
      }
    })

    const router = useRouter()
    const route = useRoute()

    async function handleStart () {
      const callbackUrl = document.location.origin + route.fullPath
      const backUrl = document.location.origin + router.resolve({ name: 'Welcome', params: { id: id.value } }).href
      try {
        const { redirectUrl } = await api.startVerifyIdentity({
          verifyIdentityForm: { callbackUrl, backUrl, subject: { investigationId: id.value } }
        })
        document.location = redirectUrl as unknown as Location
      } catch (resp) {
        state.error = 'unknown'
        const error = await errorFromResponse(resp)
        if (error.error === 'UpstreamError' && error.extra?.grandid) {
          if (error.extra.grandid.code === 'INCORRECT_SSN') {
            state.error = 'incorrect_ssn'
          }
        }

        if (state.error === 'unknown') {
          console.error(error)
        }
      }
    }

    async function handleComplete () {
      try {
        const resp = await api.completeVerifyIdentityRaw({
          verifyIdentityResult: { grandidsession: grandidsession.value ?? '', investigation: id.value }
        })
        if (resp.raw.ok) {
          await reload()
          if (identity.value) {
            state.complete = true
            setTimeout(() => {
              router.push({ name: 'PersonalQuestions', params: route.params })
                .catch(err => console.error('Failed to navigate', err))
            }, 500)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw resp
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw resp
        }
      } catch (resp) {
        state.error = 'unknown'
        const error = await errorFromResponse(resp)

        if (error.status === 404) {
          state.error = 'invalid_ssn'
        }

        if (error.error === 'UpstreamError' && error.extra?.grandid) {
          if (error.extra.grandid.code === 'NOTLOGGEDIN') {
            state.error = 'timeout'
          } else {
            console.error('GrandID error', error.extra)
          }
        }
      }
    }

    return { id, state, loadingText }
  },
  metaInfo: {
    title: 'Verifiera identitet'
  }
})
