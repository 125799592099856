/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { Cluster } from './Cluster';
import {
    ClusterFromJSON,
    ClusterFromJSONTyped,
    ClusterToJSON,
} from './Cluster';
/**
 * Form with cluster
 * @export
 * @interface FormDefinition
 */
export interface FormDefinition {
    /**
     *
     * @type {Array<Cluster>}
     * @memberof FormDefinition
     */
    clusters: Array<Cluster>;
    /**
     *
     * @type {string}
     * @memberof FormDefinition
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof FormDefinition
     */
    lang: string;
    /**
     *
     * @type {string}
     * @memberof FormDefinition
     */
    id: string;
}
/**
 * Check if a given object implements the FormDefinition interface.
 */
export function instanceOfFormDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clusters" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "lang" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function FormDefinitionFromJSON(json: any): FormDefinition {
    return FormDefinitionFromJSONTyped(json, false);
}
export function FormDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'clusters': ((json['clusters'] as Array<any>).map(ClusterFromJSON)),
        'name': json['name'],
        'lang': json['lang'],
        'id': json['id'],
    };
}
export function FormDefinitionToJSON(value?: FormDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'clusters': ((value.clusters as Array<any>).map(ClusterToJSON)),
        'name': value.name,
        'lang': value.lang,
        'id': value.id,
    };
}