/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatientCreate
 */
export interface PatientCreate {
    /**
     *
     * @type {string}
     * @memberof PatientCreate
     */
    tel: string;
    /**
     *
     * @type {string}
     * @memberof PatientCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof PatientCreate
     */
    ssn: string;
    /**
     *
     * @type {string}
     * @memberof PatientCreate
     */
    name: string;
}
/**
 * Check if a given object implements the PatientCreate interface.
 */
export function instanceOfPatientCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tel" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "ssn" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function PatientCreateFromJSON(json: any): PatientCreate {
    return PatientCreateFromJSONTyped(json, false);
}
export function PatientCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'tel': json['tel'],
        'email': json['email'],
        'ssn': json['ssn'],
        'name': json['name'],
    };
}
export function PatientCreateToJSON(value?: PatientCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'tel': value.tel,
        'email': value.email,
        'ssn': value.ssn,
        'name': value.name,
    };
}