import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { mapValues } from 'lodash'
import z from 'zod'

export type FormEntry<T> = { start: T, validation?: (t: T) => string } | { start: T, schema?: z.ZodString }
export type ReturnValueEntry<T extends FormEntry<X>, X> = T['start']

export function useFormValidation<T extends { [Property in keyof T]: FormEntry<T[Property]['start']> }> (key: string | null, def: T) {
  type FormData = { [Property in keyof T]: T[Property]['start']; }
  type FormError = { [Property in keyof T]: string | null }
  type FormValid = { [Property in keyof T]: boolean | null }

  const data = reactive<FormData>(mapValues(def, v => null as null | typeof v))

  const feedback = computed<FormError>(() => mapValues(def, (v: FormEntry<unknown>, k: keyof typeof data) => {
    if (v === null) return null
    if ('validation' in v && v.validation) {
      return v.validation(data[k])
    }
    if ('schema' in v && v.schema) {
      const result = v.schema.nullable().safeParse(data[k])
      if (result.success) { return '' } else {
        return result.error.issues.map((i: z.ZodIssue) => i.message).join(', ')
      }
    }
    return null
  }) as unknown as FormError)

  function loadErrors (_feedback: FormError) {
    Object.assign(feedback, _feedback)
  }

  const valid = ref<FormValid>(mapValues(feedback.value, () => null) as FormValid)

  watch(feedback, () => {
    valid.value = mapValues(feedback.value, v => v === null ? null : !v)
  })
  const hasErrors = computed(() => Object.values(valid.value).every(e => !!e))

  function save () {
    if (key) sessionStorage.setItem(key, JSON.stringify(data))
  }
  function clear () {
    if (key) sessionStorage.removeItem(key)
  }

  function load () {
    if (!key) return
    const saved = sessionStorage.getItem(key)
    if (saved) {
      Object.assign(data as object, JSON.parse(saved))
    }
  }

  onMounted(load)
  onUnmounted(save)

  const groupstate = computed<FormValid>(() => {
    return mapValues(valid.value, f => f === null ? null : !f) as FormValid
  })
  const inputstate = computed<FormValid>(() => {
    return mapValues(valid.value, f => f === null ? null : (f ? null : false)) as FormValid
  })

  return { valid, hasErrors, feedback, data, load, save, clear, loadErrors, groupstate, inputstate }
}
