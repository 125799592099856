
import { defineComponent, PropType, ref } from 'vue'
import { Cluster, ClusterData, FormDefinition, Dimension } from '../api/index'
import { optionValueDescription, clusterMerge } from '../lib/form-answer'
import ClusterReviewBadges from '@/components/ClusterReviewBadges.vue'
import ClusterReviewDimensionCard from '@/components/ClusterReviewDimensionCard.vue'

interface FocusItem { cluster: string; dimension: string }

export default defineComponent({
  name: 'ClusterReviewTableV2',
  components: { ClusterReviewBadges, ClusterReviewDimensionCard },
  props: {
    primary: { type: Object as PropType<ClusterData>, required: false },
    secondary: { type: Object as PropType<ClusterData>, required: false },
    form: { type: Object as PropType<FormDefinition>, required: true },
    saving: { type: Boolean, default: () => false },
    focus: { type: Object as PropType<FocusItem>, required: false }
  },
  setup () {

    const highlightItem = ref<FocusItem>()

    function isExpanded (highlight: FocusItem | undefined, cluster: Cluster): boolean {
      if (highlight) {
        return highlight.cluster === cluster.key
      }
      return false
    }

    function isHighlight (highlight: FocusItem | undefined, cluster: Cluster, dim: Dimension): boolean {
      if (highlight) {
        return highlight.cluster === cluster.key && highlight.dimension === dim.code
      }
      return false
    }

    return {
      optionValueDescription,
      clusterMerge,
      highlightItem,
      isExpanded,
      isHighlight
    }
  }
})
