
import { RawLocation } from 'vue-router'
import { watch } from 'vue'
import { useDocumentVisibility } from '@vueuse/core'
import { useRouter } from 'vue-router/composables'

export function useVisibility ({ onInvisible, onVisible }: { onVisible?: () => void, onInvisible?: () => void }): void {
  const visibility = useDocumentVisibility()
  watch(visibility, (current, previous) => {
    if (current === 'visible' && previous === 'hidden') {
      if (onVisible) onVisible()
    }
    if (current === 'hidden' && previous === 'visible') {
      if (onInvisible) onInvisible()
    }
  })
}

export function useVisibilityGuard (redirect: RawLocation, condition: () => Promise<boolean>) {
  const router = useRouter()
  useVisibility({
    onVisible (): void {
      condition()
        .then(result => {
          if (result) {
            router.push(redirect)
              .catch(err => console.error('Failed to navigate', err))
          }
        })
        .catch(err => console.error('Failed calculate condition', err))
    }
  })
}
