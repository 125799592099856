/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { LoginSession } from './LoginSession';
import {
    LoginSessionFromJSON,
    LoginSessionFromJSONTyped,
    LoginSessionToJSON,
} from './LoginSession';
import type { LoginSessionOrganisation } from './LoginSessionOrganisation';
import {
    LoginSessionOrganisationFromJSON,
    LoginSessionOrganisationFromJSONTyped,
    LoginSessionOrganisationToJSON,
} from './LoginSessionOrganisation';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
/**
 *
 * @export
 * @interface CompleteIDPAuthentication200Response
 */
export interface CompleteIDPAuthentication200Response {
    /**
     *
     * @type {string}
     * @memberof CompleteIDPAuthentication200Response
     */
    created: string;
    /**
     *
     * @type {User}
     * @memberof CompleteIDPAuthentication200Response
     */
    user: User;
    /**
     *
     * @type {LoginSessionOrganisation}
     * @memberof CompleteIDPAuthentication200Response
     */
    organisation: LoginSessionOrganisation | null;
    /**
     *
     * @type {string}
     * @memberof CompleteIDPAuthentication200Response
     */
    type: CompleteIDPAuthentication200ResponseTypeEnum;
}
/**
 * @export
 */
export const CompleteIDPAuthentication200ResponseTypeEnum = {
    Login: 'login'
} as const;
export type CompleteIDPAuthentication200ResponseTypeEnum = typeof CompleteIDPAuthentication200ResponseTypeEnum[keyof typeof CompleteIDPAuthentication200ResponseTypeEnum];
/**
 * Check if a given object implements the CompleteIDPAuthentication200Response interface.
 */
export function instanceOfCompleteIDPAuthentication200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function CompleteIDPAuthentication200ResponseFromJSON(json: any): CompleteIDPAuthentication200Response {
    return CompleteIDPAuthentication200ResponseFromJSONTyped(json, false);
}
export function CompleteIDPAuthentication200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteIDPAuthentication200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'created': json['created'],
        'user': UserFromJSON(json['user']),
        'organisation': LoginSessionOrganisationFromJSON(json['organisation']),
        'type': json['type'],
    };
}
export function CompleteIDPAuthentication200ResponseToJSON(value?: CompleteIDPAuthentication200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'created': value.created,
        'user': UserToJSON(value.user),
        'organisation': LoginSessionOrganisationToJSON(value.organisation),
        'type': value.type,
    };
}