
import { defineComponent, PropType } from 'vue'

import MultipleTreatmentInput from '@/components/actionplan/MultipleTreatmentInput.vue'
import MultipleDiagnoseInput from '@/components/actionplan/MultipleDiagnoseInput.vue'
import { ActionPlanDataInternal, ActionPlanDataValidation, ActionPlanOptions } from '../use/action-plan-form'
export default defineComponent({
  name: 'ActionPlanForm',
  components: { MultipleDiagnoseInput, MultipleTreatmentInput },
  props: {
    plan: { type: Object as PropType<ActionPlanDataInternal>, required: true },
    options: { type: Object as PropType<ActionPlanOptions>, required: true },
    validation: { type: Object as PropType<ActionPlanDataValidation>, required: true }
  }
})
