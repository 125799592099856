import { inject, InjectionKey, provide } from 'vue'
import { AdminApi, DefaultApi, PatientApi, IdentityLoginApi, InvestigationApi, ConsentApi, Configuration, OpenIdentityApi, OpenSubmitApi } from '../../api/index'

export const DefaultApiType: InjectionKey<DefaultApi> = Symbol('default-api')
export const AdminApiType: InjectionKey<AdminApi> = Symbol('admin-api')
export const ConsentApiType: InjectionKey<ConsentApi> = Symbol('consent-api')
export const PatientApiType: InjectionKey<PatientApi> = Symbol('patient-api')
export const IdentityLoginApiType: InjectionKey<IdentityLoginApi> = Symbol('ident-api')
export const InvestigationApiType: InjectionKey<InvestigationApi> = Symbol('investigation-api')
export const OpenIdentityApiType: InjectionKey<OpenIdentityApi> = Symbol('open-identity-api')
export const OpenSubmitApiType: InjectionKey<OpenSubmitApi> = Symbol('open-submit-api')

export class Api {
  static init (basePath = `${document.location.protocol}//${document.location.host}`) {
    const config = new Configuration({ basePath })
    const _default = new DefaultApi(config)
    provide(DefaultApiType, _default)
    const admin = new AdminApi(config)
    provide(AdminApiType, admin)
    const consent = new ConsentApi(config)
    provide(ConsentApiType, consent)
    const patient = new PatientApi(config)
    provide(PatientApiType, patient)
    const identitylogin = new IdentityLoginApi(config)
    provide(IdentityLoginApiType, identitylogin)
    const investigation = new InvestigationApi(config)
    provide(InvestigationApiType, investigation)
    const openidentity = new OpenIdentityApi(config)
    provide(OpenIdentityApiType, openidentity)
    const opensubmit = new OpenSubmitApi(config)
    provide(OpenSubmitApiType, opensubmit)

    return {
      _default,
      admin,
      consent,
      patient,
      identitylogin,
      investigation,
      openidentity,
      opensubmit
    }
  }

  private static _get<T>(api: InjectionKey<T>): T {
    const client = inject(api)
    if (client) {
      return client
    } else {
      throw new Error(`${api.description ?? '(no description)'} not injected properly, please call Api.init()`)
    }
  }

  static get (): DefaultApi {
    return this._get(DefaultApiType)
  }

  static consent (): ConsentApi {
    return this._get(ConsentApiType)
  }

  static admin (): AdminApi {
    return this._get(AdminApiType)
  }

  static patient (): PatientApi {
    return this._get(PatientApiType)
  }

  static investigation (): InvestigationApi {
    return this._get(InvestigationApiType)
  }

  static identlogin (): IdentityLoginApi {
    return this._get(IdentityLoginApiType)
  }

  static openIdentity (): OpenIdentityApi {
    return this._get(OpenIdentityApiType)
  }

  static openSubmit (): OpenSubmitApi {
    return this._get(OpenSubmitApiType)
  }

}
