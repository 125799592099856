
import { computed, defineComponent, PropType, watchEffect } from 'vue'
import { Answer, AnswerData } from '../api/index'

import { isFiltered, dependents } from '@/lib/form-answer'

import AnswerFormOption from '@/components/AnswerFormOption.vue'

export default defineComponent({
  name: 'AnswerForm',
  props: {
    answers: { type: Array as PropType<Answer[]>, required: true },
    value: { type: Object as PropType<AnswerData>, required: true }
  },
  components: { AnswerFormOption },
  setup (props) {
    function color (color?: string) {
      const c = color as unknown as string
      if (c === 'red') return 'color-red'
      if (c === 'yellow') return 'color-teal'
      if (c === 'green') return 'color-green'
      return ''
    }

    function isColoarble (answer: Answer) {
      return answer.options?.every(opt => opt.coloring)
    }

    function resetDependents (answer: Answer) {
      for (const dep of dependents(props.answers, answer)) {
        props.value[dep] = ''
      }
    }

    function autoSelectSingleChilds () {
      for (const answer of props.answers) {
        const option = props.value[answer.key]
        const options = answer.options || []
        options.filter(o => o.value === option).forEach(o => {
          if ((!o.children || o.children.length === 1) && answer.child_key) {
            if (props.value[answer.child_key] !== '*') {
              props.value[answer.child_key] = '*'
            }
          }
        })
      }
    }

    watchEffect(() => autoSelectSingleChilds())

    const answersPeek = computed(() => {
      return props.answers.filter(a => isFiltered(a, props.value))
    })

    return { color, isColoarble, resetDependents, answersPeek }
  }
})
