import { Answer, AnswerData, Cluster, ClusterData, DimensionData, FormDefinition } from '../api/index'
import { flatMap } from './array'
import { fromEntries } from './entries'

export function cluster (clusters: Cluster[], key: string): Cluster | undefined {
  return clusters.find(c => c.key === key)
}

export function zipDataAndDefinition (clusters: ClusterData, form: FormDefinition): Array<[Cluster, DimensionData]> {
  return Object.entries(clusters)
    .map(([clusterKey, data]) => {
      return [cluster(form.clusters, clusterKey) as Cluster, data]
    })
}

export function dimensionTitle (cluster: Cluster, code: string): string {
  const dimensions = cluster.dimensions || []
  const dimension = dimensions.find(d => d.code === code)
  return dimension ? dimension.title : code
}

export function optionValueDescription (cluster: Cluster, key: string | number, value: number | string): string | undefined {
  return typeof key === 'number'
    ? `${value}`
    : optionValueTitle(cluster.answers.find(v => v.key === key)!, value)
}

export function optionValueTitle (answer: Answer, value: unknown): string | undefined {
  return answer.options?.find(opt => opt.value === value)?.title
}

export function sortedAnswer (cluster: Cluster, answer: AnswerData) {
  const keys = flatMap(cluster.answers, a => a.child_key ? [a.key, a.child_key] : [a.key])
  const keyToIndex = fromEntries(keys.map((k, idx) => ([k, idx])))
  return [...Object.entries(answer)].sort(([ak], [bk]) => {
    return keyToIndex[ak] as number - (keyToIndex[bk] as number)
  })
}

export function clusterMerge (form?: FormDefinition, a?: ClusterData, b?: ClusterData) {
  a ??= {}
  b ??= {}
  const res = {} as ClusterData

  if (form) {
    for (const c of form.clusters) {
      res[c.key] = {}
      for (const d of c.dimensions) {
        res[c.key][d.code] = {}
      }
    }
  }

  for (const [k1, v1] of Object.entries(b)) {
    res[k1] = { }
    for (const [k2, v2] of Object.entries(v1)) {
      res[k1][k2] = v2
    }
  }

  for (const [k1, v1] of Object.entries(a)) {
    res[k1] ??= { }
    for (const [k2, v2] of Object.entries(v1)) {
      if (b[k1]) {
        res[k1][k2] = Object.assign({}, b[k1][k2] || v2)
      } else {
        res[k1][k2] = v2
      }
    }
  }

  return res
}

export function isFiltered (a: Answer, data: AnswerData) {
  if (a.filter) {
    return !Object.entries(a.filter).every(([k, v]) => {
      return data[k] === '' || data[k] === v
    })
  }
  return true
}

export function dependents (answers: Answer[], target: Answer, includeChild = true): string[] {
  const siblings = answers.filter(a => a.key !== target.key)
  const dependents = siblings.filter(a => target.key in (a.filter ?? {})).map(a => a.key)

  return includeChild && target.child_key ? dependents.concat(target.child_key) : dependents
}

export function keysAffectedOfUpdate (answers: Answer[], target: Answer, key: string, value: unknown): string[] {
  const siblings = answers.filter(a => a.key !== target.key)

  return siblings
    .filter(a => isFilterMatch(a, { [key]: value }))
    .map(s => s.key)
}

function isFilterMatch (answer: Answer, data: Record<string, unknown>) {
  if (!answer.filter) {
    return false
  }
  const filter = answer.filter as Record<string, unknown>
  for (const k of Object.keys(filter)) {
    if (k in data && data[k] === filter[k]) {
      continue
    }
    return false
  }
  return true
}

export function required (answers: Answer[], data: AnswerData): string[] {
  const required = answers.filter(a => {
    if (a.filter) {
      return !Object.entries(a.filter).every(([k, v]) => {
        return data[k] === '' || data[k] === v
      })
    }
    return true
  })
  return flatMap(required, a => a.child_key ? [a.key, a.child_key] : [a.key])
}

export function isComplete (answers: Answer[], data: AnswerData): boolean {
  const keys = required(answers, data)
  return keys.every(key => key in data && data[key] !== '')
}
