/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface JoiValidationErrorItemContext
 */
export interface JoiValidationErrorItemContext {
    /**
     *
     * @type {string}
     * @memberof JoiValidationErrorItemContext
     */
    key?: string;
    /**
     *
     * @type {string}
     * @memberof JoiValidationErrorItemContext
     */
    label?: string;
    /**
     *
     * @type {any}
     * @memberof JoiValidationErrorItemContext
     */
    value?: any | null;
}
/**
 * Check if a given object implements the JoiValidationErrorItemContext interface.
 */
export function instanceOfJoiValidationErrorItemContext(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
export function JoiValidationErrorItemContextFromJSON(json: any): JoiValidationErrorItemContext {
    return JoiValidationErrorItemContextFromJSONTyped(json, false);
}
export function JoiValidationErrorItemContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoiValidationErrorItemContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
       
        'key': !exists(json, 'key') ? undefined : json['key'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}
export function JoiValidationErrorItemContextToJSON(value?: JoiValidationErrorItemContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
       
        'key': value.key,
        'label': value.label,
        'value': value.value,
    };
}